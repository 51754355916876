var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._l((_vm.fields),function(field,idx){return _c('div',{key:idx},[(field.type === 'text' || field.type === 'date')?_c('v-text-field',{attrs:{"label":field.label,"type":field.type,"value":_vm.admitForm[field.model],"disabled":!_vm.canEditPersonInfo,"required":field.required},on:{"input":function($event){return _vm.$emit('update-admit-form', {
								key: field.model,
								value: $event,
							})}}}):(
							field.type === 'select' &&
							field.model === 'province'
						)?_c('v-select',{attrs:{"items":_vm.provinces,"item-text":"text","item-value":"value","label":"Province","value":_vm.admitForm.province,"disabled":!_vm.canEditPersonInfo,"required":field.required},on:{"input":function($event){return _vm.$emit('update-admit-form', {
								key: 'province',
								value: $event,
							})}}}):(
                field.type === 'autocomplete' &&
                field.model === 'ethnicity'
              )?_c('v-autocomplete',{staticStyle:{"border-radius":"4px"},attrs:{"value":_vm.admitForm.ethnicity,"items":_vm.ethnicities,"search-input":_vm.localSearchEthnicity,"label":`Ethnicity`,"item-text":"ethnicity_name","item-value":"id","hide-no-data":"","underlined":"","background-color":"white","disabled":!_vm.canEditPersonInfo,"required":field.required},on:{"update:searchInput":function($event){_vm.localSearchEthnicity=$event},"update:search-input":function($event){_vm.localSearchEthnicity=$event},"input":function($event){return _vm.$emit('update-admit-form', {
                  key: 'ethnicity',
                  value: $event,
                })}}}):(
                field.type === 'autocomplete' &&
                field.model === 'citizenship'
              )?_c('v-autocomplete',{staticStyle:{"border-radius":"4px"},attrs:{"value":_vm.admitForm.citizenship,"items":_vm.citizenships,"search-input":_vm.localSearchCitizenship,"label":"Citizenship","item-text":"country_name","item-value":"id","hide-no-data":"","underlined":"","background-color":"white","disabled":!_vm.canEditPersonInfo,"required":field.required},on:{"update:searchInput":function($event){_vm.localSearchCitizenship=$event},"update:search-input":function($event){_vm.localSearchCitizenship=$event},"input":function($event){return _vm.$emit('update-admit-form', {
                  key: 'citizenship',
                  value: $event,
                })}}}):(
							field.type === 'select' &&
							field.model === 'citizenship'
						)?_c('v-select',{attrs:{"items":_vm.citizenships,"item-text":"text","item-value":"value","label":"Citizenship","value":_vm.admitForm.citizenship,"disabled":!_vm.canEditPersonInfo,"required":field.required},on:{"input":function($event){return _vm.$emit('update-admit-form', {
								key: 'citizenship',
								value: $event,
							})}}}):(
							field.type === 'select' && field.model === 'sex'
						)?_c('v-select',{attrs:{"items":_vm.sexOptions,"item-text":"text","item-value":"value","label":"Sex","value":_vm.admitForm.sex,"disabled":!_vm.canEditPersonInfo,"required":field.required},on:{"input":function($event){return _vm.$emit('update-admit-form', {
								key: 'sex',
								value: $event,
							})}}}):_vm._e()],1)}),_c('v-checkbox',{attrs:{"label":"Is a Refugee?","input-value":_vm.admitForm.isRefugee,"disabled":!_vm.canEditPersonInfo},on:{"change":_vm.handleIsRefugee}}),_c('v-checkbox',{attrs:{"label":"Is an Immigrant?","input-value":_vm.admitForm.isImmigrant,"disabled":!_vm.canEditPersonInfo},on:{"change":function($event){return _vm.$emit('update-admit-form', {
							key: 'isImmigrant',
							value: $event,
						})}}}),_c('v-checkbox',{attrs:{"label":"Is a Veteran?","input-value":_vm.admitForm.isVeteran,"disabled":!_vm.canEditPersonInfo},on:{"change":function($event){return _vm.$emit('update-admit-form', {
							key: 'isVeteran',
							value: $event,
						})}}})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }