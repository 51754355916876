<template>
	<div>
		<v-card class="bedchart-content">
			<v-card-title>
				Bed Chart
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-icon
							v-bind="attrs"
							v-on="on"
							small
							class="ml-2 info-icon">
							mdi-information-outline
						</v-icon>
					</template>
					<span class="tooltip-content">
						Shows the bed arrangement and allows you to select or
						view the details of each bed.
					</span>
				</v-tooltip>
			</v-card-title>
			<v-card-text> <BedchartTabs /> </v-card-text>
		</v-card>
	</div>
</template>

<script>
	import BedchartTabs from "./bedchart-tabs.vue";

	export default {
		name: "BedchartContent",
		components: { BedchartTabs },
	};
</script>

<style scoped>
	.bedchart-content {
		border: 1px solid #ccc;
		border-radius: 8px;
		padding: 4px; /* Adjust the padding within the card */
		background-color: #fff; /* White background */
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
		min-width: fit-content; /* Set minimum width to fit content */
		width: 100%; /* Ensure it takes full width */
		height: 100%; /* Ensure it takes full height of the column */
		display: flex;
		flex-direction: column;
	}
</style>
