<template>
	<div>
		<v-app-bar app class="theme-color-red header-appbar">
			<!-- Toggle button for sidebar -->
			<v-btn icon @click="toggleDrawer" style="color: white">
				<v-icon>mdi-menu</v-icon>
			</v-btn>

			<!-- Title -->
			<v-toolbar-title style="color: white">
				Salvation Army
				<v-icon class="white--text">mdi-chevron-double-right</v-icon>
				Chilliwack
			</v-toolbar-title>

			<v-spacer></v-spacer>

			<div class="header-appbar-actions">
				<!-- Search field --> 
				<v-autocomplete
					v-model="searchQuery"
					:items="filteredItems"
					placeholder="Search here..."
					hide-details
					dense
					class="white--text"
					solo
					background-color="white"
					style="border-radius: 4px"
					append-icon="mdi-magnify"
					@input="onSearchInput"
					@change="showPersonInfo"></v-autocomplete>

				<!-- Admit Button -->
				<v-btn
					icon
					@click="showSearchPersonDialog = true"
					class="header-admit-btn mx-5"
					style="
						color: white;
						border: 1px solid white;
						border-radius: 10px;
						background-color: #d32f2f;
						width: 90px;
					">
					<v-icon>mdi-account-plus</v-icon>
					Admit
				</v-btn>

				<!-- Discharge Button -->
				<v-btn
					icon
					@click="showSearchDischargePersonDialog = true"
					class="header-discharge-btn"
					style="
						color: white;
						border: 1px solid white;
						border-radius: 10px;
						background-color: #d32f2f;
						width: 130px;
					">
					Discharge
					<v-icon>mdi-account-remove</v-icon>
				</v-btn>
			</div>

			<!-- Overflow menu -->
			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						icon
						v-bind="attrs"
						v-on="on"
						style="color: white"
						class="mt-4 pb-4">
						<v-icon>mdi-dots-vertical</v-icon>
					</v-btn>
				</template>
				<v-list
					style="
						color: white;
						margin-left: -10px;
						margin-right: -10px;
					">
					<v-list-item @click="logout">Log Out</v-list-item>
				</v-list>
			</v-menu>

			<!-- Search Person Dialog -->
			<search-person
        v-if="showSearchPersonDialog"
				v-model="showSearchPersonDialog"
				@view="showPersonQuickInfo"
				@addOrUpdate="addOrUpdatePerson"></search-person>

      <div v-if="false">
        <!-- Discharge Person Dialog -->
        <!-- <discharge-person
          v-model="showDischargePersonDialog"
          @view="showPersonQuickInfo"
          @addOrUpdate="addOrUpdatePerson"></discharge-person>  -->
       
      </div>
        <search-active-intakes 
          v-if="showSearchDischargePersonDialog"
          v-model="showSearchDischargePersonDialog"
          @dischargeIntake="dischargePersonIntake"
        ></search-active-intakes>
        <div v-if="false">
          <!-- <discharge-person 
            v-if="showSearchDischargePersonDialog"
            v-model="showSearchDischargePersonDialog"
            @dischargeIntake="dischargePersonIntake"
          ></discharge-person> -->
        </div>

			<!-- View Person Info Dialog -->
			<person-quick-info
				v-if="selectedPerson"
				:person="selectedPerson"
				:value="showPersonInfoDialog"
				@input="showPersonInfoDialog = $event" />
   

			<!-- Admit Dialog using Stepper -->
			<form-stepper-admit
        v-if="showAdmitDialog"
				ref="formStepperAdmit"
				:show="showAdmitDialog"
				:person="selectedPerson"
				@close="showAdmitDialog = false"
				@admit="handleAdmit" />

      <form-discharge
        v-if="showDischargeDialog"
        :person="selectedPerson"
        @close="showDischargeDialog = false"
        @discharge="handleDischarge"
      />
		</v-app-bar>
	</div>
</template>

<script>
	import FormStepperAdmit from "./form-stepper-admit.vue";
	import SearchPerson from "./search-person.vue";
  import SearchActiveIntakes from "./search-active-intakes.vue";
	import PersonQuickInfo from "./person-quick-info.vue";
	// import DischargePerson from "./discharge-person.vue";
	import { searchPersonIntake } from "@/api";

	export default {
		components: {
			FormStepperAdmit,
			SearchPerson,
      SearchActiveIntakes,
			PersonQuickInfo,
			// DischargePerson,
		},
		data() {
			return {
				searchQuery: "",
				showAdmitDialog: false,
				showSearchDischargePersonDialog: false,
        showDischargeDialog: false,
				showPersonInfoDialog: false,
				showSearchPersonDialog: false,
				selectedPerson: {},
				personData: [],
        personMostRecentShelterIntake: {}
			};
		},
		methods: {
      test() {
        this.showSearchDischargePersonDialog = true;
        
      }, 
			toggleDrawer() {
				this.$emit("toggle-drawer"); // Emitting the event to toggle sidebar
			},
			handleAdmit(admitForm) {
				console.log("Admit:", admitForm);
				this.showAdmitDialog = false;
				this.showSearchPersonDialog = false; // Close search-person dialog
				this.admitForm = { name: "", bedNumber: "", date: "" };
				this.$notify({
					type: "success",
					title: "Successfully Admitted",
					text: `Admitted on: ${new Date().toLocaleDateString()}`,
				});
			},
      handleDischarge(dischargeForm) {
        console.log(`Discharge: ${dischargeForm}`)
      },
			onSearchInput() {
				if (this.searchQuery.length >= 3) {
					this.filteredItems = this.personData.filter((item) =>
						item.person.full_name
							.toLowerCase()
							.includes(this.searchQuery.toLowerCase())
					);
				} else {
					this.filteredItems = [];
				}
			},
			showPersonInfo(person) {
				this.selectedPerson = person;
				this.showPersonInfoDialog = true;
			},
			showPersonQuickInfo(person) {
				this.selectedPerson = person;
				this.showPersonInfoDialog = true;
			},
			addOrUpdatePerson(person) {
        this.personMostRecentShelterIntake = person;
        
				this.selectedPerson = person || {};
        this.showAdmitDialog = true;
				this.showSearchPersonDialog = false; // Close search-person dialog

			},

      dischargePersonIntake(person) {
        this.selectedPerson = person || {};
        this.showDischargeDialog = true;
        this.showSearcgDischargePersonDialog = false;
      },
			async fetchPersonData() {
				try {
					const response = await searchPersonIntake();
					this.personData = response.data.items;
				} catch (error) {
					console.error("Error fetching person data:", error);
				}
			},
			logout() {
				console.log("Logging out");
				this.$router.push("/login");
				this.showSearchPersonDialog = false; // Close search-person dialog
			},
		},
		computed: {
			filteredItems() {
				if (this.searchQuery.length < 3) return [];
				return this.personData.filter((item) =>
					item.person.full_name
						.toLowerCase()
						.includes(this.searchQuery.toLowerCase())
				);
			},
		},
		created() {
			this.fetchPersonData();
      
		},
		watch: {
			$route() {
				this.showSearchPersonDialog = false; // Close search-person dialog on route change
			},
		},
	};
</script>

<style scoped>
	.header-appbar {
		margin: 0 !important;
		padding: 0 !important;
	}
	.header-appbar-actions {
		display: flex !important;
		align-items: center !important;
	}
</style>
