import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router/router";
import VTooltip from "v-tooltip";
import Notifications from 'vue-notification';

// Register v-tooltip globally
Vue.use(VTooltip);
Vue.use(Notifications);

Vue.config.productionTip = false;

new Vue({
	vuetify,
	router,
	render: (h) => h(App),
}).$mount("#app");
