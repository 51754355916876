var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-app-bar',{staticClass:"theme-color-red white--text",attrs:{"elevation":5,"app":""}},[_c('v-breadcrumbs',{staticClass:"white--text"},[_c('v-breadcrumbs-item',[_vm._v("Salvation Army")]),_c('v-breadcrumbs-divider',[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-chevron-double-right")])],1),_c('v-breadcrumbs-item',[_vm._v("Chilliwack")])],1)],1),_c('v-main',[_c('v-container',{staticClass:"background-image fill-height d-flex justify-center align-center",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-snackbar',{staticClass:"login-alert",attrs:{"color":_vm.alertColor},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.alertIcon))]),_vm._v(_vm._s(_vm.alertMessage)+" "),_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){_vm.alert = false}}},[_c('v-icon',[_vm._v(_vm._s(_vm.alertCloseIcon))])],1)],1)]),_c('v-card',{staticClass:"ma-2 pa-2 card-border"},[_c('v-card-title',{staticClass:"headline"},[_c('v-img',{staticClass:"mb-3",attrs:{"src":require("@/assets/salvation-army-logo.png"),"aspect-ratio":"1","contain":"","max-height":"200"}})],1),_c('v-card-text',[_c('v-form',{ref:"loginForm",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"label":"Username","prepend-icon":"mdi-account","autocomplete":"username","rules":[
										(v) =>
											!!v || 'Username is required',
									],"required":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('v-text-field',{attrs:{"label":"Password","append-icon":_vm.showPassword
											? 'mdi-eye-off'
											: 'mdi-eye',"type":_vm.showPassword ? 'text' : 'password',"prepend-icon":"mdi-lock","autocomplete":"current-password","rules":[
										(v) =>
											!!v || 'Password is required',
									],"required":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-btn',{staticClass:"white--text",attrs:{"block":"","color":"#ee2d3d","type":"submit"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-login")]),_vm._v(" Login ")],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }