<template>
	<v-card class="shelter-area-list-card">
		<v-card-title>
			<v-row style="width: 100%">
				<v-col cols="12" md="6">
					List of Archived Shelter Areas
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-icon
								v-bind="attrs"
								v-on="on"
								small
								class="ml-2 info-icon">
								mdi-information-outline
							</v-icon>
						</template>
						<span class="tooltip-content">
							This table shows the list of archived Shelter Areas
							along with their details.
						</span>
					</v-tooltip>
				</v-col>
				<v-col cols="12" md="6">
					<v-text-field
						v-model="search"
						placeholder="Search Archived Shelter Area:"
						hide-details
						dense
						solo
						background-color="white"
						style="border-radius: 4px"
						append-icon="mdi-archive-search"></v-text-field>
				</v-col>
			</v-row>
		</v-card-title>
		<v-card-text>
			<div class="table-container">
				<v-progress-linear
					v-if="loading"
					indeterminate
					color="red"></v-progress-linear>
				<v-data-table
					:headers="headers"
					:items="paginatedShelterAreas"
					:search="search"
					:items-per-page="5"
					:footer-props="{
						'items-per-page-options': [5, 10, 50, 100, 500],
						'items-per-page-text': 'Items per page',
					}">
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									small
									class="mr-2"
									@click="viewShelterArea(item)">
									mdi-eye
								</v-icon>
							</template>
							<span class="tooltip-content">
								View Archived Shelter Area
							</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									small
									class="mr-2"
									@click="editShelterArea(item)">
									mdi-pencil
								</v-icon>
							</template>
							<span class="tooltip-content">
								Edit Archived Shelter Area
							</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									small
									@click="restoreArchiveShelterArea(item)">
									mdi-archive-minus
								</v-icon>
							</template>
							<span class="tooltip-content">
								Restore Archived Shelter Area
							</span>
						</v-tooltip>
					</template>
				</v-data-table>
				<v-pagination
					v-model="page"
					:length="pageCount"
					:total-visible="7"
					color="red"></v-pagination>
			</div>
		</v-card-text>

		<!-- Archive Confirmation Dialog -->
		<v-dialog v-model="confirmArchiveDialog" max-width="600px">
			<v-card>
				<v-toolbar
					flat
					dense
					class="form-stepper-admit-toolbar sticky-toolbar">
					<v-toolbar-title class="dialog-title">
						Confirm Archiving
					</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn
						class="small-close-btn"
						icon
						@click="closeConfirmArchiveDialog">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text>
					<p>Are you sure you want to restore this shelter area?</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn class="theme-btn" @click="restoreShelterArea">
						Yes
					</v-btn>
					<v-btn class="theme-btn" @click="closeConfirmArchiveDialog">
						Cancel
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
	import { fetchShelterAreas } from "@/api"; // Ensure the correct path is provided

	export default {
		data() {
			return {
				shelterAreas: [],
				search: "",
				page: 1,
				itemsPerPage: 5,
				headers: [
					{ text: "ID", value: "id" },
					{ text: "Preferred Name", value: "building_name" },
					{ text: "Address", value: "address" },
					{ text: "Description", value: "building_desc" },
					{ text: "Phone Number", value: "phone_no" },
					{ text: "Actions", value: "actions", sortable: false },
				],
				loading: false,
				confirmArchiveDialog: false,
				areaToArchive: null,
			};
		},
		computed: {
			filteredShelterAreas() {
				return this.shelterAreas.filter(
					(area) =>
						area.building_name
							.toLowerCase()
							.includes(this.search.toLowerCase()) ||
						(area.address &&
							area.address
								.toLowerCase()
								.includes(this.search.toLowerCase())) ||
						(area.phone_no &&
							area.phone_no
								.toLowerCase()
								.includes(this.search.toLowerCase()))
				);
			},
			paginatedShelterAreas() {
				const start = (this.page - 1) * this.itemsPerPage;
				const end = start + this.itemsPerPage;
				return this.filteredShelterAreas.slice(start, end);
			},
			pageCount() {
				return Math.ceil(
					this.filteredShelterAreas.length / this.itemsPerPage
				);
			},
		},
		created() {
			this.fetchShelterAreas();
		},
		methods: {
			async fetchShelterAreas() {
				this.loading = true;
				try {
					const response = await fetchShelterAreas();
					this.shelterAreas = response.data.items;
				} catch (error) {
					console.error("Error fetching shelter areas:", error);
				} finally {
					this.loading = false;
				}
			},
			viewShelterArea(area) {
				// View shelter area logic here
				console.log("View:", area);
			},
			editShelterArea(area) {
				// Edit shelter area logic here
				console.log("Edit:", area);
			},
			restoreArchiveShelterArea(area) {
				this.areaToRestore = area;
				this.confirmRestoreDialog = true;
			},
			restoreShelterArea() {
				// Logic to restore the shelter area instead of archiving
				console.log("Archive:", this.areaToRestore);
				this.confirmRestoreDialog = false;
			},
			closeConfirmRestoreDialog() {
				this.confirmRestoreDialog = false;
			},
		},
	};
</script>

<style scoped>
	.shelter-area-search-input {
		width: 100%;
	}
	.shelter-area-list-card {
		margin-top: 20px;
	}
	.table-container {
		position: relative;
	}
</style>
