<template>
	<v-dialog v-model="localShow" max-width="600px">
		<v-card>
			<v-card-title class="red white--text">
				{{ personCopy.name }}
			</v-card-title>
			<v-row style="margin: 0 !important">
				<v-col cols="4">
					<v-img
						:src="
							personCopy.profile_pic ||
							'https://via.placeholder.com/300'
						"
						height="150"
						width="150"
						cover
						class="border"></v-img>
				</v-col>
				<v-col cols="8">
					<v-card-text>
						<v-text-field
							label="Full Name"
							v-model="personCopy.name"
							readonly></v-text-field>
						<v-text-field
							label="Bed Number"
							v-model="personCopy.bedNumber"
							readonly></v-text-field>
						<v-text-field
							label="Date"
							v-model="personCopy.date"
							readonly></v-text-field>
						<v-text-field
							label="Reason"
							v-model="personCopy.reason"
							readonly></v-text-field>
					</v-card-text>
				</v-col>
			</v-row>
			<v-card-actions class="pa-3 justify-end">
				<v-btn class="btn-close" text @click="closeDialog">Close</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	export default {
		props: {
			person: {
				type: Object,
				required: true,
				default: () => ({}),
			},
			value: {
				type: Boolean,
				required: true,
			},
		},
		data() {
			return {
				localShow: this.value,
				personCopy: { ...this.person },
			};
		},
		watch: {
			value(val) {
				this.localShow = val;
			},
			localShow(val) {
				this.$emit("input", val);
			},
			person: {
				handler(newVal) {
					this.personCopy = { ...newVal };
				},
				deep: true,
			},
		},
		methods: {
			closeDialog() {
				this.localShow = false;
			},
		},
	};
</script>

<style scoped>
	.border {
		border: 1px solid #ccc;
	}

	.btn-close {
		background-color: var(--primary-color);
		color: var(--secondary-color);
	}

	.v-dialog {
		overflow: hidden !important;
	}
</style>
