<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<!-- Demographics Profile -->
				<v-card class="mb-4" outlined>
					<v-card-title class="secondary-title"
						>Demographics Profile</v-card-title
					>
					<v-card-text>
						<v-select
							:items="homelessnessStatuses"
							label="Homelessness Status"
							:value="admitForm.homelessnessStatus"
							@input="
								$emit('update-admit-form', {
									key: 'homelessnessStatus',
									value: $event,
								})
							"></v-select>
						<v-select
							:items="homelessnessLengths"
							label="Homelessness Length"
							:value="admitForm.homelessnessLength"
							@input="
								$emit('update-admit-form', {
									key: 'homelessnessLength',
									value: $event,
								})
							"></v-select>
						<v-textarea
							label="Special Note/Remarks"
							:value="admitForm.specialNote"
							@input="
								$emit('update-admit-form', {
									key: 'specialNote',
									value: $event,
								})
							"></v-textarea>
					</v-card-text>
				</v-card>

				<!-- Demographics Emergency Contact -->

<div v-if="false">
				<v-card class="mb-4" outlined>
					<v-card-title class="secondary-title"
						>Demographics Emergency Contact</v-card-title
					>
					<v-card-text>
						<div
							v-for="(
								contact, idx
							) in admitForm.emergencyContacts"
							:key="'contact-' + idx">
							<v-text-field
								label="First Name"
								:value="contact.firstName"
								@input="
									$emit('update-emergency-contact', {
										index: idx,
										key: 'firstName',
										value: $event,
									})
								"
								required></v-text-field>
							<v-text-field
								label="Middle Name"
								:value="contact.middleName"
								@input="
									$emit('update-emergency-contact', {
										index: idx,
										key: 'middleName',
										value: $event,
									})
								"></v-text-field>
							<v-text-field
								label="Last Name"
								:value="contact.lastName"
								@input="
									$emit('update-emergency-contact', {
										index: idx,
										key: 'lastName',
										value: $event,
									})
								"
								required></v-text-field>
							<v-select
								:items="[
									'Immediate Family',
									'Relative',
									'Friend',
									'Other',
								]"
								label="Relationship"
								:value="contact.relationship"
								@input="
									$emit('update-emergency-contact', {
										index: idx,
										key: 'relationship',
										value: $event,
									})
								"
								required></v-select>
							<v-text-field
								label="Phone"
								:value="contact.phone"
								@input="
									$emit('update-emergency-contact', {
										index: idx,
										key: 'phone',
										value: $event,
									})
								"
								required></v-text-field>
							<v-text-field
								label="Email"
								:value="contact.email"
								@input="
									$emit('update-emergency-contact', {
										index: idx,
										key: 'email',
										value: $event,
									})
								"></v-text-field>
							<v-textarea
								label="Note"
								:value="contact.note"
								@input="
									$emit('update-emergency-contact', {
										index: idx,
										key: 'note',
										value: $event,
									})
								"></v-textarea>
							<v-btn
								v-if="idx > 0"
								@click="removeEmergencyContact(idx)"
								icon>
								<v-icon>mdi-delete</v-icon>
							</v-btn>
							<v-divider class="my-4"></v-divider>
						</div>
						<v-btn
							v-if="admitForm.emergencyContacts.length < 3"
							@click="addEmergencyContact"
							>Add Emergency Contact</v-btn
						>
					</v-card-text>
				</v-card>
</div>
        <v-card class="mb-4" outlined>
            <v-card-title
              class="secondary-title"
              >Demographics Emergency
              Contact</v-card-title
            >
            <v-card-text>
                <v-list v-if="personEmergencyContacts.length > 0">
                  <v-list-item
                    v-for="(
                      emerCont, idx
                    ) in personEmergencyContacts"
                    :key="'contact-' + idx">

                    <v-list-item-content
                      :class="['card-container']">

                      <v-card outlined>
                        <v-list-item>
                          <v-list-item-content>
                            <v-checkbox
                              :input-value="isEmergencyContactSelected(emerCont.id)"
                              @change="toggleEmergencyCardSelection(emerCont.id)"
                            />
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>First Name:</v-list-item-title>
                            <v-list-item-subtitle>{{ emerCont.first_name }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Middle Name:</v-list-item-title>
                            <v-list-item-subtitle>{{ emerCont.middle_name }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Last Name:</v-list-item-title>
                            <v-list-item-subtitle>{{ emerCont.last_name }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Relationship:</v-list-item-title>
                            <v-list-item-subtitle>{{ emerCont.relationship }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Phone:</v-list-item-title>
                            <v-list-item-subtitle>{{ emerCont.phone }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Email:</v-list-item-title>
                            <v-list-item-subtitle>{{ emerCont.email }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Note:</v-list-item-title>
                            <v-list-item-subtitle>{{ emerCont.note }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <!-- <v-btn @click="removeEmergencyContact(idx)">
                          Remove Contact
                        </v-btn> -->

                      
                      </v-card>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-text>
                <v-list v-if="newPersonEmergencyContacts.length >= 1">
                    <v-divider class="my-4"></v-divider>

                    <v-list-item-subtitle>New Emergency Contacts</v-list-item-subtitle>
                    
                    <v-list-item
                      v-for="(
                        emerCont, idx
                      ) in newPersonEmergencyContacts"
                      :key="'add-contact-' + idx">
                        
                        <v-list-item-content
                          :class="['card-container']">

                          <v-card class="mb-4" outlined>
                            <v-list-item>
                              <v-list-item-content>
                                <v-checkbox
                                  :input-value="true"
                                  disabled
                                />

                            <v-text-field
                              label="First Name"
                              v-model="
                                emerCont.first_name
                              "
                              @input="updateNewEmergencyContactField"
                              required></v-text-field>
                            <v-text-field
                              label="Middle Name"
                              v-model="
                                emerCont.middle_name
                              "
                              @input="updateNewEmergencyContactField"
                              ></v-text-field>
                            <v-text-field
                              label="Last Name"
                              v-model="
                                emerCont.last_name
                              "
                              @input="updateNewEmergencyContactField"
                              required></v-text-field>
                            <v-select
                              :items="emergencyContactsRelationshipTypes"
                              label="Relationship"
                              v-model="
                                emerCont.relationship
                              "
                              @input="updateNewEmergencyContactField"
                              required></v-select>
                            <v-text-field
                              label="Phone"
                              v-model="emerCont.phone"
                              @input="updateNewEmergencyContactField"
                              required></v-text-field>
                            <v-text-field
                              label="Email"
                              v-model="
                                emerCont.email
                              "
                              @input="updateNewEmergencyContactField"
                              ></v-text-field>
                            <v-textarea
                              label="Note"
                              v-model="
                                emerCont.note
                              "
                              @input="updateNewEmergencyContactField"
                              ></v-textarea>
                            
                            
                                <v-btn
                                    
                                    @click="
                                      removeEmergencyContact(
                                        idx
                                      )
                                    "
                                    >Remove Contact
                                </v-btn>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-btn
                    
                    @click="addNewEmergencyContact"
                    >Add Emergency
                    Contact</v-btn
                  >
              
            </v-card-text>


        </v-card>

				<!-- Demographics Health Profile -->
<div v-if="false">
				<v-card class="mb-4" outlined>
					<v-card-title class="secondary-title"
						>Demographics Health Profile  *old</v-card-title
					>
					<v-card-text>
						<div
							v-for="(healthGroup, idx) in admitForm.healthGroups"
							:key="'healthGroup-' + idx"
							class="mb-4">
							<p>
								<strong>Record:</strong>
								{{ healthGroup.recordDate }}
							</p>
							<ul>
								<li
									v-for="(
										issue, idx
									) in healthGroup.healthIssues"
									:key="'health-' + idx">
									<v-row>
										<v-col cols="6">{{ issue.name }}</v-col>
										<v-col cols="6">
											<v-radio-group
												v-model="issue.status"
												:mandatory="false">
												<v-radio
													label="Yes"
													value="Yes"></v-radio>
												<v-radio
													label="No"
													value="No"></v-radio>
												<v-radio
													label="Suspected"
													value="Suspected"></v-radio>
											</v-radio-group>
											<v-radio-group
												v-model="issue.evidence"
												:mandatory="false">
												<v-radio
													label="Stated"
													value="Stated"></v-radio>
												<v-radio
													label="Observed"
													value="Observed"></v-radio>
											</v-radio-group>
										</v-col>
									</v-row>
								</li>
							</ul>
							<v-textarea
								label="Other"
								:value="healthGroup.otherHealthIssues"
								@input="
									$emit('update-health-group', {
										index: idx,
										key: 'otherHealthIssues',
										value: $event,
									})
								"></v-textarea>
							<v-btn icon @click="removeHealthGroup(idx)">
								<v-icon>mdi-delete</v-icon>
							</v-btn>
							<v-divider class="my-4"></v-divider>
						</div>
						<v-btn @click="addHealthGroup"
							>New Health Profile</v-btn
						>
					</v-card-text>
				</v-card>
</div>

        <v-card class="mb-4" outlined>
					<v-card-title class="secondary-title"
						>Demographics Health Profile</v-card-title
					>

          <v-card-text>
            <v-list v-if="admitForm.healthIssues.length > 0">
              <v-list-item
                v-for="(
                  issue, idx
                ) in admitForm.healthIssues"
                :key="'health-' + idx">

                <v-list-item-content
                  :class="['card-container']">

                  <v-card outlined>
                    <v-list-item>
                      <v-list-item-content>
                        <v-checkbox
                          :input-value="true"
                          @change="toggleHealthIssueSelection(issue)"
                        />

                    
                        <v-list-item-title>Health Issue:</v-list-item-title>
                        <span v-if="issue.has_health_issues === 'Suspected or Possible' || issue.has_health_issues === 'Yes'"> {{ issue.has_health_issues }} </span>
                        <v-list-item-subtitle>  
                          <span v-if="issue.other_health_issues">{{ issue.other_health_issues }}</span>
                          <span v-else>{{ issue.healthIssues.description }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                  </v-card>
                </v-list-item-content>
                <!-- <v-list-item-action>
                  <v-btn
                      icon
                      @click="
                        removeHealthIssue(
                          idx
                        )
                      ">
                      <v-icon
                        >mdi-delete</v-icon
                      >
                    </v-btn>
                  </v-list-item-action> -->
              </v-list-item>
            </v-list>
          </v-card-text>

					<v-card-text>
						<v-list v-if="newPersonHealthIssues.length > 0">
              <v-divider class="my-4"></v-divider>

              <v-list-item-subtitle>New Health Issue Entry</v-list-item-subtitle>
              <v-list-item
                v-for="(
                  issue, idx
                ) in newPersonHealthIssues"
                :key="'add-health-' + idx">
                <v-list-item-content
                  :class="['card-container', {'selected': true}]">

                  <v-card outlined>
                    <v-list-item>
                      
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          <v-container>
                        <v-checkbox
                          :input-value="true"
                          disabled
                        />

                        

                        <v-radio-group
                          label="Has Health Issue?"
                          v-model="
                            issue.status
                          "
                          :mandatory="
                            false
                          "
                          @change="updateNewHealthIssueEntry"
                          >
                          <v-radio
                            label="Yes"
                            value="Yes"></v-radio>
                          <v-radio
                            label="No"
                            value="No"></v-radio>
                          <v-radio
                            label="Suspected or Possible"
                            value="Suspected or Possible">
                          </v-radio>
                          <v-radio
                            label="Dont't Know"
                            value="Dont't Know">
                          </v-radio>
                        </v-radio-group>
                        <v-container v-if="displayHealthIssuesSelect(issue)">
                          <v-select
                            :items="healthIssuesList"
                            label="Health Issue"
                            
                            item-text="description"
                            item-value="id"
                            v-model="issue.issueId"
                            required
                            @input="updateNewHealthIssueEntry"
                          ></v-select>
                        </v-container>
                        <v-container v-if="displayOtherHealthIssueInput(issue)">
                          <v-textarea
                          
                            label="Other"
                            v-model="
                              issue.otherHealthIssues"
                            @input="updateNewHealthIssueEntry"
                          
                          ></v-textarea>
                        </v-container>
                        
                      </v-container>
                    </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                      
                  </v-card>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    icon
                    @click="
                      removeHealthIssue(
                        idx
                      )
                    ">
                    <v-icon
                      >mdi-delete</v-icon
                    >
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-btn								
              @click="addNewHealthIssue"
              >Add Health Issue</v-btn
            >
					</v-card-text>
				</v-card>

        <v-container>
          <v-divider
            class="my-4"></v-divider>
          
            If the person has allergies,
            please specify:
          
          
        </v-container>
        <!-- Allergies -->
        <v-card class="mb-4" outlined>
          <v-card-text >
            <v-list v-if="admitForm.allergies.length > 0">
              <v-list-item
                v-for="(
                  allergy, idx
                ) in admitForm.allergies"
                :key="'allergy-' + idx">

                <v-list-item-content
                  :class="['card-container']">

                  <v-card outlined>
                    <v-list-item>
                      <v-list-item-content>
                        <v-checkbox
                          :input-value="true"
                          @change="toggleAllergySelection(allergy)"
                        />

                            <v-list-item-title>Allergy:</v-list-item-title>
                            <v-list-item-subtitle>  
                              <span v-if="allergy.other_allergy">{{ allergy.other_allergy }}</span>
                              <span v-else>{{ allergy.allergy.name }} - {{ allergy.allergy.type }}</span>
                            </v-list-item-subtitle>
                            <v-list-item-title>Remarks:</v-list-item-title>
                            <v-list-item-subtitle>
                              {{ allergy.remarks }}
                            </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                  </v-card>
                </v-list-item-content>
                
              </v-list-item>
            </v-list>
          </v-card-text>

          <v-card-text v-if="true">
            <v-list v-if="newPersonAllergies.length > 0">
              <v-divider class="my-4"></v-divider>

              <v-list-item-subtitle>New Allergies</v-list-item-subtitle>
              <v-list-item
                v-for="(
                  allergy, idx
                ) in newPersonAllergies"
                :key="'allergy-' + idx">

                <v-list-item-content
                  :class="['card-container', {'selected': true}]">

                  <v-card outlined>
                    <v-checkbox
                      :input-value="true"
                      disabled
                    />

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Allergy:</v-list-item-title>
                        <v-list-item-subtitle>  
                          <span v-if="allergy.otherAllergy">{{ allergy.otherAllergy }}</span>
                          <span v-else>{{ allergy.name }} - {{ allergy.type }}</span>
                          
                        </v-list-item-subtitle>
                        <v-list-item-title>Remarks:</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ allergy.remarks }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                  </v-card>
                </v-list-item-content>
                <v-list-item-action>
                <v-btn
                    icon
                    @click="
                      removeAllergy(
                        idx
                      )
                    ">
                    <v-icon
                      >mdi-delete</v-icon
                    >
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

          </v-card-text>

          <v-card-text>
            <v-btn
              @click="
                showAddAllergyDialog
              "
              >Add Allergy</v-btn
            >

          </v-card-text>
        </v-card>

				<!-- Demographics Addictions -->
				<v-card class="mb-4" outlined v-if=false>
					<v-card-title class="secondary-title"
						>Demographics Addictions</v-card-title
					>
					<v-card-text>
						<div
							v-for="(
								addictionGroup, idx
							) in admitForm.addictionGroups"
							:key="'addictionGroup-' + idx"
							class="mb-4">
							<p>
								<strong>Record:</strong>
								{{ addictionGroup.recordDate }}
							</p>
							<ul>
								<li
									v-for="(
										addiction, idx
									) in addictionGroup.addictions"
									:key="'addiction-' + idx">
									<v-row>
										<v-col cols="6">{{
											addiction.name
										}}</v-col>
										<v-col cols="6">
											<v-radio-group
												v-model="addiction.status"
												:mandatory="false">
												<v-radio
													label="Yes"
													value="Yes"></v-radio>
												<v-radio
													label="No"
													value="No"></v-radio>
												<v-radio
													label="Suspected"
													value="Suspected"></v-radio>
											</v-radio-group>
											<v-radio-group
												v-model="addiction.evidence"
												:mandatory="false">
												<v-radio
													label="Stated"
													value="Stated"></v-radio>
												<v-radio
													label="Observed"
													value="Observed"></v-radio>
											</v-radio-group>
										</v-col>
									</v-row>
								</li>
							</ul>
							<v-textarea
								label="Other"
								:value="addictionGroup.otherAddictions"
								@input="
									$emit('update-addiction-group', {
										index: idx,
										key: 'otherAddictions',
										value: $event,
									})
								"></v-textarea>
							<v-btn icon @click="removeAddictionGroup(idx)">
								<v-icon>mdi-delete</v-icon>
							</v-btn>
							<v-divider class="my-4"></v-divider>
						</div>
						<v-btn @click="addAddictionGroup"
							>New Addiction Profile</v-btn
						>
					</v-card-text>
				</v-card>

        <v-card class="mb-4" outlined>
          <v-card-title
            class="secondary-title"
            >Demographics
            Addictions Profile</v-card-title
          >
          <v-card-text >
            <v-list v-if="admitForm.addictions.length > 0">
              <v-list-item
                v-for="(
                  addiction, idx
                ) in admitForm.addictions"
                :key="'addiction-' + idx">

                <v-list-item-content
                  :class="['card-container']">

                  <v-card outlined>
                    <v-list-item>
                      <v-list-item-content>
                        <v-checkbox
                          :input-value="true"
                          @change="toggleAddictionSelection(addiction)"
                        />

                            <v-list-item-title>Addiction:</v-list-item-title>
                            <span v-if="addiction.has_addictions === 'Suspected or Possible' || addiction.has_addictions === 'Yes'"> {{ addiction.has_addictions }} </span>
                            <span >{{ addiction.is_stated_or_observed }} </span> 
                            <v-list-item-subtitle>  
                              <span v-if="addiction.other_addictions">{{ addiction.other_addictions }}</span>
                              <span v-else>{{ addiction.addictions.name }}</span>
                            </v-list-item-subtitle>
                          
                      </v-list-item-content>
                    </v-list-item>

                  </v-card>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>

          <v-card-text>
            <v-list  v-if="newPersonAddictions.length > 0" >

              <v-divider class="my-4"></v-divider>
              <v-list-item-subtitle>New Addiction Entry</v-list-item-subtitle>

              <v-list-item
                v-for="(
                  addiction, idx
                ) in newPersonAddictions"
                :key="
                  'addiction-' + idx
                ">
                
                <v-list-item-content
                  :class="['card-container', {'selected': true}]"
                >
                  
                  <v-card cols="6" outlined>
                    <v-list-item>
                      <v-list-item-content>
                        
                        <v-list-item-subtitle>
                          <v-container>
                            <v-checkbox
                              :input-value="true"
                              disabled
                            />
                            <v-radio-group
                              label="Has Addiction?"
                              v-model="
                                addiction.status
                              "
                              :mandatory="
                                false
                              "
                              @change="updateNewAddictionEntry"
                              >
                              <v-radio
                                label="Yes"
                                value="Yes"></v-radio>
                              <v-radio
                                label="No"
                                value="No"></v-radio>
                              <v-radio
                                label="Suspected or Possible"
                                value="Suspected or Possible"></v-radio>
                            </v-radio-group>
                          </v-container>
                          <v-container v-if="displayAddictionsSelect(addiction)">
                            <v-select
                              :items="addictionsList"
                              label="Addiction"
                              
                              item-text="name"
                              item-value="id"
                              v-model="addiction.addictionId"
                              required
                              @input="updateNewAddictionEntry"
                            ></v-select>
                            <v-radio-group
                              label="Is Stated or Observed?"
                              v-model="
                                addiction.evidence
                              "
                              :mandatory="
                                false
                              "
                              @change="updateNewAddictionEntry"
                              >
                              <v-radio
                                label="Stated"
                                value="Stated"></v-radio>
                              <v-radio
                                label="Observed"
                                value="Observed"></v-radio>
                            </v-radio-group>
                          </v-container>
                          <v-container v-if="displayOtherAddictionsInput(addiction)">
                            <v-textarea
                            
                              label="Other"
                              v-model="
                                addiction.otherAddictions"
                              @input="updateNewAddictionEntry"
                            ></v-textarea>
                          </v-container>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    icon
                    @click="
                      removeAddiction(
                        idx
                      )
                    ">
                    <v-icon
                      >mdi-delete</v-icon
                    >
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-btn
              @click="addNewAddiction"
              >Add Addiction
            </v-btn>  
          </v-card-text>

        </v-card>

        

				<!-- Demographics Mental Health -->
				<v-card class="mb-4" outlined v-if="false">
					<v-card-title class="secondary-title"
						>Demographics Mental Health</v-card-title
					>
					<v-card-text>
						<div
							v-for="(
								mentalHealthGroup, idx
							) in admitForm.mentalHealthGroups"
							:key="'mentalHealthGroup-' + idx"
							class="mb-4">
							<p>
								<strong>Record:</strong>
								{{ mentalHealthGroup.recordDate }}
							</p>
							<ul>
								<li
									v-for="(
										mentalHealthIssue, idx
									) in mentalHealthGroup.mentalHealthIssues"
									:key="'mental-health-' + idx">
									<v-row>
										<v-col cols="6">{{
											mentalHealthIssue.name
										}}</v-col>
										<v-col cols="6">
											<v-radio-group
												v-model="
													mentalHealthIssue.status
												"
												:mandatory="false">
												<v-radio
													label="Yes"
													value="Yes"></v-radio>
												<v-radio
													label="No"
													value="No"></v-radio>
												<v-radio
													label="Suspected"
													value="Suspected"></v-radio>
											</v-radio-group>
											<v-radio-group
												v-model="
													mentalHealthIssue.evidence
												"
												:mandatory="false">
												<v-radio
													label="Stated"
													value="Stated"></v-radio>
												<v-radio
													label="Observed"
													value="Observed"></v-radio>
											</v-radio-group>
										</v-col>
									</v-row>
								</li>
							</ul>
							<v-textarea
								label="Other"
								:value="
									mentalHealthGroup.otherMentalHealthIssues
								"
								@input="
									$emit('update-mental-health-group', {
										index: idx,
										key: 'otherMentalHealthIssues',
										value: $event,
									})
								"></v-textarea>
							<v-btn icon @click="removeMentalHealthGroup(idx)">
								<v-icon>mdi-delete</v-icon>
							</v-btn>
							<v-divider class="my-4"></v-divider>
						</div>
						<v-btn @click="addMentalHealthGroup"
							>New Mental Health Profile</v-btn
						>
					</v-card-text>
				</v-card>

        <v-card class="mb-4" outlined>
          <v-card-title
            class="secondary-title"
            >Demographics Mental
            Health Profile</v-card-title
          >

          <v-card-text>
            <v-list v-if="admitForm.mentalHealthIssues.length > 0">
              <v-list-item
                v-for="(
                  issue, idx
                ) in admitForm.mentalHealthIssues"
                :key="'mental-health-' + idx">

                <v-list-item-content
                  :class="['card-container']">

                  <v-card outlined>
                    <v-list-item>
                      <v-list-item-content>
                    <v-checkbox
                      :input-value="true"
                      @change="toggleMentalHealthIssueSelection(issue)"
                    />

                   
                        <v-list-item-title>Mental Health Issue:</v-list-item-title>
                        <span v-if="issue.has_mental_health_issues === 'Suspected or Possible' || issue.has_mental_health_issues === 'Yes'"> {{ issue.has_mental_health_issues }} </span>
                        <span >{{ issue.is_stated_or_observed }} </span> 
                        <v-list-item-subtitle>  
                          <span v-if="issue.other_mental_health_issues">{{ issue.other_mental_health_issues }}</span>
                          <span v-else>{{ issue.mentalHealthIssues.description }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                  </v-card>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>

          <v-card-text>
            <v-list v-if="newPersonMentalHealthIssues.length > 0">
                <v-divider class="my-4"></v-divider>
                <v-list-item-subtitle>New Mental Health Issue Entry</v-list-item-subtitle>

                <v-list-item
                  v-for="(
                    issue,
                    idx
                  ) in newPersonMentalHealthIssues"
                  :key="
                    'add-mental-health-' +
                    idx
                  ">
                  <v-list-item-content
                    :class="['card-container']"
                  >
                    
                    <v-card outlined>
                      <v-list-item>
                      
                        <v-list-item-content>
                          <v-list-item-subtitle>
                            <v-container>
                              <v-checkbox
                                :input-value="true"
                                disabled
                              />
                              <v-radio-group
                                label="Has Mental Health Issue?"
                                v-model="
                                  issue.status
                                "
                                :mandatory="
                                  false
                                "
                                @change="updateNewMentalHealthIssueEntry"
                                >
                                <v-radio
                                  label="Yes"
                                  value="Yes"></v-radio>
                                <v-radio
                                  label="No"
                                  value="No"></v-radio>
                                <v-radio
                                  label="Suspected or Possible"
                                  value="Suspected or Possible"></v-radio>
                              </v-radio-group>
                              <v-container v-if="displayMentalHealthIssuesSelect(issue)">
                                <v-select
                                  :items="mentalHealthIssuesList"
                                  label="Mental Health Issue"
                                  
                                  item-text="description"
                                  item-value="id"
                                  v-model="issue.issueId"
                                  required
                                  @input="updateNewMentalHealthIssueEntry"
                                ></v-select>
                                <v-radio-group
                                  label="Is Stated or Observed?"
                                  v-model="
                                    issue.evidence
                                  "
                                  :mandatory="
                                    false
                                  "
                                  @change="updateNewMentalHealthIssueEntry"
                                  >
                                  <v-radio
                                    label="Stated"
                                    value="Stated"></v-radio>
                                  <v-radio
                                    label="Observed"
                                    value="Observed"></v-radio>
                                </v-radio-group>
                              </v-container>
                              <v-container v-if="displayOtherMentalHealthIssuesInput(issue)">
                                <v-textarea
                                
                                  label="Other"
                                  v-model="
                                    issue.otherMentalHealthIssues"
                                  @input="updateNewMentalHealthIssueEntry"
                                ></v-textarea>
                              </v-container>
                            </v-container>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                    </v-card>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      icon
                      @click="
                        removeMentalHealth(
                          idx
                        )
                      ">
                      <v-icon
                        >mdi-delete</v-icon
                      >
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-btn
                
                @click="addNewMentalHealthIssue"
                >Add Mental Health Issue</v-btn>
          </v-card-text>


        </v-card>

			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	export default {
		props: {
			admitForm: Object,
			homelessnessStatuses: Array,
			homelessnessLengths: Array,
      personEmergencyContacts: Array,
      newPersonEmergencyContacts: Array,
      emergencyContactsRelationshipTypes: Array,
      selectedEmergencyContacts: Array,
      healthIssuesList: Array,
      newPersonHealthIssues: Array,
      selectedHealthIssues: Array,
      newPersonAllergies: Array,
      selectedAllergies: Array,
      newPersonAddictions: Array,
      selectedAddictions: Array,
      addictionsList: Array,
      newPersonMentalHealthIssues: Array,
      selectedMentalHealthIssues: Array,
      mentalHealthIssuesList: Array


		},
		methods: {
			addEmergencyContact() {
				this.$emit("add-emergency-contact");
			},
      addNewEmergencyContact() {
				this.$emit("add-new-emergency-contact");
			},
      addNewHealthIssue() {
        this.$emit("add-new-health-issue");
      },
      updateNewEmergencyContactField() {
        this.$emit("update-new-emergency-contact-field", this.newPersonEmergencyContacts);
      },
			removeEmergencyContact(index) {
				this.$emit("remove-emergency-contact", index);
			},


			addHealthGroup() {
				this.$emit("add-health-group");
			},
			removeHealthGroup(index) {
				this.$emit("remove-health-group", index);
			},
      removeHealthIssue(index) {
        this.$emit("remove-health-issue", index);
      },
      addAddictionGroup() {
				this.$emit("add-addiction-group");
			},
			removeAddictionGroup(index) {
				this.$emit("remove-addiction-group", index);
			},
			addMentalHealthGroup() {
				this.$emit("add-mental-health-group");
			},
			removeMentalHealthGroup(index) {
				this.$emit("remove-mental-health-group", index);
			},

      toggleEmergencyCardSelection(emerContId) {
        this.$emit("toggle-emergency-card-selection", emerContId);
      },

      isEmergencyContactSelected(emerContId) {
        const previouslyAddedEmergencyContacts = this.admitForm.emergencyContacts;
        let isSelected = false;
        if (previouslyAddedEmergencyContacts) {
          isSelected = previouslyAddedEmergencyContacts.some(contact => contact.id === emerContId);
        }
        return this.selectedEmergencyContacts.includes(emerContId) || isSelected;
      },

      displayHealthIssuesSelect(issue) {
        return (
          issue.status === "Yes" || issue.status === "Suspected or Possible"
        );
      },

      displayOtherHealthIssueInput(issue) {
        return this.displayHealthIssuesSelect(issue) && !issue.issueId ; //13 for Other
      },
      
      updateNewHealthIssueEntry() {
        this.$emit("update-new-health-issue-entry", this.newPersonHealthIssues);
      },

      toggleHealthIssueSelection(issueId) {
        this.$emit("toggle-health-issue-selection", issueId);
        
      },
      showAddAllergyDialog() {
				this.$emit("show-add-allergy-dialog");
			},
			removeAllergy(index) {
				this.$emit("remove-allergy", index);
			},

      toggleAllergySelection(allergy) {
        this.$emit("toggle-allergy-selection", allergy);
      },
      
      displayAddictionsSelect(addiction) {
        return (
          addiction.status === "Yes" || addiction.status === "Suspected or Possible"
        );
      },

      displayOtherAddictionsInput(addiction) {
        return ((addiction.status == 'Yes' || addiction.status == 'Suspected or Possible') && !addiction.addictionId);
      },
      toggleAddictionSelection(addiction) {
        this.$emit("toggle-addiction-selection", addiction);
      },
      addNewAddiction() {
        this.$emit("add-new-addiction");
      },
      removeAddiction(index) {
				this.$emit("remove-addiction", index);
			},
      updateNewAddictionEntry() {
        this.$emit("update-new-addiction-entry", this.newPersonAddictions);
      },

      addNewMentalHealthIssue() {
				this.$emit("add-new-mental-health-issue");
			},

      updateNewMentalHealthIssueEntry() {
        this.$emit("update-new-mental-health-issue-entry", this.newPersonMentalHealthIssues);
      },

      toggleMentalHealthIssueSelection(issueId) {
        this.$emit("toggle-mental-health-issue-selection", issueId);
        
      },

      displayMentalHealthIssuesSelect(mentalIssue) {
        return (
          mentalIssue.status === "Yes" || mentalIssue.status === "Suspected or Possible"
        );
      },

      displayOtherMentalHealthIssuesInput(mentalIssue) {
        return ((mentalIssue.status == 'Yes' || mentalIssue.status == 'Suspected or Possible') && !mentalIssue.issueId);
      },

      removeMentalHealth(index) {
        this.$emit("remove-mental-health", index);
      },

		},
	};
</script>

<style scoped>
	/* Add any relevant styles here */
</style>
