<template>
	<div>
		<h1>Search For Archived Shelter Areas</h1>
		<v-container
			class="main-section-shelter-areas-archived ma-1 pa-1"
			fluid>
			<ShelterAreasArchived />
		</v-container>
	</div>
</template>

<script>
	import ShelterAreasArchived from "./shelter-areas-archived.vue";

	export default {
		name: "MainSectionShelterAreasArchived",
		components: { ShelterAreasArchived },
	};
</script>

<style scoped></style>
