<template>
	<v-card class="itembelonging-list-card">
		<v-card-title>
			<v-row style="width: 100%">
				<v-col cols="12" md="6">
					List of Items/ Belongings
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-icon
								v-bind="attrs"
								v-on="on"
								small
								class="ml-2 info-icon">
								mdi-information-outline
							</v-icon>
						</template>
						<span class="tooltip-content">
							This table shows the list of items/ belongings along
							with their details.
						</span>
					</v-tooltip>
				</v-col>
				<v-col cols="12" md="6">
					<v-text-field
						v-model="search"
						placeholder="Search Item/ Belonging:"
						hide-details
						dense
						solo
						background-color="white"
						style="border-radius: 4px"
						append-icon="mdi-briefcase-search">
					</v-text-field>
				</v-col>
			</v-row>
		</v-card-title>
		<v-card-text>
			<div class="table-container">
				<v-progress-linear v-if="loading" indeterminate color="red">
				</v-progress-linear>
				<v-data-table
					:headers="headers"
					:items="paginatedItemsBelongings"
					:search="search"
					:items-per-page="5"
					:footer-props="{
						'items-per-page-options': [5, 10, 50, 100, 500],
						'items-per-page-text': 'Items per page',
					}">
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									small
									class="mr-2"
									@click="viewItem(item)">
									mdi-eye
								</v-icon>
							</template>
							<span class="tooltip-content">View Item</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									small
									class="mr-2"
									@click="editItem(item)">
									mdi-pencil
								</v-icon>
							</template>
							<span class="tooltip-content">Edit Item</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									small
									@click="deleteItem(item)">
									mdi-delete
								</v-icon>
							</template>
							<span class="tooltip-content">Delete Item</span>
						</v-tooltip>
					</template>
				</v-data-table>
				<v-pagination
					v-model="page"
					:length="pageCount"
					:total-visible="7"
					color="red">
				</v-pagination>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
	export default {
		data() {
			return {
				headers: [
					{ text: "ID", value: "id" },
					{ text: "Name", value: "name" },
					{ text: "Category", value: "category" },
					{ text: "Actions", value: "actions", sortable: false },
				],
				itemsBelongings: [],
				search: "",
				page: 1,
				itemsPerPage: 5,
				loading: false,
			};
		},
		computed: {
			filteredItemsBelongings() {
				return this.itemsBelongings.filter(
					(item) =>
						item.name
							.toLowerCase()
							.includes(this.search.toLowerCase()) ||
						item.category
							.toLowerCase()
							.includes(this.search.toLowerCase())
				);
			},
			paginatedItemsBelongings() {
				const start = (this.page - 1) * this.itemsPerPage;
				const end = start + this.itemsPerPage;
				return this.filteredItemsBelongings.slice(start, end);
			},
			pageCount() {
				return Math.ceil(
					this.filteredItemsBelongings.length / this.itemsPerPage
				);
			},
		},
		created() {
			this.loadDummyData();
		},
		methods: {
			loadDummyData() {
				this.loading = true;
				setTimeout(() => {
					this.itemsBelongings = Array.from(
						{ length: 20 },
						(_, i) => ({
							id: i + 1,
							name: `Item ${i + 1}`,
							category: `Category ${i + 1}`,
						})
					);
					this.loading = false;
				}, 1000);
			},
			viewItem(item) {
				// View item logic here
				console.log("View:", item);
			},
			editItem(item) {
				// Edit item logic here
				console.log("Edit:", item);
			},
			deleteItem(item) {
				// Delete item logic here
				console.log("Delete:", item);
			},
		},
	};
</script>

<style scoped>
	.itembelonging-search-input {
		width: 100%;
	}
	.itembelonging-list-card {
		margin-top: 20px;
	}
	.table-container {
		position: relative;
	}
</style>
