<template>
	<v-app>
		<v-app-bar :elevation="5" app class="theme-color-red white--text">
			<v-breadcrumbs class="white--text">
				<v-breadcrumbs-item>Salvation Army</v-breadcrumbs-item>
				<v-breadcrumbs-divider>
					<v-icon class="white--text"
						>mdi-chevron-double-right</v-icon
					>
				</v-breadcrumbs-divider>
				<v-breadcrumbs-item>Chilliwack</v-breadcrumbs-item>
			</v-breadcrumbs>
		</v-app-bar>

		<v-main>
			<v-container
				class="background-image fill-height d-flex justify-center align-center"
				fluid>
				<v-row justify="center">
					<v-col cols="12" sm="6" md="3">
						<!-- Alert -->
						<v-snackbar
							v-model="alert"
							:color="alertColor"
							class="login-alert">
							<div class="text-center">
								<v-icon left>{{ alertIcon }}</v-icon
								>{{ alertMessage }}
								<v-btn text icon @click="alert = false">
									<v-icon>{{ alertCloseIcon }}</v-icon>
								</v-btn>
							</div>
						</v-snackbar>
						<!-- Login form -->
						<v-card class="ma-2 pa-2 card-border">
							<v-card-title class="headline">
								<v-img
									src="@/assets/salvation-army-logo.png"
									class="mb-3"
									aspect-ratio="1"
									contain
									max-height="200"></v-img>
							</v-card-title>
							<v-card-text>
								<v-form ref="loginForm" @submit.prevent="login">
									<v-text-field
										v-model="username"
										label="Username"
										prepend-icon="mdi-account"
										autocomplete="username"
										:rules="[
											(v) =>
												!!v || 'Username is required',
										]"
										required></v-text-field>
									<v-text-field
										v-model="password"
										label="Password"
										:append-icon="
											showPassword
												? 'mdi-eye-off'
												: 'mdi-eye'
										"
										@click:append="
											showPassword = !showPassword
										"
										:type="
											showPassword ? 'text' : 'password'
										"
										prepend-icon="mdi-lock"
										autocomplete="current-password"
										:rules="[
											(v) =>
												!!v || 'Password is required',
										]"
										required></v-text-field>
									<v-btn
										block
										color="#ee2d3d"
										class="white--text"
										type="submit">
										<v-icon left>mdi-login</v-icon>
										Login
									</v-btn>
								</v-form>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
	import { loginUser } from "@/api";

	export default {
		name: "sa-login-form",
		data() {
			return {
				username: "",
				password: "",
				showPassword: false,
				alert: false,
				alertMessage: "",
				alertColor: "error",
				alertIcon: "mdi-alert-circle",
				alertCloseIcon: "mdi-close-box-outline",
			};
		},
		methods: {
			login() {
				if (this.$refs.loginForm.validate()) {
					const data = {
						username: this.username,
						password: this.password,
					};
					loginUser(data)
						.then((response) => {
							if (response.success) {
								console.log("Login Successful: ", response);
								this.alertMessage =
									"Login Successful! Redirecting to the Dashboard...";
								this.alertColor = "success";
								this.alertIcon = "mdi-check-circle";
								this.alertCloseIcon =
									"mdi-check-circle-outline";
								this.alert = true;
								setTimeout(() => {
									this.$router.push("/dashboard");
								}, 2500); // Wait 2.5 seconds before redirecting
							} else {
								console.error(
									"Login Failed: ",
									response.message
								);
								this.alertMessage =
									"Login Error: Invalid credentials!";
								this.alertColor = "error";
								this.alertIcon = "mdi-alert-circle";
								this.alertCloseIcon = "mdi-close-box-outline";
								this.alert = true;
							}
						})
						.catch((error) => {
							if (error.response) {
								console.error("Login Error: ", error.response);
								this.alertMessage =
									"Login Error: Invalid credentials!";
							} else if (error.request) {
								console.error(
									"Login Error: No response from server.",
									error.request
								);
								this.alertMessage =
									"Login Error: No response from server.";
							} else {
								console.error("Login Error: ", error.message);
								this.alertMessage =
									"An error occurred. Please try again.";
							}
							this.alertColor = "error";
							this.alertIcon = "mdi-alert-circle";
							this.alertCloseIcon = "mdi-close-box-outline";
							this.alert = true;
						});
				}
			},
		},
	};
</script>

<style scoped>
	.background-image {
		background-image: url("@/assets/salvation-army-login-bg.png");
		background-size: cover;
		background-repeat: no-repeat;
	}

	.card-border {
		border: solid gray 1.5px;
	}

	.login-alert {
		position: fixed;
		bottom: 0;
		width: 100%;
		z-index: 10;
	}

	.login-alert .text-center {
		font-size: 16px;
		text-align: center !important;
	}
</style>
