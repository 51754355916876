<template>
	<div class="shelter-areas-all">
		<main-section-shelter-areas-all></main-section-shelter-areas-all>
	</div>
</template>

<script>
	import ShelterAreasAll from "@/components/main-section-shelter-areas-all.vue";

	export default {
		components: {
			"main-section-shelter-areas-all": ShelterAreasAll,
		},
	};
</script>

<style scoped>
	/* Add your scoped styles here */
</style>
