<script>
	export default {
		data() {
			return {
				reportsData: [
					{
						title: "Resident Details Report",
						description: "Detailed information about all residents",
						content: `<p>This content includes a list of all residents, including their details, statuses, and more.</p>`,
					},
					{
						title: "Admission and Discharge Report",
						description:
							"Admission and discharge dates for all residents",
						content: `<p>This report shows the admission and discharge dates for each resident in the shelter.</p>`,
					},
					{
						title: "Refugee Status Report",
						description: "Report on residents who are refugees",
						content: `<p>This content covers details about the refugee status of residents.</p>`,
					},
					// Add more reports as needed...
				],
			};
		},
		methods: {
			getReportByTitle(title) {
				return this.reportsData.find(
					(report) => report.title === title
				);
			},
		},
	};
</script>
