<template>
	<div>
		<v-card
			class="bed-chart-tabs-card mt-0 mx-0 pt-0 px-0"
			style="border-radius: 4px">
			<v-card-title class="mt-0 mx-0 pt-0 px-0">
				<v-tabs
					v-model="selectedTab"
					background-color="red"
					center-active
					dark
					show-arrows
					style="border-bottom: solid 2px red">
					<!-- <v-tab
						v-for="(shelter, index) in shelterAreas"
						:key="shelter.id"
						class="custom-tab mx-1 mt-1">
						<span>{{ shelter.building_name }}</span>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									small
									class="ml-2 info-icon">
									mdi-information-outline
								</v-icon>
							</template>
							<span class="tooltip-content">
								{{ shelter.building_desc }}
							</span>
						</v-tooltip>
					</v-tab> -->
          <v-tab
						v-for="(shelter,index) in bedListPerBuilding"
						:key="index"
						class="custom-tab mx-1 mt-1">
						<span>{{ shelter.building_name }}</span>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									small
									class="ml-2 info-icon">
									mdi-information-outline
								</v-icon>
							</template>
							<span class="tooltip-content">
								{{ shelter.building_desc }}
							</span>
						</v-tooltip>
					</v-tab>
				</v-tabs>
				<v-progress-linear
					v-if="loading"
					indeterminate
					color="red"></v-progress-linear>
			</v-card-title>
			<v-card-subtitle>
				{{ selectedShelterDescription }}
			</v-card-subtitle>
<!-- New Bed Num -->
      <v-card-text>
				<div v-if="currentBuilding" class="bed-list d-block">
					<!-- table old -->
          <!-- <table>
						<tbody>
							<tr
								v-for="(row, rowIndex) in currentBedRows"
								:key="rowIndex">
								<td
									v-for="(bedNumber, columnIndex) in row"
									:key="bedNumber"
									class="bed-cell">
									<button
										:class="[
											'v-btn',
											'v-btn--block',
											'v-btn--text',
											'bed-button',
											getBedStatusClass(bedNumber),
											columnIndex === 9
												? 'last-column'
												: '',
										]"
										:disabled="bedNumber > maxBed"
										@click="selectBed(bedNumber)">
										{{ bedNumber }}
									</button>
								</td>
							</tr>
						</tbody>
					</table> -->
					<table>
            <tbody>
              <tr v-for="(row, rowIndex) in currentBedRows" :key="rowIndex">
                <td
                  v-for="(bed, columnIndex) in row"
                  :key="bed.id"
                  class="bed-cell"
                >
                  <button
                    :class="[
                      'v-btn',
                      'v-btn--block',
                      'v-btn--text',
                      'bed-button',
                      getBedStatusClass(bed.id),
                      columnIndex === 9 ? 'last-column' : '',
                    ]"
                    :disabled="!bed"
                    @click="selectBed(bed.id)"
                  >
                    {{ bed ? bed.id : '' }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <v-pagination
						v-model="page"
						:length="totalPages"
						color="red lighten-2"
						class="mt-5"
						active-class="pagination-active"
						total-visible="5"></v-pagination>
				</div>

				<div v-else class="bed-list d-block">
					<table>
						<tbody>
							<tr
								v-for="(row, rowIndex) in currentWomenBedRows"
								:key="rowIndex">
								<td
									v-for="(bedNumber, columnIndex) in row"
									:key="bedNumber"
									class="bed-cell">
									<button
										:class="[
											'v-btn',
											'v-btn--block',
											'v-btn--text',
											'bed-button',
											columnIndex === 9
												? 'last-column'
												: '',
										]"
										:disabled="bedNumber > womenMaxBed"
                    @click="selectBed(bedNumber)"
                    >
                    
										{{ bedNumber }}
									</button>
								</td>
							</tr>
						</tbody>
					</table>
					<v-pagination
						v-model="womenPage"
						:length="womenTotalPages"
						color="red lighten-2"
						class="mt-5"
						active-class="pagination-active"
						total-visible="5"></v-pagination>
				</div>
			</v-card-text>
<!--  -->
			<v-card-text v-if="false">
				<div v-if="selectedTab === 0" class="bed-list d-block">
					<table>
						<tbody>
							<tr
								v-for="(row, rowIndex) in currentBedRows"
								:key="rowIndex">
								<td
									v-for="(bedNumber, columnIndex) in row"
									:key="bedNumber"
									class="bed-cell">
									<button
										:class="[
											'v-btn',
											'v-btn--block',
											'v-btn--text',
											'bed-button',
											getBedStatusClass(bedNumber),
											columnIndex === 9
												? 'last-column'
												: '',
										]"
										:disabled="bedNumber > maxBed"
										@click="selectBed(bedNumber)">
										{{ bedNumber }}
									</button>
								</td>
							</tr>
						</tbody>
					</table>
					<v-pagination
						v-model="page"
						:length="totalPages"
						color="red lighten-2"
						class="mt-5"
						active-class="pagination-active"
						total-visible="5"></v-pagination>
				</div>

				<div v-else class="bed-list d-block">
					<table>
						<tbody>
							<tr
								v-for="(row, rowIndex) in currentWomenBedRows"
								:key="rowIndex">
								<td
									v-for="(bedNumber, columnIndex) in row"
									:key="bedNumber"
									class="bed-cell">
									<button
										:class="[
											'v-btn',
											'v-btn--block',
											'v-btn--text',
											'bed-button',
											columnIndex === 9
												? 'last-column'
												: '',
										]"
										:disabled="bedNumber > womenMaxBed"
                    @click="selectBed(bedNumber)"
                    >
                    
										{{ bedNumber }}
									</button>
								</td>
							</tr>
						</tbody>
					</table>
					<v-pagination
						v-model="womenPage"
						:length="womenTotalPages"
						color="red lighten-2"
						class="mt-5"
						active-class="pagination-active"
						total-visible="5"></v-pagination>
				</div>
			</v-card-text>
			<v-dialog v-model="dialog" max-width="600">
				<v-card>
					<v-card-title class="red white--text">
						{{ patientInfo.name }}
					</v-card-title>
					<v-row style="margin: 0 !important">
						<v-col cols="4">
							<v-img
								src="https://via.placeholder.com/300"
								height="150"
								width="150"
								cover
								class="border"></v-img>
						</v-col>
						<v-col cols="8">
							<v-card-text>
								<v-text-field
									label="Bed number"
									v-model="patientInfo.bedNumber"
									readonly></v-text-field>
								<v-text-field
									label="Date Admitted"
									v-model="patientInfo.dateAdmitted"
									readonly></v-text-field>
								<v-text-field
									label="Age"
									v-model="patientInfo.age"
									readonly></v-text-field>
								<v-text-field
									label="Gender"
									v-model="patientInfo.gender"
									readonly></v-text-field>
								<v-text-field
									label="Staffed by"
									v-model="patientInfo.staffedBy"
									readonly></v-text-field>
								<v-text-field
									label="Note"
									v-model="patientInfo.note"
									readonly></v-text-field>
							</v-card-text>
						</v-col>
					</v-row>
					<v-card-actions class="pa-3 justify-end">
						<v-btn class="btn-close" text @click="dialog = false"
							>Close</v-btn
						>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-card>
	</div>
</template>

<script>
	import { fetchBedList, searchPersonIntake, fetchShelterAreas, fetchBedListPerBuilding } from "@/api";

	export default {
    props: {
      isForInput: Boolean
    },
		name: "BedchartTabs",
		data() {
			return {
				selectedTab: 0, // Currently selected tab, id of 
				selectedBed: null, // No bed selected by default
				occupiedBed: null, // Bed number that is occupied
				maxBed: 100, // Maximum number of beds for men
				womenMaxBed: 70, // Maximum number of beds for women
				dialog: false, // Dialog visibility
				page: 1, // Current pagination page for men
				womenPage: 1, // Current pagination page for women
				itemsPerPage: 50, // Number of beds per page for both men and women
				bedsPerRow: 10,
        patientInfo: {
					name: "",
					bedNumber: "",
					dateAdmitted: "",
					age: "",
					gender: "",
					staffedBy: "",
					note: "",
				},
				bedList: [],
				personList: [],
				shelterAreas: [], // List of shelter areas
        bedListPerBuilding: [],
				loading: false, // Loading state for progress bar
			};
		},
		computed: {
			// totalPages() {
			// 	return Math.ceil(this.maxBed / this.itemsPerPage);
			// },
      totalPages() {
        return Math.ceil(
          (this.currentBuilding?.bedLists.length || 0) / this.itemsPerPage
        );
      },

			womenTotalPages() {
				return Math.ceil(this.womenMaxBed / this.itemsPerPage);
			},
      currentBedRows() {
        const startIndex = (this.page - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        const currentBeds = this.currentBuilding.bedLists.slice(
          startIndex,
          endIndex
        );

        const rows = [];
        for (let i = 0; i < currentBeds.length; i += this.bedsPerRow) {
          rows.push(currentBeds.slice(i, i + this.bedsPerRow));
        }
        return rows;
      },
			// 	const start = (this.page - 1) * this.itemsPerPage;
			// 	const end = start + this.itemsPerPage;
			// 	const rows = [];
			// 	for (let i = start; i < end; i += 10) {
			// 		const row = [];
			// 		for (let j = 0; j < 10; j++) {
			// 			const bedNumber = i + j + 1;
			// 			if (bedNumber > this.maxBed) break;
			// 			row.push(bedNumber);
			// 		}
			// 		rows.push(row);
			// 	}
			// 	return rows;
			// },
			currentWomenBedRows() {
				const start = (this.womenPage - 1) * this.itemsPerPage;
				const end = start + this.itemsPerPage;
				const rows = [];
				for (let i = start; i < end; i += 10) {
					const row = [];
					for (let j = 0; j < 10; j++) {
						const bedNumber = i + j + 1;
						if (bedNumber > this.womenMaxBed) break;
						row.push(bedNumber);
					}
					rows.push(row);
				}
				return rows;
			},
			selectedShelterDescription() {
        return (
					this.bedListPerBuilding[this.selectedTab]?.building_desc ||
					""
				);
        // return (
				// 	this.shelterAreas[this.selectedTab]?.building_description ||
				// 	""
				// );
			},
      currentBuilding() {
        return this.bedListPerBuilding[this.selectedTab] || null;
      }
		},
		methods: {
			getBedStatusClass(bedNumber) {
				const bed = this.bedList.find((b) => b.bed_number == bedNumber);
				if (!bed) return "";
				switch (bed.availability_status) {
					case "Occupied":
						return "occupied";
					case "Available":
						return "";
					case "Under Maintenance":
						return "under-maintenance";
					default:
						return "";
				}
			},
			async fetchBedList() {
				this.loading = true;
				try {
					const response = await fetchBedList();
					this.bedList = response.data.items;
				} catch (error) {
					console.error("Error fetching bed list:", error);
				} finally {
					this.loading = false;
				}
			},
      async fetchBedListPerBuilding({get_available_beds='', building_id=''}) {
        this.loading = true;
				try {
          
					const response = await fetchBedListPerBuilding({get_available_beds:get_available_beds, building_id:building_id});
					this.bedListPerBuilding = response.data.items;
          
				} catch (error) {
					console.error("Error fetching bed list:", error);
				} finally {
					this.loading = false;
				}
      },
			async fetchPersonList() {
				try {
					const response = await searchPersonIntake();
					this.personList = response.data.items;
				} catch (error) {
					console.error("Error fetching person list:", error);
				}
			},
			async fetchShelterAreas() {
				try {
					const response = await fetchShelterAreas();
					this.shelterAreas = response.data.items;
				} catch (error) {
					console.error("Error fetching shelter areas:", error);
				}
			},
			selectBed(bedNumber) {
				const bed = this.bedList.find((b) => b.bed_number == bedNumber);
				if (bed && bed.availability_status === "Occupied") {
					const intake = this.personList.find(
						(p) => p.bed.bed_number == bedNumber
					);
					if (intake) {
						this.patientInfo = {
							name: `${intake.person.first_name} ${intake.person.last_name}`,
							bedNumber: intake.bed.bed_number,
							dateAdmitted: intake.checked_in_at,
							age:
								new Date().getFullYear() -
								new Date(
									intake.person.date_of_birth
								).getFullYear(),
							gender: intake.person.sex,
							staffedBy: intake.case_worker,
							note: intake.special_note,
						};
						this.dialog = true;
					}
				} else {
          if (this.isForInput) {
            
            this.$emit("update-selected-bed-from-bed-chart", bed);
          }
        }
			},
		},
		watch: {
			selectedTab() {
				if (this.selectedTab === 0) {
					this.fetchBedList();

				}
			},
		},
		created() {
			this.fetchShelterAreas();
			if (this.selectedTab === 0) {
				this.fetchBedList();
			}
      this.fetchBedListPerBuilding({get_available_beds:0, building_id:''})
			this.fetchPersonList();
		},
	};
</script>

<style scoped>
	.bed-chart-tabs-card {
		border: 1px solid #ccc;
		border-radius: 8px;
		padding: 4px;
		background-color: #fff;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
		min-width: fit-content;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.bed-list {
		width: 100%;
	}

	table {
		width: 100%;
		border-collapse: collapse;
	}

	td.bed-cell {
		padding: 6px 5px 6px 5px;
		width: 10%;
	}

	.v-btn.bed-button {
		width: 100%;
		padding: 8px 0;
		margin: 0;
		border: 1px solid #ddd;
		border-radius: 4px;
	}

	.v-btn.occupied {
		background-color: green !important;
		color: white !important;
	}

	.v-btn.under-maintenance {
		background-color: yellow !important;
		color: black !important;
	}

	.v-btn.not-available {
		background-color: red !important;
		color: white !important;
	}

	.border {
		border: 1px solid #ccc;
	}

	.btn-close {
		background-color: var(--primary-color);
		color: var(--secondary-color);
	}

	.v-dialog {
		overflow: hidden !important;
	}

	.pagination-active {
		background-color: var(--primary-color) !important;
		color: var(--secondary-color) !important;
	}

	.tooltip-content {
		white-space: nowrap;
	}

	.custom-tab {
		border-top: 1px solid white;
		border-left: 1px solid white;
		border-right: 1px solid white;
		border-radius: 4px 4px 0 0;
		color: white !important;
	}

	.custom-tab:hover {
		background-color: darkred;
	}

	.info-icon {
		color: white;
	}
</style>
