<template>
	<v-app>
		<dashboard-header @toggle-drawer="drawer = !drawer" />
		<v-navigation-drawer app class="sidebar" v-model="drawer">
			<dashboard-sidebar />
		</v-navigation-drawer>
		<v-main>
			<v-container fluid>
				<router-view />
			</v-container>
		</v-main>
		<dashboard-footer />
	</v-app>
</template>

<script>
	import DashboardHeader from "@/components/app-header.vue";
	import DashboardSidebar from "@/components/app-sidebar.vue";
	import DashboardFooter from "@/components/app-footer.vue";

	export default {
		components: {
			DashboardHeader,
			DashboardSidebar,
			DashboardFooter,
		},
		data() {
			return {
				drawer: true,
        openDialog: false,
			};
		},
	};
</script>

<style scoped>
	.sidebar {
		width: 250px; /* Adjust the width of the sidebar as needed */
	}
</style>
