<template>
	<v-container class="pl-0" fluid>
		<v-card class="residents-current-card">
			<v-card-title>
				<v-row style="width: 100%">
					<v-col cols="12" md="6">
						List of Current Residents
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									small
									class="ml-2 info-icon">
									mdi-information-outline
								</v-icon>
							</template>
							<span class="tooltip-content">
								This table shows the list of current residents
								along with their details.
							</span>
						</v-tooltip>
					</v-col>
					<v-col cols="12" md="6">
						<v-text-field
							v-model="search"
							placeholder="Search Resident:"
							hide-details
							dense
							solo
							background-color="white"
							style="border-radius: 4px"
							append-icon="mdi-account">
						</v-text-field>
					</v-col>
				</v-row>
			</v-card-title>
			<v-card-text>
				<v-progress-linear
					v-if="loading"
					indeterminate
					color="red"></v-progress-linear>
				<v-data-table
					:headers="headers"
					:items="paginatedResidents"
					:search="search"
					:items-per-page="10"
					:footer-props="{
						'items-per-page-options': [10, 20, 50, 100],
						'items-per-page-text': 'Residents per page',
					}">
					<template v-slot:[`item.fullName`]="{ item }">
						{{ item.fullName }}
					</template>
					<template v-slot:[`item.dateOfBirth`]="{ item }">
						{{ item.dateOfBirth }}
					</template>
					<template v-slot:[`item.sex`]="{ item }">
						{{ item.sex }}
					</template>
					<template v-slot:[`item.bedNumber`]="{ item }">
						{{ item.bedNumber }}
					</template>
					<template v-slot:[`item.checkedInAt`]="{ item }">
						{{ item.checkedInAt }}
					</template>
					<template v-slot:[`item.caseWorker`]="{ item }">
						{{ item.caseWorker }}
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									small
									@click="viewResident(item)">
									mdi-eye
								</v-icon>
							</template>
							<span class="tooltip-content">View Resident</span>
						</v-tooltip>
					</template>
				</v-data-table>
				<v-pagination
					v-model="page"
					:length="pageCount"
					:total-visible="7"
					color="red">
				</v-pagination>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
	import { searchPersonIntake } from "@/api"; // Ensure the correct path is provided

	export default {
		data() {
			return {
				headers: [
					{ text: "Full Name", value: "fullName" },
					{ text: "Date of Birth", value: "dateOfBirth" },
					{ text: "Sex", value: "sex" },
					{ text: "Bed Number", value: "bedNumber" },
					{ text: "Checked In At", value: "checkedInAt" },
					{ text: "Admitted by (Case Worker)", value: "caseWorker" },
					{ text: "Actions", value: "actions", sortable: false },
				],
				residents: [],
				search: "",
				page: 1,
				itemsPerPage: 10,
				loading: false,
			};
		},
		computed: {
			filteredResidents() {
				return this.residents.filter(
					(resident) =>
						resident.fullName
							.toLowerCase()
							.includes(this.search.toLowerCase()) ||
						resident.dateOfBirth
							.toLowerCase()
							.includes(this.search.toLowerCase()) ||
						resident.sex
							.toLowerCase()
							.includes(this.search.toLowerCase()) ||
						resident.bedNumber
							.toLowerCase()
							.includes(this.search.toLowerCase()) ||
						resident.checkedInAt
							.toLowerCase()
							.includes(this.search.toLowerCase()) ||
						resident.caseWorker
							.toLowerCase()
							.includes(this.search.toLowerCase())
				);
			},
			paginatedResidents() {
				const start = (this.page - 1) * this.itemsPerPage;
				const end = start + this.itemsPerPage;
				return this.filteredResidents.slice(start, end);
			},
			pageCount() {
				return Math.ceil(
					this.filteredResidents.length / this.itemsPerPage
				);
			},
		},
		created() {
			this.fetchResidents();
		},
		methods: {
			async fetchResidents() {
				this.loading = true;
				try {
					const response = await searchPersonIntake();
					this.residents = response.data.items.map((item) => ({
						fullName: `${item.person.last_name}, ${
							item.person.first_name
						} ${
							item.person.middle_name
								? item.person.middle_name.charAt(0) + "."
								: ""
						}`.trim(),
						dateOfBirth: new Date(
							item.person.date_of_birth
						).toLocaleDateString(),
						sex: item.person.sex,
						bedNumber: item.bed.bed_number,
						checkedInAt: new Date(
							item.checked_in_at
						).toLocaleDateString(),
						caseWorker: item.case_worker,
					}));
				} catch (error) {
					console.error("Error fetching residents:", error);
				} finally {
					this.loading = false;
				}
			},
			viewResident(resident) {
				// View resident logic here
				console.log("View:", resident);
			},
		},
	};
</script>

<style scoped>
	.residents-current-card {
		border: 1px solid #ccc;
		border-radius: 8px;
		padding: 4px;
		background-color: #fff;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
		min-width: fit-content;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
</style>
