<template>
	<v-container>
		<v-data-table
			:headers="headers"
			:items="localStays"
			class="elevation-1"
			:editable="editable">
			<template v-slot:[`item.duration`]="{ item }">
				{{ formatDuration(item.checkIn, item.checkOut) }}
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-btn v-if="editable" icon @click="editStay(item)">
					<v-icon>mdi-pencil</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</v-container>
</template>

<script>
	export default {
		name: "PersonHistory",
		props: {
			stays: {
				type: Array,
				required: true,
			},
			editable: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				localStays: [...this.stays],
				headers: [
					{ text: "Check In", value: "checkIn" },
					{ text: "Check Out", value: "checkOut" },
					{ text: "Duration", value: "duration" },
					{ text: "Actions", value: "actions", sortable: false },
				],
			};
		},
		methods: {
			formatDuration(checkIn, checkOut) {
				const start = new Date(checkIn);
				const end = new Date(checkOut);
				const duration = Math.floor(
					(end - start) / (1000 * 60 * 60 * 24)
				);
				return `${duration} days`;
			},
			editStay(stay) {
				// Implement the logic for editing the stay
				console.log("Edit Stay:", stay);
			},
		},
	};
</script>
