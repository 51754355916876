<template>
	<v-dialog
		v-model="localShow"
		max-width="800px"
		class="form-stepper-admit-dialog-main">
		<v-toolbar flat dense class="form-stepper-admit-toolbar sticky-toolbar">
			<v-toolbar-title class="dialog-title">Intake Form</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn
				icon
				@click="handleClose"
				class="form-stepper-admit-dialog-close-btn small-close-btn">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-toolbar>

		<v-card-text
			class="form-stepper-admit-dialog-content"
			v-if="step <= steps.length">
			<v-stepper v-model="step" vertical>
				<div v-for="(stepItem, index) in steps" :key="index">
					<v-stepper-step
						:complete="step > index + 1"
						:step="index + 1">
						<h3>{{ stepItem.title }}</h3>
						<p>{{ stepItem.subtitle }}</p>
					</v-stepper-step>
					<v-stepper-content :step="index + 1">
						<component
							:is="stepItem.component"
							:admitForm="admitForm"
							v-bind="stepItem.props"

              :canEditPersonInfo="canEditPersonInfo" 
              :personIDCards="personIDCards"
              :newPersonIDCards="newPersonIDCards"
              :selectedIdCards="selectedIdCards"
              @update:searchEthnicity="searchEthnicity = $event"
              @remove-card="removeCard"
              
              :personEmergencyContacts="personEmergencyContacts"
              :newPersonEmergencyContacts="newPersonEmergencyContacts"
              
              :searchEthnicity="searchEthnicity"
              :searchCitizenship="searchCitizenship"

              :selectedEmergencyContacts="selectedEmergencyContacts"
              @update:searchCitizenship="searchCitizenship = $event"
              @toggle-id-card-selection="toggleIdCardSelection"
              
              @update-new-emergency-contact-field="updateNewEmergencyContactField"
              @toggle-health-issue-selection="toggleHealthIssueSelection"
              :selectedHealthIssues="selectedHealthIssues"
              :healthIssuesList="healthIssuesList"
              :newPersonHealthIssues="newPersonHealthIssues"
              @remove-health-issue="removeHealthIssue"
              @update-new-health-issue-entry="updateNewHealthIssueEntry"

              :selectedAllergies="selectedAllergies"
              @show-add-allergy-dialog="showAddAllergyDialog"
              :newPersonAllergies="newPersonAllergies"
              @toggle-allergy-selection="toggleAllergySelection"
              @remove-allergy="removeAllergy"

              :selectedAddictions="selectedAddictions"
              :newPersonAddictions="newPersonAddictions"
              :addictionsList="addictionsList"
              @toggle-addiction-selection="toggleAddictionSelection"
              @remove-addiction="removeAddiction"
              @update-new-addiction-entry="updateNewAddictionEntry"

              :mentalHealthIssuesList="mentalHealthIssuesList"
              :selectedselectedMentalHealthIssues="selectedMentalHealthIssues"
              :newPersonMentalHealthIssues="newPersonMentalHealthIssues"
              @toggle-mental-health-issue-selection="toggleMentalHealthIssueSelection"
              @remove-mental-health="removeMentalHealth"
              @update-new-mental-health-issue-entry="updateNewMentalHealthIssueEntry"
              
							@update-admit-form="updateAdmitForm"
							@update-emergency-contact="updateEmergencyContact"
							@update-health-group="updateHealthGroup"
							@update-addiction-group="updateAddictionGroup"
							@update-mental-health-group="
								updateMentalHealthGroup
							"
							@add-emergency-contact="addEmergencyContact"
              @add-new-emergency-contact="addNewEmergencyContact"
              @toggle-emergency-card-selection="toggleEmergencyCardSelection"
              @add-new-health-issue="addNewHealthIssue"
              @add-new-addiction="addNewAddiction"
              @add-new-mental-health-issue="addNewMentalHealthIssue"
              
							@remove-emergency-contact="removeEmergencyContact"
							@add-health-group="addHealthGroup"
							@remove-health-group="removeHealthGroup"
							@add-addiction-group="addAddictionGroup"
							@remove-addiction-group="removeAddictionGroup"
							@add-mental-health-group="addMentalHealthGroup"
							@remove-mental-health-group="
								removeMentalHealthGroup
							"
              
              @show-profile-photo-dialog="showProfilePhotoDialog"
              @show-add-card-dialog="showAddCardDialog"
              
              ></component>
						<v-card-actions>
							<v-spacer></v-spacer>
              <span v-if="index <= 0 && !isNewPerson">
                <v-btn
                  @click="enableEditPersonInfo">
                  <span 
                    v-if="!canEditPersonInfo"
                    >
                    Edit
                  </span>
                  <span v-else
                    @click="updatePersonInfo"
                    >
                    
                    Save
                  </span>
                </v-btn>
              </span>
							<v-btn
								v-if="index !== 0"
								@click="prevStep"
								class="form-stepper-admit-dialog-previous-btn">
								Previous
							</v-btn>
							<v-btn
								v-if="index === steps.length - 1"
								@click="handleDone"
								class="form-stepper-admit-dialog-done-btn">
								Done
							</v-btn>
							<v-btn
								v-else
								@click="nextStep"
								class="form-stepper-admit-dialog-next-btn">
								Next
							</v-btn>
						</v-card-actions>
					</v-stepper-content>
				</div>
			</v-stepper>
		</v-card-text>

		<!-- Other dialogs and components here -->

		<!-- Confirmation Dialog for Closing -->
		<v-dialog v-model="confirmCloseDialog" max-width="600px">
			<v-card>
				<v-toolbar
					flat
					dense
					class="form-stepper-admit-toolbar sticky-toolbar">
					<v-toolbar-title class="dialog-title"
						>Save Changes?</v-toolbar-title
					>
					<v-spacer></v-spacer>
					<v-btn
						class="small-close-btn"
						icon
						@click="closeConfirmCloseDialog">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text>
					<p>Do you want to save your progress or clear the form?</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn class="theme-btn" @click="saveAndClose">Save</v-btn>
					<v-btn class="theme-btn" @click="clearAndClose"
						>Clear</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

    <!-- Dialog for Adding Profile Photo -->
		<v-dialog
			v-model="addProfilePhotoDialog"
			max-width="600px"
			class="form-stepper-admit-dialog-add-photo">
			<v-card>
				<v-toolbar
					flat
					dense
					class="form-stepper-admit-toolbar sticky-toolbar">
					<v-toolbar-title class="dialog-title"
						>Upload Profile Photo</v-toolbar-title
					>
					<v-spacer></v-spacer>
					<v-btn
						icon
						@click="closeProfilePhotoDialog"
						class="form-stepper-admit-dialog-close-btn small-close-btn">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text>
          
          <v-spacer class="my-4"></v-spacer>
					<file-upload
						label="Profile Photo"
            @file-change="onFileChange"
						v-model="newProfilePhoto"></file-upload>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="addNewProfilePhoto">Upload</v-btn>
					<v-btn @click="closeProfilePhotoDialog">Cancel</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

    <!-- Dialog for Adding Card -->
		<v-dialog
			v-model="addCardDialog"
			max-width="600px"
			class="form-stepper-admit-dialog-add-card">
			<v-card>
				<v-toolbar
					flat
					dense
					class="form-stepper-admit-toolbar sticky-toolbar">
					<v-toolbar-title class="dialog-title"
						>Add Card</v-toolbar-title
					>
					<v-spacer></v-spacer>
					<v-btn
						icon
						@click="closeAddCardDialog"
						class="form-stepper-admit-dialog-close-btn small-close-btn">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text>
					<v-select
						label="Card Type"
						:items="availableCardTypes"
						v-model="newCard.type"
						item-text="name"
						item-value="id"
            ></v-select>
					<v-text-field
						label="ID Number"
						v-model="newCard.idNumber"></v-text-field>
					<v-select
						label="Province"
						:items="provinces"
						item-text="text"
						item-value="value"
						v-model="newCard.province"></v-select>
					<file-upload
						label="Upload Card Image"
						v-model="newCard.image"></file-upload>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="addNewCard">Add</v-btn>
					<v-btn @click="closeAddCardDialog">Cancel</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

    <!-- Dialog for Adding Allergy -->
		<v-dialog
			v-model="addAllergyDialog"
			max-width="600px"
			class="form-stepper-admit-dialog-add-allergy">
			<v-card>
				<v-toolbar
					flat
					dense
					class="form-stepper-admit-toolbar sticky-toolbar">
					<v-toolbar-title class="dialog-title"
						>Add Allergy</v-toolbar-title
					>
					<v-spacer></v-spacer>
					<v-btn
						icon
						@click="closeAddAllergyDialog"
						class="form-stepper-admit-dialog-close-btn small-close-btn">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text>
					<v-select
						label="Allergy"
						:items="allergiesList"
            item-text="name"
						item-value="id"
						v-model="newAllergy.allergyId"></v-select>
        
            <v-text-field v-if="displayOtherAllergyInput(newAllergy.allergyId)"
              label="Other Allergy"
              v-model="newAllergy.otherAllergy"></v-text-field>
         
					<v-textarea
						label="Remarks"
						v-model="newAllergy.remarks"></v-textarea>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="addNewAllergy">Add</v-btn>
					<v-btn @click="closeAddAllergyDialog">Cancel</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Confirmation Dialog for Done -->
		<v-dialog v-model="confirmDoneDialog" max-width="600px">
			<v-card>
				<v-toolbar
					flat
					dense
					class="form-stepper-admit-toolbar sticky-toolbar">
					<v-toolbar-title class="dialog-title"
						>Complete Intake</v-toolbar-title
					>
					<v-spacer></v-spacer>
					<v-btn
						class="small-close-btn"
						icon
						@click="closeConfirmDoneDialog">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text>
					<p>
						Would you like to admit the person or continue with
						other forms?
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn class="theme-btn" @click="admitPerson">Admit</v-btn>
					<v-btn class="theme-btn" @click="continueWithForms"
						>Continue</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

    <!-- BedChard Dialog -->
    <v-dialog
			v-model="showDashboardBedchartDialog"
			max-width="auto"
			width="auto"
			class="form-stepper-admit-dialog-bedchart">
			<v-card class="dashboard-card">
				<v-toolbar flat dense>
					<div class="dialog-title" style="margin-left: -15px">
						Select Bed
					</div>
					<v-spacer></v-spacer>
					<v-btn
						icon
						@click="showDashboardBedchartDialog = false"
						class="form-stepper-admit-dialog-close-btn small-close-btn">
						<v-icon>mdi-close</v-icon>
					</v-btn>

				</v-toolbar>
        <v-card-text>
          
          <DashboardBedchart
            :isForInput="isForInput"
            @update-selected-bed-from-bed-chart="updateSelectedBedFromBedChart"
            @close="showDashboardBedchartDialog = false"
          />

          <v-card outlined>
            <v-card-text>
            <v-text-field
              label="Bed Number:"
              :value="selectedBedFromBedChart.id"
              required
              disabled></v-text-field>
            
            <v-text-field
              label="Room Number:"
              :value="selectedBedFromBedChart.room_number"
              required
              disabled></v-text-field>
            
            <v-text-field
              label="Building:"
              :value="selectedBedFromBedChart.building?.building_desc"
              required
              disabled></v-text-field>

            <v-text-field
            label="Case Worker"
            v-model="
              admitForm.caseWorker
            "
            required></v-text-field>
            </v-card-text>
          </v-card>

          <v-card outlined v-if="selectedBedFromBedChart && false">
            <v-card-text>
              <span>
                Bed Number: {{ selectedBedFromBedChart.id }}
              </span>
            </v-card-text>
            <v-card-text>
              <span>
                Room Number: {{ selectedBedFromBedChart.room_number }}
              </span>
            </v-card-text>
            <v-card-text>
              <span>
                Building: {{ selectedBedFromBedChart.building.building_desc }}
              </span>
            </v-card-text>
          </v-card>
          <!-- <v-select 
            :items="buildings"
            item-text="building_name"
            item-value="id"
            label="Buildings"
            v-model="selectedBuilding"
            :required="
              1
            "
            @change="handleSelectBuilding"
            >
          

          </v-select>

          <v-container v-if="selectedBuilding">
            <v-select 
              :items="bedList"
              item-text="bed_number"
              item-value="id"
              label="Available Beds"
              v-model="selectedBed"
              :required="
                1
              "
              
              >
            </v-select> 
          </v-container> -->

          <!-- <v-text-field
            label="Case Worker"
            v-model="
              admitForm.caseWorker
            "
            required></v-text-field> -->
        </v-card-text>
        <v-card-actions>
          <v-btn
						
						class="form-stepper-admit-dialog-continue-btn"
            @click="handleSelectBed"
						>SUBMIT</v-btn
					>
        </v-card-actions>
        
				<!-- <dashboard-bedchart
					@close="showDashboardBedchartDialog = false" /> -->
          <!-- <DashboardBedchart
            @close="showDashboardBedchartDialog = false"
          /> -->
			</v-card>
		</v-dialog>

    <!-- dashboard Case Worker -->
    <v-dialog
			v-model="showDashboardCaseWorkerDialog"
			max-width="auto"
			width="auto"
			class="form-stepper-admit-dialog-bedchart">
			<v-card class="dashboard-card">
				<v-toolbar flat dense>
					<div class="dialog-title" style="margin-left: -15px">
						Case Worker
					</div>
					<v-spacer></v-spacer>
					<v-btn
						icon
						@click="showDashboardCaseWorkerDialog = false"
						class="form-stepper-admit-dialog-close-btn small-close-btn">
						<v-icon>mdi-close</v-icon>
					</v-btn>

				</v-toolbar>
        <v-card-text>
          <v-text-field
            label="Case Worker"
            v-model="
              admitForm.caseWorker
            "
            required></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
						@click="handleSelectBed"
						class="form-stepper-admit-dialog-continue-btn"
						>SUBMIT</v-btn
					>
        </v-card-actions>

        
				<!-- <dashboard-bedchart
					@close="showDashboardBedchartDialog = false" /> -->
          <!-- <DashboardBedchart
            @close="showDashboardBedchartDialog = false"
          /> -->
			</v-card>
		</v-dialog>

    <!-- Confirmation Dialog -->
    <v-dialog v-model="confirmationDialog" max-width="400px">
        <v-card>
            <v-card-title class="headline">Confirm Shelter Intake</v-card-title>
            <v-card-text
                >Are you sure you want to admit this
                person?</v-card-text
            >
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="confirmationDialog = false">
                    Cancel
                </v-btn>
                <v-btn color="red darken-1" text @click="submitPostCreateIntake">
                    Confirm
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- SUCCESS MESSAGE -->
    <v-dialog 
      v-model="showSuccessDialog"
			max-width="auto"
			width="auto"
			class="form-stepper-admit-dialog-bedchart">

      <v-card class="dashboard-card">
				<v-toolbar flat dense>
					<div class="dialog-title" style="margin-left: -15px">
						Message
					</div>
					<v-spacer></v-spacer>
					<v-btn
						icon
						@click="closeSuccessDialog"
						class="form-stepper-admit-dialog-close-btn small-close-btn">
						<v-icon>mdi-close</v-icon>
					</v-btn>

				</v-toolbar>
        
        <v-card-text class="summary-dialog-content">
					<p>{{ postPersonShelterIntakeMessage }}</p>
				</v-card-text>
      </v-card>

    </v-dialog>

    <!-- Validation Dialog -->
    <v-dialog 
      v-model="showValidationDialog"
			max-width="auto"
			width="auto"
			class="form-stepper-admit-dialog-bedchart">

      <v-card class="dashboard-card">
				<v-toolbar flat dense>
					<div class="dialog-title" style="margin-left: -15px">
						Message
					</div>
					<v-spacer></v-spacer>
					<v-btn
						icon
						@click="showValidationDialog=false"
						class="form-stepper-admit-dialog-close-btn small-close-btn">
						<v-icon>mdi-close</v-icon>
					</v-btn>

				</v-toolbar>
        
        <v-card-text class="summary-dialog-content">
					<p>{{ validationMessage }}</p>
          <v-container v-if="validationMessages.length > 0">
            <span v-for="(index) in validationMessages" :key="index">
              {{ index }}
            </span>
          </v-container>
				</v-card-text>
      </v-card>

    </v-dialog>

    
	</v-dialog>
</template>

<script>
	import FormSectionIntakeStep1 from "./form-section-intake-step-1.vue";
	import FormSectionIntakeStep2 from "./form-section-intake-step-2.vue";
	import FormSectionIntakeStep3 from "./form-section-intake-step-3.vue";
	import FileUpload from "@/components/file-upload.vue";
	import DashboardBedchart from "@/components/bedchart-tabs.vue";
	import { 
    fetchProvinces, fetchEthnicity, fetchCitizenship,
    
    fetchPersonIDCards, fetchCardTypes,
    fetchPersonEmergencyContacts, fetchHealthIssues, fetchAllergies,
    fetchAddictions, fetchMentalHealthIssues,
    fetchBuildings, fetchBedListPerBuilding,
    updatePerson, storePersonProfilePhoto, storePersonShelterIntake

  } from "@/api";

	export default {
		components: {
			FormSectionIntakeStep1,
			FormSectionIntakeStep2,
			FormSectionIntakeStep3,
			FileUpload,
			DashboardBedchart,
		},
		props: {
			show: {
				type: Boolean,
				required: true,
			},
			person: {
				type: Object,
				default: () => ({}),
			},
		},
		data() {
      let person = this.person.person ? this.person.person : this.person;
			return {
        selectedPerson: this.person.person ? this.person.person : this.person,
        searchEthnicity: '',
        wasEthnicityUpdatedFromSelection: false,
        searchCitizenship: '',
        wasCitizenshipUpdatedFromSelection: false,
        isNewPerson: Object.keys(person).length > 0 ? false : true,
        canEditPersonInfo: Object.keys(person).length > 0 ? false : true,
        personIDCards: [],
        newPersonIDCards: [],

        selectedIdCards: this.person.intakeIdCards ? this.person.intakeIdCards.map(card => card.idCard.id) : [],
        selectedEmergencyContacts: this.person.intakeEmergencyContacts ? this.person.intakeEmergencyContacts.map(contact => contact.emergencyContact.id) : [],
        selectedHealthIssues: this.person.intakeHealthProfiles ? this.person.intakeHealthProfiles.slice() : [],
        selectedAllergies: this.person.intakeAllergies ? this.person.intakeAllergies.slice() : [],
        selectedAddictions: this.person.intakeAddictionProfiles ? this.person.intakeAddictionProfiles.slice() : [],
        selectedMentalHealthIssues: this.person.intakeMentalHealthProfiles ? this.person.intakeMentalHealthProfiles.slice() : [],

        selectedBedFromBedChart: '',
        selectedBed: '',
        selectedBuilding: '',

        provinces: [],
        cardTypes: [],
        healthIssuesList: [],
        allergiesList: [],
        addictionsList: [],
        mentalHealthIssuesList: [],

        buildings: [],
        bedListPerBuilding: [],
        bedList: [],

        personEmergencyContacts: [],
        newPersonEmergencyContacts: [],

        personHealthIssues: this.person ? this.person.intakeHealthProfiles : [],
        newPersonHealthIssues: [],

        newPersonAllergies: [],
        newPersonAddictions: [],
        newPersonMentalHealthIssues: [],

        isForInput: true,


				step: 1,
				newProfilePhoto: null,
				newCard: {
					type: "",
					idNumber: "",
					province: "",
					image: null,
				},
				newAllergy: {
					type: "",
					note: "",
				},
				admitForm: {
					fname: person?.first_name,
					mname: person?.middle_name,
					lname: person?.last_name,
					dob: person?.date_of_birth,
					sex: person?.sex,
					province: person?.province_id,
					ethnicity: person?.ethnicity_id ? person?.ethnicity_id : 11, //11 for Canadian
					citizenship: person?.citizenship ? person?.citizenship : 32, //32 for Canada
					profile_photo: person?.profile_pic ? {file: '', url: process.env.VUE_APP_API_HOST + `/picture/?location=`+ person?.profile_pic} : '',
					cards: this.person.intakeIdCards ? this.person.intakeIdCards : [],

					// isRefugee: person?.is_refugee ? person?.is_refugee : 0,
          isRefugee: person?.is_refugee === 1 ? true : false,
					isImmigrant:  person?.is_immigrant === 1 ? true : false,
					isVeteran: person?.is_veteran === 1 ? true : false,
          // isRefugee: person?.is_refugee === 1 ? person?.is_refugee : 0,
					// isImmigrant:  person?.is_immigrant === 1 ? person?.is_immigrant : 0,
					// isVeteran: person?.is_veteran === 1 ? person?.is_veteran : 0,
					homelessnessStatus: this.person.homelessness_status,
					homelessnessLength: this.person.homelessness_length,
					specialNote:  this.person.special_note,
          emergencyContacts : this.person.intakeEmergencyContacts ? this.person.intakeEmergencyContacts : [],
          
					// emergencyContacts: [
					// 	{
					// 		firstName: "",
					// 		middleName: "",
					// 		lastName: "",
					// 		relationship: "",
					// 		phone: "",
					// 		email: "",
					// 		note: "",
					// 	},
					// ],


          healthIssues: this.person.intakeHealthProfiles ? this.person.intakeHealthProfiles.slice() : [],
          otherHealthIssues: "",

          allergies: this.person.intakeAllergies ? this.person.intakeAllergies.slice() : [],

          addictions: this.person.intakeAddictionProfiles ? this.person.intakeAddictionProfiles.slice() : [],
					otherAddictions: "",

          mentalHealthIssues: this.person.intakeMentalHealthProfiles ? this.person.intakeMentalHealthProfiles.slice() : [],
					otherMentalHealthIssues: "",
					
          caseWorker: person?.case_worker,

					healthGroups: [],
					addictionGroups: [],
					mentalHealthGroups: [],
					// allergies: [],
				},
				addAllergyDialog: false,
				addProfilePhotoDialog: false,
				addCardDialog: false,
				summaryDialog: false,
				showDashboardBedchartDialog: false,
        showDashboardCaseWorkerDialog: false,
        confirmationDialog: false,
				confirmCloseDialog: false,
				confirmDoneDialog: false,

        showSuccessDialog: false,
        postPersonShelterIntakeMessage: '',
        postPersonShelterIntakeResponse: '',

        validationMessage: '',
        validationMessages: [],
        showValidationDialog: false,

				localShow: this.show,
				steps: [
					{
						title: "Step 1: Basic Information",
						subtitle: "Enter basic details of the person",
						component: "FormSectionIntakeStep1",
						props: {
              canEditPersonInfo: this.canEditPersonInfo,
							fields: [
								{
									label: "First Name",
									type: "text",
									model: "fname",
									required: true,
								},
								{
									label: "Middle Name",
									type: "text",
									model: "mname",
									required: false,
								},
								{
									label: "Last Name",
									type: "text",
									model: "lname",
									required: true,
								},
								{
									label: "Date of Birth",
									type: "date",
									model: "dob",
									required: true,
								},
								{
									label: "Sex",
									type: "select",
									model: "sex",
									required: true,
								},
								{
									label: "Province",
									type: "select",
									model: "province",
									required: true,
								},
								// {
								// 	label: "Ethnicity",
								// 	type: "select",
								// 	model: "ethnicity",
								// 	required: true,
								// },
								// {
								// 	label: "Citizenship",
								// 	type: "select",
								// 	model: "citizenship",
								// 	required: true,
								// },
                {
									label: "Ethnicity",
									type: "autocomplete",
									model: "ethnicity",
									required: true,
								},
								{
									label: "Citizenship",
									type: "autocomplete",
									model: "citizenship",
									required: true,
								},
							],
							provinces: [],
							ethnicities: [],
              searchEthnicity: this.searchEthnicity,
							citizenships: [],
              searchCitizenship: this.citizenship,
							sexOptions: [
								{ text: "Male", value: "Male" },
								{ text: "Female", value: "Female" },
								{ text: "Transgender", value: "Transgender" },
							],
						},
					},
					{
						title: "Step 2: Identification",
						subtitle: "Submission of Profile Photo & IDs",
						component: "FormSectionIntakeStep2",
					},
					{
						title: "Step 3: Demographics",
						subtitle: "More Information",
						component: "FormSectionIntakeStep3",
						props: {
              
							// homelessnessStatuses: [
							// 	"Status 1",
							// 	"Status 2",
							// 	"Status 3",
							// ],
							// homelessnessLengths: [
							// 	"Length 1",
							// 	"Length 2",
							// 	"Length 3",
							// ],
              homelessnessStatuses: [
                "Absolute Homeless", 
                "Homeless Due to Crisis"
              ],
              homelessnessLengths: [
                'Under 1 month',
                '1-6 months',
                '6-12 months',
                '1-3 Years',
                '3 Years or More',
                'N/A'
              ],
				
              emergencyContactsRelationshipTypes: [
                'Immediate Family',
                'Relative',
                'Friend',
                'Other',
              ],
						},
					},
				],

        shelterIntakePostData: {
          shelter_intake: {
            building_id: '',
            homelessness_status: '',
            homelessness_length: '',
            special_note: '',
            user_id: '',
            case_worker: '',
            bed_id: '',
          }
        }
			};
		},
		watch: {
			show(val) {
				this.localShow = val;
			},
			localShow(val) {
				if (!val) {
					this.$emit("close");
				}
			},
			person: {
				handler(val) {
					Object.assign(this.admitForm, val);
				},
				immediate: true,
				deep: true,
			},
      searchEthnicity: {
        handler: async function (val) {
          if (this.wasEthnicityUpdatedFromSelection) {
            this.wasEthnicityUpdatedFromSelection = false;
            return;
          }
          if (val != null) {
            if (val.length >= 3 || val.length === 0) {
              await this.getEthnicities({name: val});
            }
          }
        },
        immediate: true,
      },
      searchCitizenship: {
        handler: async function (val='') {
          if (this.wasCitizenshipUpdatedFromSelection) {
            this.wasCitizenshipUpdatedFromSelection = false;
            return;
          }

          if (val != null) {
            if (val.length >= 3 || val.length === 0) {
              await this.getCitizenships({name: val});

            }
          } 
        },
        immediate: true
      }

		},
		computed: {
			availableCardTypes() {
				return this.cardTypes.filter(
					(type) =>{
            if (!this.admitForm.cards || this.admitForm.cards.length === 0) {
              return true;
            }
						return !this.admitForm.cards.some((card) => card.type === type)
          }
				);
			},
		},
		methods: {
      enableEditPersonInfo() {
        this.canEditPersonInfo = !this.canEditPersonInfo;
      },

      async submitPostCreateIntake() {
        const formData = new FormData();
        this.shelterIntakePostData.intake_health_profile = [];
        this.shelterIntakePostData.intake_allergies = [];
        this.shelterIntakePostData.intake_addiction_profile = [];
        this.shelterIntakePostData.intake_mental_health_profile = [];
        this.shelterIntakePostData.intake_id_cards_presented = {};
        this.shelterIntakePostData.intake_emergency_contacts = {};

        if (Object.keys(this.selectedPerson).length > 0) {
          this.shelterIntakePostData.shelter_intake.person_id = this.selectedPerson.id;

        } else {
          this.shelterIntakePostData.shelter_intake.new_person = {
            first_name: this.admitForm.fname,
            middle_name: this.admitForm.mname ? this.admitForm.mname : '',
            last_name: this.admitForm.lname,
            date_of_birth: this.admitForm.dob,
            sex: this.admitForm.sex,
            ethnicity_id: this.admitForm.ethnicity,
            province_id: this.admitForm.province,
            citizenship: this.admitForm.citizenship,
            // profile_pic: this.admitForm.profile_photo.file, // File
            is_refugee: this.admitForm.isRefugee === 'true' ? 1 : 0,
            is_immigrant: this.admitForm.isImmigrant === 'true' ? 1 : 0,
            is_veteran: this.admitForm.isVeteran === 'true' ? 1 : 0,
          };
        }
        
        this.shelterIntakePostData.shelter_intake = {
          ...this.shelterIntakePostData.shelter_intake,
          homelessness_length: this.admitForm.homelessnessLength,
          homelessness_status: this.admitForm.homelessnessStatus,
          special_note: this.admitForm.specialNote,
          user_id: 1, // test user data for admin user id 1
          case_worker: this.admitForm.caseWorker, //test user data for Case Worker
          building_id: this.selectedBuilding.id, // test data building id 1
          bed_id: this.selectedBed, //test data bed id 1 available
            
        }

        if (this.selectedIdCards.length > 0) {
          this.shelterIntakePostData.intake_id_cards_presented = {
            ...this.shelterIntakePostData.intake_id_cards_presented,
            id_card_id: this.selectedIdCards
          };
        }

        if (this.newPersonIDCards.length > 0) {

          const formattedIDCards = this.newPersonIDCards.map(card => ({
            card_type_id: card.type,
            card_no: card.idNumber,
            card_upload: card.image ? card.image.file : null,
            province_id: card.province,
          }));

          this.shelterIntakePostData.intake_id_cards_presented = {
            ...this.shelterIntakePostData.intake_id_cards_presented,
            new_id_card: formattedIDCards
          }
        }

        if (this.selectedEmergencyContacts.length > 0) {
          this.shelterIntakePostData.intake_emergency_contacts.emergency_contact_id = this.selectedEmergencyContacts;
        }

        if (this.newPersonEmergencyContacts.length > 0) {
          const formattedEmergencyContacts = this.newPersonEmergencyContacts.map(contact => ({
            first_name: contact.first_name,
            middle_name: contact.middle_name ? contact.middle_name : '',
            last_name: contact.last_name,
            relationship: contact.relationship,
            phone: contact.phone,
            email: contact.email,
            note: contact.note

          }));

          this.shelterIntakePostData.intake_emergency_contacts = {
            ...this.shelterIntakePostData.intake_emergency_contacts,
            new_emergency_contact: formattedEmergencyContacts
          }
        }

        if (this.selectedHealthIssues.length > 0) {
          const formattedHealthIssues = this.selectedHealthIssues.map(issue => ({
            has_health_issues: issue.has_health_issues,
            health_issues_id: issue.health_issues_id,
            other_health_issues: issue.other_health_issues
          }));

          this.shelterIntakePostData.intake_health_profile = formattedHealthIssues;
        }

        if (this.newPersonHealthIssues.length > 0) {
          const formattedHealthIssues = this.newPersonHealthIssues.map(issue => ({
            has_health_issues: issue.status,
            health_issues_id: issue.issueId ? issue.issueId : null,
            other_health_issues: issue.otherHealthIssues ? issue.otherHealthIssues : null 
          }));

          this.shelterIntakePostData.intake_health_profile = [
            ...this.shelterIntakePostData.intake_health_profile,
            ...formattedHealthIssues
          ];
        }

        if (this.selectedAllergies.length > 0) {
          const formattedAllergies = this.selectedAllergies.map(allergy => ({
            allergy_id: allergy.allergy_id,
            other_allergy: allergy.other_allergy,
            remarks: allergy.remarks
          }));

          this.shelterIntakePostData.intake_allergies = formattedAllergies;
        }

        if (this.newPersonAllergies.length > 0) {
          const formattedAllergies = this.newPersonAllergies.map(allergy => ({
            allergy_id: allergy.allergyId ? allergy.allergyId : null,
            other_allergy: allergy.otherAllergy ? allergy.otherAllergy : null,
            remarks: allergy.remarks
          }));

          this.shelterIntakePostData.intake_allergies = [
            ...this.shelterIntakePostData.intake_allergies,
            ...formattedAllergies
          ];
        }

        if (this.selectedAddictions.length > 0) {
          const formattedAddictions = this.selectedAddictions.map(addiction => ({
            has_addictions: addiction.has_addictions,
            addictions_id: addiction.addictions_id,
            is_stated_or_observed: addiction.is_stated_or_observed,
            other_addictions: addiction.other_addictions
          }));
          this.shelterIntakePostData.intake_addiction_profile = formattedAddictions;
        }


        if (this.newPersonAddictions.length > 0) {
          const formattedAddictions = this.newPersonAddictions.map(addiction => ({
            has_addictions: addiction.status,
            addictions_id: addiction.addictionId ? addiction.addictionId :  null,
            is_stated_or_observed: addiction.evidence,
            other_addictions: addiction.otherAddictions ? addiction.otherAddictions : null
          }));

          this.shelterIntakePostData.intake_addiction_profile = [
            ...this.shelterIntakePostData.intake_addiction_profile,
            ...formattedAddictions
          ]
        }

        if (this.selectedMentalHealthIssues.length > 0) {
          const formattedMentalIssues = this.selectedMentalHealthIssues.map(mentalIssue => ({
            has_mental_health_issues: mentalIssue.has_mental_health_issues ? mentalIssue.has_mental_health_issues : null,
            mental_health_issues_id: mentalIssue.mental_health_issues_id ? mentalIssue.mental_health_issues_id : null,
            is_stated_or_observed: mentalIssue.is_stated_or_observed,
            other_mental_health_issues: mentalIssue.other_mental_health_issues
          }));
          this.shelterIntakePostData.intake_mental_health_profile = formattedMentalIssues;
        }

        if (this.newPersonMentalHealthIssues.length > 0) {
          const formattedMentalIssues = this.newPersonMentalHealthIssues.map(mentalIssue => ({
            has_mental_health_issues: mentalIssue.status,
            mental_health_issues_id: mentalIssue.issueId ? mentalIssue.issueId : null,
            is_stated_or_observed: mentalIssue.evidence,
            other_mental_health_issues: mentalIssue.otherMentalHealthIssues ? mentalIssue.otherMentalHealthIssues : null
          }));

          this.shelterIntakePostData.intake_mental_health_profile = [
            ...this.shelterIntakePostData.intake_mental_health_profile,
            ...formattedMentalIssues
          ]
        }

        const appendFormData = (data, root = '') => {
          for (let key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
              const value = data[key];
              const formKey = root ? `${root}[${key}]` : key;

              if (value === null) {
                // continue;
                formData.append(formKey, '');
              } else if (value instanceof File) {
                // If it's a file, append it directly
                formData.append(formKey, value);
              } else if (Array.isArray(value)) {
                // If it's an array, iterate over array items
                value.forEach((item, index) => {
                  if (typeof item === 'object' && !Array.isArray(item)) {
                    // Handle case where array contains objects (e.g., {id_card_id: []})
                    appendFormData(item, `${formKey}[${index}]`);
                  } else {
                    
                    // appendFormData(item, `${formKey}[${index}]`);
                    formData.append(`${formKey}[${index}]`, item);
                  }
                });
              } else if (typeof value === 'object' && value !== null) {
                // If it's an object, recurse with the object
                appendFormData(value, formKey);
              } else {
                // Otherwise, just append the value
                formData.append(formKey, value);
              }
            }
          }
        };

        // Convert shelterIntakePostData to FormData
        appendFormData(this.shelterIntakePostData);

        // For debugging: Print out all key-value pairs
        for (let pair of formData.entries()) {
          console.log(`${pair[0]}:`, pair[1]);
        }


        await this.postPersonShelterIntake(formData);
      },

      async postPersonShelterIntake(intakeInfo) {
        try {
          storePersonShelterIntake(intakeInfo).then(response => {
            if (response.data) {
              if (response.data) {
                this.postPersonShelterIntakeResponse = response.data;
                this.postPersonShelterIntakeMessage = response.data.message;
                this.showSuccessDialog = true;
              }
            } 
            
          })
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      },

      async updatePersonInfo() {
        const data = {
            first_name: this.admitForm.fname,
            middle_name: this.admitForm.mname ? this.admitForm.mname : '',
            last_name: this.admitForm.lname,
            date_of_birth: this.admitForm.dob,
            sex: this.admitForm.sex,
            ethnicity_id: this.admitForm.ethnicity,
            province_id: this.admitForm.province,
            citizenship: this.admitForm.citizenship,
            // profile_pic: this.admitForm.profile_photo.file, // File
            is_refugee: this.admitForm.isRefugee ? 1 : 0,
            // is_refugee: this.admitForm.isRefugee === 1 ? 1 : 0,
            is_immigrant: this.admitForm.isImmigrant  ? 1 : 0,
            is_veteran: this.admitForm.isVeteran ? 1 : 0,
        };
        await this.putPersonInfo(this.selectedPerson.id, data);
      },

      async putPersonInfo(person_id, personInfo) {
        try {
          updatePerson(person_id, personInfo).then(response => {
            console.log(JSON.stringify(response.data));
          });

        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      },

      onFileChange(fileData) {
        this.newProfilePhoto = fileData;
      },

      // isIdCardSelected(cardId) {
      //   alert(`selected ID Card: ${JSON.stringify(this.selectedIdCards)}`);
      //   const previouslyPresentedIdCards = this.admitForm.cards;
      //   let isSelected = false;
      //   if (previouslyPresentedIdCards) {
      //     isSelected = previouslyPresentedIdCards.some(card => card.id === cardId);
          
      //   }
      //   alert(this.selectedIdCards.includes(cardId) || isSelected);
      //   return this.selectedIdCards.includes(cardId) || isSelected;
      // },

      // isIdCardSelected(cardId) {
      //   const isSelected = this.selectedIdCards.includes(cardId);
      //   const isSelectedInForm = this.admitForm.cards.some(card => card.id === cardId);
      //   alert(isSelected || isSelectedInForm);
      //   return isSelected || isSelectedInForm;
      // },


      toggleIdCardSelection(cardId) {
        const index = this.selectedIdCards.indexOf(cardId);
        if (index === -1) {
          this.selectedIdCards.push(cardId);
        } else {
          this.selectedIdCards.splice(index, 1);
        }
      },
      // toggleIdCardSelection(cardId, isChecked) {
      //   alert('WOW');
      //   if (isChecked) {
      //     alert('isChecked');
      //     // If the checkbox is checked, add the card ID
      //     if (!this.selectedIdCards.includes(cardId)) {
      //       this.selectedIdCards.push(cardId);
      //     }
      //   } else {
          
      //     alert('NotChecked');
      //     // If unchecked, remove the card ID
      //     const index = this.selectedIdCards.indexOf(cardId);
      //     if (index !== -1) {
      //       this.selectedIdCards.splice(index, 1);
      //     }
      //   }

      //   // Debug: Check the updated selected cards
      //   console.log('Updated selected ID cards:', this.selectedIdCards);
      // },

      toggleEmergencyCardSelection(emerContId) {
        // const index = this.selectedEmergencyContacts.findIndex(contact => contact.id === emerContId);
        const index = this.selectedEmergencyContacts.indexOf(emerContId);
        if (index === -1) {
          this.selectedEmergencyContacts.push(emerContId);
        } else {
          this.selectedEmergencyContacts.splice(index, 1);
        }
      },

      updateNewEmergencyContactField(){
        
      },

      toggleHealthIssueSelection(issue) {
        const index = this.selectedHealthIssues.indexOf(issue);
        if (index === -1) {
          this.selectedHealthIssues.push(issue);
        } else {
          this.selectedHealthIssues.splice(index, 1);
        }
      },

      displayOtherAllergyInput(allergy) {
        return !allergy;
      },
      toggleAllergySelection(allergy) {
        const index = this.selectedAllergies.indexOf(allergy);
        if (index === -1) {
          this.selectedAllergies.push(allergy);
        } else {
          this.selectedAllergies.splice(index, 1);
        }
      },

      toggleAddictionSelection(addiction) {
        const index = this.selectedAddictions.indexOf(addiction);
        if (index === -1) {
          this.selectedAddictions.push(addiction);
        } else {
          this.selectedAddictions.splice(index, 1);
        }
      },

      removeAddiction(index) {
        this.newPersonAddictions.splice(index,  1)
      },
      
      addNewAddiction() {
        this.newPersonAddictions.push({});
      },

      updateNewAddictionEntry() {
        
      },




      
			nextStep() {
        if (this.step === 1) {
          if (!this.validateNewPerson()) {
            this.showValidationDialog = true;
            return;
          }
        }

        if (this.step === 2) {
          if (!this.validateIDCardData()) {
            this.showValidationDialog = true;
            return;
          }
        }

				if (this.step < this.steps.length) {
					this.step++;
				}
			},

      validateNewPerson() {
        this.validationMessage = '';
        if(Object.keys(this.selectedPerson).length < 1) {
          const requiredFields = ['fname', 'lname', 'dob', 'sex', 'ethnicity', 'province', 'citizenship'];
          const missingFields = requiredFields.some(field => !this.admitForm[field]);

          // const missingFields = this.admitForm.some(person =>
          //   !person.fname || !person.lname || !person.dob || !person.sex || 
          //   !person.ethnicity || !person.province || !person.citizenship
          // );

          if (missingFields) {
            this.validationMessage = "Person first name, last name, date of birth, sex, ethnicity, citizenship, and province fields are required.";
            return false;
          }
        }
        return true;
      },

      validateIntakeInfo() {
        const requiredFields = ['homelessnessLength', 'homelessnessStatus', 'specialNote'];
        const missingFields = requiredFields.some(intake => !this.admitForm[intake]);

        if (missingFields) {
          this.validationMessages.push('Homelessness Length, Homelessness Status, and Special Note is required');
          return false;
        }
        return true;
      },

      validateBed() {
        this.validationMessage = '';
        if (!this.admitForm.caseWorker || !this.selectedBuilding.id || !this.selectedBed) {
          this.validationMessage = 'Specify Bed number, building, and case worker.';
          return false;
        }

        return true;
      },

      validateIDCardData() {
        this.validationMessage = '';

        if (this.selectedIdCards.length === 0 && this.newPersonIDCards.length === 0) {
          this.validationMessage = "Provide Identification Cards.";
          return false;
        }

        if (this.newPersonIDCards.length > 0) {
          const missingFields = this.newPersonIDCards.some(card => 
            !card.type || !card.idNumber || !card.image.file || !card.province
          );

          if (missingFields) {
            this.validationMessage = "Identification card type, card number, card image, and province.";
            return false;
          }
        }

        return true;
      },

      validateEmergencyContacts() {
        if (this.selectedEmergencyContacts.length === 0 && this.newPersonEmergencyContacts.length === 0) {
          this.validationMessages.push('Provide Emergency Contact Information.');
          return false;
        }

        if (this.newPersonEmergencyContacts.length > 0) {
          const missingFields = this.newPersonEmergencyContacts.some(contact => 
            !contact.first_name || !contact.last_name || !contact.relationship || !contact.phone || !contact.email || !contact.note
          );
          
          if (missingFields) {
            this.validationMessages.push(`Emergency Contact's first name, last name, relationship, phone, email, and note are required`);
            return false;
          }
        }

        return true;
      },

      validateHealthIssues() {
        // Loop through each health issue to apply validation rules
        for (let issue of this.newPersonHealthIssues) {
          if (Object.keys(this.newPersonHealthIssues).length < 1) {
            this.validationMessages.push('No health issue specified.');
            return false;
          }
          // If status is "Yes", check for required fields
          if (issue.status === "Yes" || issue.status === 'Suspected or Possible') {
            if (!issue.issueId && !issue.otherHealthIssue) {
              this.validationMessages.push('No health issue specified.');
              return false;
            }
            
          }
        }

        return true; // Pass validation if all conditions are met
      },

      validateAllergies() {
        for (let allergy of this.newPersonAllergies) {
          if (Object.keys(this.newPersonAllergies).length < 1) {
            this.validationMessages.push('No Allergy Specified.');
            return false;
          }

          if (!allergy.allergyId && !allergy.otherAllergy) {
            this.validationMessages.push('No Allergy specified.');
            return false;
          }

          if (!allergy.remarks) {
            this.validationMessages.push('Remarks is required');
            return false;
          }

        }
        return true;
      },

      validateAddictions() {
        for (let addiction of this.newPersonAddictions) {
          if (Object.keys(this.newPersonAddictions).length < 1) {
            this.validationMessages.push('No Addiction specified.');
            return false;
          }

          if (addiction.status === 'Yes' || addiction.status === 'Suspected or Possible') {
            if (!addiction.addictionId && !addiction.otherAddictions) {
              this.validationMessages.push('No Addiction specified.');
              return false;
            }

            if (!addiction.evidence) {
              this.validationMessages.push('Specify whether addiction is Stated or Observed');
              return false;
            }
          }
        }

        return true;
      },
      validateMentalHealthIssues() {
        for (let issue of this.newPersonMentalHealthIssues) {
          if (Object.keys(this.newPersonMentalHealthIssues).length < 1) {
            this.validationMessages.push('No Mental Health Issue specified.');
            return false;
          }

          if(issue.status === 'Yes' || issue.status === 'Suspected or Possible') {
            if(!issue.issueId && !issue.otherMentalHealthIssues) {
              this.validationMessages.push('No Mental Health Issue specified.');
              return false;
            }

            if(!issue.evidence){
              this.validationMessages.push('Specify whether mental health issue is Stated or Observed.');
              return false;
            }
          }
        }
        return true;
      },




			prevStep() {
				if (this.step > 1) {
					this.step--;
				}
			},
			handleClose() {
				if (this.isFormDirty()) {
					this.confirmCloseDialog = true;
				} else {
					this.localShow = false;
				}
			},
			handleDone() {
        this.validationMessages = [];
        if(
          !this.validateIntakeInfo() || 
          !this.validateEmergencyContacts() ||
          !this.validateHealthIssues() ||
          !this.validateAllergies() ||
          !this.validateAddictions() ||
          !this.validateMentalHealthIssues()
        ) {
          this.showValidationDialog = true;
        } else {

          this.confirmDoneDialog = true;
        }

				// if (this.areRequiredFieldsFilled()) {
				// 	this.confirmDoneDialog = true;
				// } else {
				// 	alert("Please fill out all required fields.");
				// }
			},
			isFormDirty() {
				// Check if any field in the form has been filled out
				return Object.values(this.admitForm).some((value) => {
					if (Array.isArray(value)) {
						return value.length > 0;
					}
					return value !== "" && value !== null && value !== false;
				});
			},



			areRequiredFieldsFilled() {
				const requiredFields = this.steps.flatMap((step) =>
					step.props.fields
						?.filter((field) => field.required)
						.map((field) => field.model)
				);

				return requiredFields.every((field) => {
					const value = this.admitForm[field];
					if (Array.isArray(value)) {
						return value.length > 0;
					}
					return value !== "" && value !== null && value !== false;
				});
			},
			saveAndClose() {
				this.confirmCloseDialog = false;
				this.localShow = false;
			},
			clearAndClose() {
				this.confirmCloseDialog = false;
				this.clearForm();
				this.localShow = false;
			},
			clearForm() {
				Object.keys(this.admitForm).forEach((key) => {
					if (Array.isArray(this.admitForm[key])) {
						this.admitForm[key] = [];
					} else if (typeof this.admitForm[key] === "boolean") {
						this.admitForm[key] = false;
					} else {
						this.admitForm[key] = "";
					}
				});
			},
			admitPerson() {
				this.confirmDoneDialog = false;
				this.showDashboardBedchartDialog = true;
			},
			continueWithForms() {
				this.confirmDoneDialog = false;
			},
			updateAdmitForm({ key, value }) {
				this.$set(this.admitForm, key, value);
			},
			updateEmergencyContact({ index, key, value }) {
				this.$set(this.admitForm.emergencyContacts[index], key, value);
			},
			updateHealthGroup({ index, key, value }) {
				this.$set(this.admitForm.healthGroups[index], key, value);
			},
			updateAddictionGroup({ index, key, value }) {
				this.$set(this.admitForm.addictionGroups[index], key, value);
			},
			updateMentalHealthGroup({ index, key, value }) {
				this.$set(this.admitForm.mentalHealthGroups[index], key, value);
			},
			addEmergencyContact() {
				if (this.admitForm.emergencyContacts.length < 3) {
					this.admitForm.emergencyContacts.push({
						firstName: "",
						middleName: "",
						lastName: "",
						relationship: "",
						phone: "",
						email: "",
						note: "",
					});
				}
			},
      addNewEmergencyContact() {
        // if (this.personEmergencyContacts) {
					this.newPersonEmergencyContacts.push({
						first_name: "",
						middle_name: "",
						last_name: "",
						relationship: "",
						phone: "",
						email: "",
						note: "",
					});
				// }
			},
			removeEmergencyContact(index) {
        this.newPersonEmergencyContacts.splice(index, 1);
				// this.admitForm.emergencyContacts.splice(index, 1);
			},
      addNewHealthIssue() {
        this.newPersonHealthIssues.push({});
      },
      removeHealthIssue(index) {
        this.newPersonHealthIssues.splice(index, 1);
      },

      updateNewHealthIssueEntry() {
        
      },

      addNewMentalHealthIssue() {
        this.newPersonMentalHealthIssues.push({});
      },

      updateNewMentalHealthIssueEntry() {
        
      },

      removeMentalHealth(index) {
        this.newPersonMentalHealthIssues.splice(index, 1);
      },
      toggleMentalHealthIssueSelection(mentalIssue) {
        const index = this.selectedMentalHealthIssues.indexOf(mentalIssue);
        if (index === -1) {
          this.selectedMentalHealthIssues.push(mentalIssue);
        } else {
          this.selectedMentalHealthIssues.splice(index, 1);
        }
        
      },

      updateSelectedBedFromBedChart(bed) {
        this.selectedBedFromBedChart = bed;
        this.selectedBed = bed.id;
        this.selectedBuilding = bed.building;
      },
      





			addHealthGroup() {
				this.admitForm.healthGroups.push({
					healthIssues: [
						{ name: "Health Issue 1", status: "", evidence: "" },
						{ name: "Health Issue 2", status: "", evidence: "" },
						{ name: "Health Issue 3", status: "", evidence: "" },
					],
					otherHealthIssues: "",
					recordDate: new Date().toISOString().slice(0, 10),
				});
			},
			removeHealthGroup(index) {
				this.admitForm.healthGroups.splice(index, 1);
			},
			addAddictionGroup() {
				this.admitForm.addictionGroups.push({
					addictions: [
						{ name: "Addiction 1", status: "", evidence: "" },
						{ name: "Addiction 2", status: "", evidence: "" },
						{ name: "Addiction 3", status: "", evidence: "" },
					],
					otherAddictions: "",
					recordDate: new Date().toISOString().slice(0, 10),
				});
			},
			removeAddictionGroup(index) {
				this.admitForm.addictionGroups.splice(index, 1);
			},

      
			addMentalHealthGroup() {
				this.admitForm.mentalHealthGroups.push({
					mentalHealthIssues: [
						{
							name: "Mental Health Issue 1",
							status: "",
							evidence: "",
						},
						{
							name: "Mental Health Issue 2",
							status: "",
							evidence: "",
						},
						{
							name: "Mental Health Issue 3",
							status: "",
							evidence: "",
						},
					],
					otherMentalHealthIssues: "",
					recordDate: new Date().toISOString().slice(0, 10),
				});
			},
			removeMentalHealthGroup(index) {
				this.admitForm.mentalHealthGroups.splice(index, 1);
			},
			showAddAllergyDialog() {
				this.addAllergyDialog = true;
			},
			closeAddAllergyDialog() {
				this.addAllergyDialog = false;
			},
			// addNewAllergy() {
			// 	if (this.newAllergy.type && this.newAllergy.note) {
			// 		this.admitForm.allergies.push({ ...this.newAllergy });
			// 		this.newAllergy.type = "";
			// 		this.newAllergy.note = "";
			// 		this.addAllergyDialog = false;
			// 	} else {
			// 		alert("Please provide both the allergy type and note.");
			// 	}
			// },
      addNewAllergy() {
        let allergyName;
        let allergyType;

        if (this.newAllergy.allergyId) {

          allergyName = this.allergiesList.find(item => item.id === this.newAllergy.allergyId).name;
          allergyType = this.allergiesList.find(item => item.id === this.newAllergy.allergyId).type;
        }
        
        if (this.newAllergy.allergyId || this.newAllergy.otherAllergy) {
          this.newAllergy.type = allergyType ? allergyType : '';
          this.newAllergy.name = allergyName ? allergyName: ''; 
					this.newPersonAllergies.push({ ...this.newAllergy });
					this.newAllergy.allergyId = "";
					this.newAllergy.remarks = "";
          this.newAllergy.otherAllergy = "";
          this.newAllergy.type = "";
          this.newAllergy.name = "";
          
					this.addAllergyDialog = false;
				} else {
					alert("Please provide both the allergy type and note.");
				}
			},
			removeAllergy(index) {
				// this.admitForm.allergies.splice(index, 1);
        this.newPersonAllergies.splice(index, 1);
			},
			showProfilePhotoDialog() {
        this.addProfilePhotoDialog = true;
			},
			closeProfilePhotoDialog() {
				this.addProfilePhotoDialog = false;
			},
			async addNewProfilePhoto() {
				// if (this.newProfilePhoto) {
				// 	this.admitForm.profile_photo = {
				// 		url: URL.createObjectURL(this.newProfilePhoto),
				// 		file: this.newProfilePhoto,
				// 	};
				// 	this.newProfilePhoto = null;
				// 	this.addProfilePhotoDialog = false;
				// } else {
				// 	alert("Please upload a profile photo.");
				// }

        if (this.newProfilePhoto && this.newProfilePhoto.file) {
					this.admitForm.profile_photo = {
						url: this.newProfilePhoto.url,
						file: this.newProfilePhoto.file,
					};
          this.admitForm.profile_photo.url = this.newProfilePhoto.url;

          const formData = new FormData();
          formData.append('person_id', this.selectedPerson.id);
          formData.append('profile_pic', this.admitForm.profile_photo.file);

          await storePersonProfilePhoto(formData)
            .then(() => { 
              this.newProfilePhoto = null;
              this.addProfilePhotoDialog = false;
            });
				} else {
					alert("Please upload a profile photo!.");
				}
			},
			removeProfilePhoto() {
				this.admitForm.profile_photo = null;
			},
			showAddCardDialog() {
				this.addCardDialog = true;
			},
			closeAddCardDialog() {
				this.addCardDialog = false;
			},
			addNewCard() {
				if (
					this.newCard.type &&
					this.newCard.idNumber &&
					this.newCard.province &&
					this.newCard.image
				) {
					// const newCardObj = {
					// 	...this.newCard,
					// 	image: {
					// 		url: URL.createObjectURL(this.newCard.image),
					// 		file: this.newCard.image,
					// 	},
					// };

          const newCardObj = {
						...this.newCard,
					};

          newCardObj.cardTypeName = this.availableCardTypes.find(item => item.id === this.newCard.type).name;
          newCardObj.cardProvinceName = this.provinces.find(item => item.value === this.newCard.province).text;
          if (this.newCard.image){
            newCardObj.image =
              {
                url: this.newCard.image.url,
                file: this.newCard.image.file,
              };
          
          }


					// this.admitForm.cards.push(newCardObj);
          this.newPersonIDCards.push(newCardObj);

					this.newCard.type = "";
					this.newCard.idNumber = "";
					this.newCard.province = "";
					this.newCard.image = null;
					this.addCardDialog = false;
				} else {
          if (!this.validateIDCardData()){

            this.showValidationDialog = true;
          }
				}
			},
			removeCard(index) {
				// this.admitForm.cards.splice(index, 1);
				// this.personIDCards.splice(index, 1);
        this.newPersonIDCards.splice(index, 1);
			},
			async getProvinces() {
				try {
					const response = await fetchProvinces();
					const provincesData = response.data?.items || [];
					if (!Array.isArray(provincesData)) {
						throw new Error("Unexpected response structure.");
					}
					// this.steps[0].props.provinces = provincesData.map(
					// 	(province) => ({
					// 		text: province.name,
					// 		value: province.id,
					// 	})
					// );

          this.provinces = provincesData.map(
						(province) => ({
							text: province.name,
							value: province.id,
						})
					);

          this.steps[0].props.provinces = this.provinces;

				} catch (error) {
					console.error("Error fetching provinces:", error);
				}
			},
			async getEthnicities({name='', id=''}) {
				try {
          let fetchPromise;
          if (id) {
            fetchPromise = fetchEthnicity({id: id});
          } else if (name || name === '') {
            fetchPromise = fetchEthnicity({name: name});
          } else {
            throw new Error("Either name or id must be provided.");
          
          }

					const response = await fetchPromise;
					const ethnicityData = response.data?.items || [];
					if (!Array.isArray(ethnicityData)) {
						throw new Error("Unexpected response structure.");
					}
					// this.steps[0].props.ethnicities = ethnicityData.map(
					// 	(ethnicity) => ({
					// 		text: ethnicity.name,
					// 		value: ethnicity.id,
					// 	})
					// );
          this.steps[0].props.ethnicities = ethnicityData;
          
          
				} catch (error) {
					console.error("Error fetching ethnicities:", error);
				}
			},
			async getCitizenships({name = '', id = ''}) {
				try {
          let fetchPromise;

          if (id) {
            fetchPromise = fetchCitizenship({id: id});
          } else if (name || name === '') {
            fetchPromise = fetchCitizenship({name: name});
          } else {
            throw new Error("Either name or id must be provided.");
          }

					const response = await fetchPromise;
					const citizenshipData = response.data?.items || [];
					if (!Array.isArray(citizenshipData)) {
						throw new Error("Unexpected response structure.");
					}
					// this.steps[0].props.citizenships = citizenshipData.map(
					// 	(citizenship) => ({
					// 		text: citizenship.name,
					// 		value: citizenship.id,
					// 	})
					// );
          this.steps[0].props.citizenships = citizenshipData;
          
				} catch (error) {
					console.error("Error fetching citizenships:", error);
				}
			},

      async getPersonIDCards(person_id) {
        try {
          fetchPersonIDCards(person_id).then(response => {
            const response_data = response.data?.items || [];
            if (!Array.isArray(response_data)) {
              throw new Error("Unexpected response structure.");
            }
            this.personIDCards = response_data;
            
          });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },

      async getCardTypes() {
        try {
          fetchCardTypes()
          .then(response => {
            const cardTypesData = response.data?.items || [];
            if (!Array.isArray(cardTypesData)) {
              throw new Error("Unexpected response structure.");
            }
            this.cardTypes = cardTypesData;
            
          })

        } catch (error) {
          console.error("Error fetching identification card types");
        }
      },

      async getPersonEmergencyContacts(person_id) {
        try {
          fetchPersonEmergencyContacts(person_id).then(response => {
            const response_data = response.data?.items || [];
            if (!Array.isArray(response_data)) {
              throw new Error("Unexpected response structure.");
            }
            this.personEmergencyContacts = response_data;
          });

        } catch (error) {
            console.error("Error fetching data:", error);
        }
      },

      async getHealthIssues() {
        try {
          fetchHealthIssues()
          .then(response => {
            const healthIssuesData = response.data?.items || [];
            if (!Array.isArray(healthIssuesData)) {
              throw new Error("Unexpected response structure.");
            }
            healthIssuesData.unshift({ id: '', description: 'Other' });

            this.healthIssuesList = healthIssuesData;
          });
        } catch (error) {
          console.error("Error fetching health issues:", error);
        }
      },
      
      async getAllergies() {
        try {
          fetchAllergies().then(response => {
            const response_data = response.data?.items || [];
            if (!Array.isArray(response_data)) {
              throw new Error("Unexpected response structure.");
            }

            response_data.unshift({id: '', name: 'Other'});
            this.allergiesList = response_data;
          });

        } catch (error) {
            console.error("Error fetching data:", error);
        }
      },

      async getAddictions() {
        try {
          fetchAddictions().then(response => {
            const addictionsData = response.data?.items || [];
            if (!Array.isArray(addictionsData)) {
              throw new Error("Unexpected response structure.");
            }

            addictionsData.unshift({ id: '', name: 'Other'});
            this.addictionsList = addictionsData;
          });

        } catch (error) {
            console.error("Error fetching addictions:", error);
        }
      },

      async getMentalHealthIssues() {
        try {
          fetchMentalHealthIssues().then(response => {
            const response_data = response.data?.items || [];
            if (!Array.isArray(response_data)) {
              throw new Error("Unexpected response structure.");
            }
            
            response_data.unshift({id: '', description: 'Other'});
            this.mentalHealthIssuesList = response_data;
          });

        } catch (error) {
            console.error("Error fetching data:", error);
        }
      },

      async getBuildings() {
        try {
          const response = await fetchBuildings();
          const buildingsData = response.data?.items || [];
          if (!Array.isArray(buildingsData)) {
            throw new Error("Unexpected response structure.");
          }
          this.buildings = buildingsData;
        } catch (error) {
          console.error("Error fetching buildings:", error);
        }
      },

      handleSelectBuilding(id) {
        
        this.selectedBuilding = this.buildings.find(item => item.id === id);
        this.getBedListPerBuilding({getAvailableBeds:1, building_id: id}); 
      },

      async getBedListPerBuilding({getAvailableBeds = 0, building_id = ''}) {
        try {
          const response = await fetchBedListPerBuilding({get_available_beds: getAvailableBeds, building_id: building_id});
          const responseData = response.data?.items || [];
          if (!Array.isArray(responseData)) {
            throw new Error("Unexpected response structure.");
          }
          this.bedListPerBuilding = responseData;
          if (responseData[0].bedLists) {
            this.bedList = responseData[0].bedLists;
          }
        } catch (error) {
          console.error("Error fetching bed list:", error);
        }
      },

      handleSelectBed() {
        
        if (!this.validateBed()) {
          this.showValidationDialog = true;
        } else {
          this.confirmationDialog = true;
        }
        
      },

      closeSuccessDialog() {
        if (this.postPersonShelterIntakeResponse.success === true) {
          this.showDashboardCaseWorkerDialog = false;
          this.showDashboardBedchartDialog =false;
          this.localShow = false;
        }
      },


			closeConfirmCloseDialog() {
				this.confirmCloseDialog = false;
			},
			closeConfirmDoneDialog() {
				this.confirmDoneDialog = false;
			},
		},
		created() {
			this.getProvinces();
			this.getEthnicities(this.admitForm.ethnicity_id ? {id:this.admitForm.ethnicity_id } : {name:''});
			this.getCitizenships(this.admitForm.citizenship ? {id:this.admitForm.citizenship } : {name: ''});
			this.getCardTypes();
      this.getHealthIssues();
      this.getAllergies();
      this.getAddictions();
      this.getMentalHealthIssues();
      this.getBuildings();
      
      if (Object.keys(this.selectedPerson).length > 0) {
        this.getPersonIDCards(this.selectedPerson.id);
        this.getPersonEmergencyContacts(this.selectedPerson.id);
      }


      if (this.person) {
				Object.assign(this.admitForm, this.person);
				if (this.isExistingPerson) {
					this.admitForm.healthGroups.push({
						healthIssues: [
							{
								name: "Health Issue 1",
								status: "",
								evidence: "",
							},
							{
								name: "Health Issue 2",
								status: "",
								evidence: "",
							},
							{
								name: "Health Issue 3",
								status: "",
								evidence: "",
							},
						],
						otherHealthIssues: "",
						recordDate: "2023-11-22",
					});
					this.admitForm.addictionGroups.push({
						addictions: [
							{ name: "Addiction 1", status: "", evidence: "" },
							{ name: "Addiction 2", status: "", evidence: "" },
							{ name: "Addiction 3", status: "", evidence: "" },
						],
						otherAddictions: "",
						recordDate: "2023-11-22",
					});
					this.admitForm.mentalHealthGroups.push({
						mentalHealthIssues: [
							{
								name: "Mental Health Issue 1",
								status: "",
								evidence: "",
							},
							{
								name: "Mental Health Issue 2",
								status: "",
								evidence: "",
							},
							{
								name: "Mental Health Issue 3",
								status: "",
								evidence: "",
							},
						],
						otherMentalHealthIssues: "",
						recordDate: "2023-11-22",
					});
				}
			}
		},
	};
</script>
