<template>
	<div>
		<h1>Current Residents</h1>
		<v-container class="main-section-residents-current ma-1 pa-1" fluid>
			<ResidentsCurrent />
		</v-container>
	</div>
</template>

<script>
	import ResidentsCurrent from "./residents-current.vue";

	export default {
		name: "MainSectionResidentsCurrent",
		components: { ResidentsCurrent },
	};
</script>

<style scoped></style>
