<template>
	<div>
		<h1>Forms</h1>
		<v-container class="main-section-forms ma-1 pa-1" fluid>
			<FormsList />
		</v-container>
	</div>
</template>

<script>
	import FormsList from "./forms-list.vue";

	export default {
		name: "MainSectionForms",
		components: {
			FormsList,
		},
	};
</script>
