<template>
	<div>
		<h1>Dashboard</h1>
		<div class="dashboard-cards">
			<DashboardCard />
		</div>
		<v-container class="main-section-dashboard ma-0 pa-0" fluid>
			<v-row no-gutters align="stretch" class="dashboard-row">
				<v-col cols="12" md="6" class="dashboard-col-left pt-3 px-2">
					<DashboardBedChart class="dashboard-component" />
				</v-col>
				<v-col cols="12" md="6" class="dashboard-col-right pt-3 px-2">
					<DashboardRecentMoveouts class="dashboard-component" />
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import DashboardBedChart from "./bedchart-content.vue";
	import DashboardRecentMoveouts from "./residents-recent-moveouts.vue";
	import DashboardCard from "./dashboard-cards.vue";

	export default {
		name: "MainSectionDashboard",
		components: {
			DashboardCard,
			DashboardBedChart,
			DashboardRecentMoveouts,
		},
	};
</script>

<style scoped>
	.main-section-dashboard {
		height: auto; /* Allow the height to adjust */
	}

	.dashboard-row {
		margin: 0;
		padding: 0;
	}
	.dashboard-component {
		width: 100%;
		height: 100%;
	}
</style>
