<template>
  <v-dialog v-model="localShow" max-width="500px">
    <v-card>
      <v-toolbar
         flat
				dense
				class="form-stepper-admit-toolbar sticky-toolbar">
        <v-toolbar-title class="dialog-title"
					>Search Person to Discharge</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn class="small-close-btn" icon @click="closeDialog">
					<v-icon>mdi-close</v-icon>
				</v-btn>
      </v-toolbar>
      <v-card-text class="pt-5">
        <v-autocomplete
					v-model="selectedPerson"
					:items="searchPersonSelect"
          :search-input.sync="searchPerson"
					label="Search Person via Last Name"
					item-text="name"
					item-value="id"
					hide-details
					dense
					solo
					background-color="white"
					style="border-radius: 4px"
          @input="onSearchPersonInput"
					@change="onSearchPersonInput"
          ></v-autocomplete>

          <span v-if="selectedPerson">

            <v-card class="pa-2 mt-3" outlined>
              <v-card-title>
                Intake Details
              </v-card-title>
              <v-text-field
                class="pt-3"
                v-model="intakeDetail.bedDetail"
                label="Bed Detail"
                :disabled="true"
                required></v-text-field>
              
              <v-text-field
                class="pt-3"
                v-model="intakeDetail.homelessness_status"
                label="Homelessness Status"
                :disabled="true"
                required></v-text-field>
              
              <v-text-field
                class="pt-3"
                v-model="intakeDetail.homelessness_length"
                label="Homelessness Length"
                :disabled="true"
                required></v-text-field>
            
              <v-text-field
                class="pt-3"
                v-model="intakeDetail.special_note"
                label="Special Note"
                :disabled="true"
                required></v-text-field>

              <v-text-field
                class="pt-3"
                v-model="intakeDetail.checked_in_at"
                label="Checked in Date"
                :disabled="true"
                required></v-text-field>

              <v-text-field
                class="pt-3"
                v-model="intakeDetail.checked_in_by"
                label="Checked in By"
                :disabled="true"
                required></v-text-field>
              
              <v-text-field
                class="pt-3"
                v-model="intakeDetail.case_worker"
                label="Case Worker"
                :disabled="true"
                required></v-text-field>

            </v-card>

              <!-- Discharge Details form -->
            <v-card class="pa-2 mt-3" outlined>
            <v-card-title>
              Discharge Details
            </v-card-title>
                
              <v-text-field
                :disabled="!selectedPerson"
                class="pt-5"
                v-model="dischargeDetails.discharge_date"
                label="Discharge Date"
                type="date"
                required></v-text-field>

              <v-text-field
                class="pt-3"
                v-model="dischargeDetails.conclusion_service_reason"
                label="Reason for Conclusion of Service"
                :disabled="!selectedPerson"
                required></v-text-field>
              
              <v-text-field
                class="pt-3"
                v-model="dischargeDetails.conduct_during_stay"
                label="Conduct During Stay"
                :disabled="!selectedPerson"
                required></v-text-field>
              
              <v-text-field
                class="pt-3"
                v-model="dischargeDetails.checkout_reason"
                label="Reason for Checkout"
                :disabled="false"
                required></v-text-field>
              
              <v-card class="pa-3 mb-3" outlined>

                <v-checkbox
                :disabled="!selectedPerson"
                :input-value="false"
                label="Is Client Housed after Discharge?"
                @change="is_housed = !is_housed"
                >
                </v-checkbox>

                <span v-if="is_housed"
                >
                  <v-card outlined class="pa-3">

                    <v-checkbox
                      :disabled="!selectedPerson"
                      :input-value="false"
                      label="Is Housing a Treatment Facility?"
                      @change="is_treatment_facility = !is_treatment_facility"
                    ></v-checkbox>

                    <span v-if="!is_treatment_facility"
                    >
                      
                      <v-text-field
                        class="pt-3"
                        v-model="dischargeDetails.house_rent"
                        label="Rent (in CAD per month)"
                        :disabled="!selectedPerson"
                        required></v-text-field>

                    </span>

                    <v-text-field
                        class="pt-3"
                        v-model="dischargeDetails.house_landlord"
                        :label="is_treatment_facility ? `Name of Treatment Facility` : `Landlord Name`"
                        :disabled="!selectedPerson"
                        required></v-text-field>

                    <v-text-field
                      class="pt-3"
                      v-model="dischargeDetails.house_address"
                      label="Address"
                      :disabled="!selectedPerson"
                      required></v-text-field>
                    
                    <v-text-field
                      class="pt-3"
                      v-model="dischargeDetails.house_phone"
                      label="Phone Number"
                      :disabled="!selectedPerson"
                      required></v-text-field>
                  
                    </v-card>
                  </span>
                  <span v-else>
                    <v-text-field
                      class="pt-3"
                      v-model="dischargeDetails.not_housed_reason"
                      label="Reason why Client will not be housed after discharge?"
                      :disabled="!selectedPerson"
                      required></v-text-field>
                  </span>
                  
              
                

              </v-card>
              
              <v-card outlined class="pa-3">
  
                <v-checkbox
                :disabled="!selectedPerson"
                  :input-value="false"
                  label="Ban Client?"
                  @change="toggleBanClient()"
                >
                  </v-checkbox>
                
                <span v-if="is_banned">
                  <v-text-field
                  class="pt-3"
                  v-model="dischargeDetails.status_update_remark"
                  label="Remark for Ban"
                  :disabled="!selectedPerson"
                  required></v-text-field>
  
                  <v-checkbox
                  :disabled="!selectedPerson"
                  :input-value="false"
                  label="Add Condition for Reentry?"
                  @change="toggleReentryCondition()"
                >
                  </v-checkbox>
  
                <v-text-field
                  v-if="has_reentry_condition"
                  class="pt-3"
                  v-model="dischargeDetails.reentry_condition"
                  label="Reentry Condition"
                  :disabled="!selectedPerson"
                  required></v-text-field>
                  
                </span>

              </v-card>
            </v-card>
          

          
<!--           
          <v-text-field
            class="pt-3"
            v-model="dischargeDetails.reentry_condition"
            label="Condition for Reentry"
            :disabled="false"
            required></v-text-field>
          
          <v-text-field
            class="pt-3"
            v-model="dischargeDetails.checkout_reason"
            label="Condition for Reentry"
            :disabled="false"
            required></v-text-field> -->
          
          
          
          
          

            
        </span>
      </v-card-text>
      <v-card-actions class="dialog-actions">
        <v-btn 
          :disabled="isButtonDisabled"
          class="theme-btn"
          @click="confirmDischarge"
        >
        Discharge

        </v-btn>
      </v-card-actions>

    </v-card>

    <!-- Confirmation Dialog -->
    <v-dialog v-model="confirmationDialog" max-width="400px">
        <v-card>
            <v-card-title class="headline">Confirm Discharge</v-card-title>
            <v-card-text
                >Are you sure you want to discharge this
                person?</v-card-text
            >
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="confirmationDialog = false">
                    Cancel
                </v-btn>
                <v-btn color="red darken-1" text @click="dischargePerson">
                    Confirm
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog 
      v-model="showSuccessDialog"
			max-width="auto"
			width="auto"
			class="form-stepper-admit-dialog-bedchart">

      <v-card class="dashboard-card">
				<v-toolbar flat dense>
					<div class="dialog-title" style="margin-left: -15px">
						Message
					</div>
					<v-spacer></v-spacer>
					<v-btn
						icon
						@click="closeSuccessDialog"
              
						class="form-stepper-admit-dialog-close-btn small-close-btn">
						<v-icon>mdi-close</v-icon>
					</v-btn>

				</v-toolbar>
        
        <v-card-text class="summary-dialog-content">
					<p>{{ postDischargeMessage }}</p>
          {{ postDischargeError }}
				</v-card-text>
      </v-card>

    </v-dialog>

    <!-- Validation -->
    <v-dialog 
      v-model="showValidationDialog"
			max-width="auto"
			width="auto"
			class="form-stepper-admit-dialog-bedchart">

      <v-card class="dashboard-card">
				<v-toolbar flat dense>
					<div class="dialog-title" style="margin-left: -15px">
						Message
					</div>
					<v-spacer></v-spacer>
					<v-btn
						icon
						@click="showValidationDialog=false"
              
						class="form-stepper-admit-dialog-close-btn small-close-btn">
						<v-icon>mdi-close</v-icon>
					</v-btn>

				</v-toolbar>
        
        <v-card-text class="summary-dialog-content">
					<p>{{ validationMessage }}</p>
				</v-card-text>
      </v-card>

    </v-dialog>
    
  </v-dialog>
</template>

<script>
import {fetchActiveIntakes, fetchPersonMostRecentShelterIntake, storeIntakeDischarge} from "@/api";

  export default {
    props: {
      value: {
				type: Boolean,
				required: true,
			},
    },

    data() {
      return {
        localShow: this.value,

        isButtonDisabled: true,
        
				selectedPerson: null,
        searchPerson: '',

        has_reentry_condition: false,
        is_housed: false,
        is_treatment_facility: false,
        is_banned: false,

        confirmationDialog: false,
        showSuccessDialog: false,

        postDischargeMessage: '',
        postDischargeResponse: '',
        postDischargeError: '',

        dischargeDetails: {
          discharge_date: '',
          is_banned:0,
        },
        intakeDetail: {},

        searchPersonSelect: [],
        selectedPersonInfo: {},

        personMostRecentShelterIntakeResponse: {},
        personMostRecentShelterIntake: {},

        validationMessage: '',
        showValidationDialog: false
      };

    },
    watch: {
      value(val) {
				this.localShow = val;
			},
      localShow(val) {
				this.$emit("input", val);
			},

      searchPerson: {
        handler: async function (val){
          if (val != null) {
            if (val.length >=3 || val.length == 0) {
              await this.getPersonsActiveIntakes({search_name: val});
            }
          }
        },
        immediate: true,
      },

      selectedPerson(val) {
        this.isButtonDisabled = true;
        if (val) {
          this.getPersonMostRecentShelterIntake(val);
          this.dischargeDetails = {
            ...this.dischargeDetails,
            discharge_date: this.getCurrentDate(),
          };
        }

      },

      
    },
    computed: {
    },
    methods: {
      closeDialog() {
				this.localShow = false;
			},

      getCurrentDate() {
        const today = new Date();
        return today.toISOString().slice(0, 10); // Formats date as YYYY-MM-DD
      },

      toggleReentryCondition() {
        this.has_reentry_condition = !this.has_reentry_condition;
        this.dischargeDetails.reentry_condition = null;
      },

      toggleBanClient() {
        this.is_banned = !this.is_banned;
        if (this.is_banned) {
          this.dischargeDetails = {
            ...this.dischargeDetails,
            is_banned: 1,
            is_ban_lifted: 0
          }
        } else {
          this.dischargeDetails = {
            ...this.dischargeDetails,
            is_banned: 0,
            is_ban_lifted: 1
          }
        }
        console.log(this.dischargeDetails.is_banned);
      },

      validateDischargeDetails() {
        this.validationMessage = '';
        const requiredFields = ['conclusion_service_reason', 'conduct_during_stay', 'checkout_reason'];
        const missingFields = requiredFields.some(field => !this.dischargeDetails[field]);

        if (missingFields) {
          this.validationMessage = 'Reason for Conclusion of Service, Conduct during stay, and Checkout Reason fields are required.';
          return false;
        }
        
        if (this.is_housed === false) {
          if (!this.dischargeDetails.not_housed_reason) {
            this.validationMessage = 'Reason for not being housed after being discharged is required.';
            return false;
          }
        } else {
          if (!this.dischargeDetails.house_address || !this.dischargeDetails.house_phone) {
            this.validationMessage = 'House Address and Phone number fields are required.';
            return false;

          }
        }

        if (this.dischargeDetails.is_banned === true) {
          if (!this.dischargeDetails.status_update_remark ) {
            this.validationMessage = 'Status Update Remark is required.';
            return false;
          }

          if (this.has_reentry_condition === true) {
            if (!this.reentry_condition) {
              this.validationMessage = 'Reentry Confition is required.';
              return false;
            }
          }

        }

        return true;



      },

      async dischargePerson() {

        if (!this.validateDischargeDetails()) {
          this.showValidationDialog = true;
          return;

        }

        try {


          let data = {
            
              intake_id: this.personMostRecentShelterIntake.id,
              conclusion_service_reason: this.dischargeDetails.conclusion_service_reason,
              conduct_during_stay: this.dischargeDetails.conduct_during_stay,
              reentry_condition: this.dischargeDetails.reentry_condition,
              checkout_reason: this.dischargeDetails.checkout_reason,
              
              was_housed: this.is_housed ? "Yes" : "No",
              not_housed_reason: !this.is_housed ? this.dischargeDetails.not_housed_reason : null,
              
                address: this.is_housed ? this.dischargeDetails.house_address : null,
                rent: this.is_housed ? this.dischargeDetails.house_rent : null,
                landlord: this.is_housed && this.is_treatment_facility ? null : this.dischargeDetails.house_landlord,
                treatment_facility: this.is_housed && this.is_treatment_facility ? this.dischargeDetails.house_landlord : null,
                phone_no: this.is_housed ? this.dischargeDetails.house_phone : null,
              
              is_banned: this.dischargeDetails.is_banned,
                is_ban_lifted: this.dischargeDetails.is_ban_lifted ,
                status_update_remark: this.is_banned ? this.dischargeDetails.status_update_remark : null,
              
              user_id: 1
            
          };

          console.log(data);
          await storeIntakeDischarge(data).then(response => {
            if (response.data) {
              console.log(JSON.stringify(response.data));

              this.postDischargeResponse = response.data;
              this.postDischargeMessage = response.data.message;
              if(this.postDischargeResponse.success === false){
                this.postDischargeError = response.data.error;
              } else {
                this.postDischargeError = '';
              }
              
              this.showSuccessDialog = true;

              if (response.data.success === true){
                this.confirmationDialog = false;
                // this.closeDialog();

                this.$notify({
                  type: "success",
                  title: "Successfully Discharged",
                  text: `Discharged on: ${new Date().toLocaleDateString()}`,
                });
              }
            } else {
              console.error("Error Discharging person");
            }
          });
          
        }  catch (error) {
            console.error("Error discharging person:", error);
        }

        // console.log('');
        // if (this.selectedPerson === null) {
        //   this.selectedPersonInfo = null;
        // }

        // this.$emit("dischargeIntake", this.personMostRecentShelterIntake ? this.personMostRecentShelterIntake : this.selectedPersonInfo);
      

      },

      confirmDischarge() {
        if (!this.validateDischargeDetails()){
          this.showValidationDialog = true;
        } else {

          this.confirmationDialog = true;
        }
      },

      closeSuccessDialog() {
        if(this.postDischargeResponse.success === true) {
          this.closeDialog();
        } 
        this.showSuccessDialog = false;
        this.confirmationDialog = false;
      },

      onSearchPersonInput() {
        this.getPersonsActiveIntakes({id: this.selectedPerson});
        // console.log(JSON.stringify(this.selectedPersonInfo));
        if (this.selectedPerson) {
          this.getPersonMostRecentShelterIntake(this.selectedPerson);
        }
      },  

      async getPersonsActiveIntakes({search_name='', id=''} = {}) {
        try {
        
          let fetchPromise;
          if ( id) {
            fetchPromise = fetchActiveIntakes({ person_id : id});
          } else if ((search_name || search_name === '')) {
            fetchPromise = fetchActiveIntakes({ last_name: search_name });
          } else {
            throw new Error("Either search_name or id must be provided.");
          }

          const response = await fetchPromise;
          
          const response_data = response.data || {};
          let searchPersonInfoItems = response_data.items;
          
          this.selectedPersonInfo = response_data.items[0];

          this.searchPersonSelect = searchPersonInfoItems.map(person => ({
            name: `${person.first_name} ${person.middle_name} ${person.last_name}`,
            id: person.id
          }));
          

        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },

      async getPersonMostRecentShelterIntake(person_id) {
        try {
          this.isButtonDisabled = false;
          fetchPersonMostRecentShelterIntake(person_id).then(response => {
            this. personMostRecentShelterIntakeResponse = response.data;
            const response_data = response.data?.items || [];
            if (!Array.isArray(response_data)) {
              throw new Error("Unexpected response structure.");
            }

            this.personMostRecentShelterIntake = this.personMostRecentShelterIntakeResponse.items[0];
            if (this.personMostRecentShelterIntake) {
              let intake = this.personMostRecentShelterIntake;
              this.intakeDetail = {
                bedDetail: `Bed ${intake.bed.bed_number}, Room ${intake.bed.room_number}, Building ${intake.bed.building.building_name}`,
                homelessness_status: intake.homelessness_status,
                homelessness_length: intake.homelessness_length,
                special_note: intake.special_note,
                checked_in_at: intake.checked_in_at,
                checked_in_by: intake.checkedInBy.username,
                case_worker: intake.case_worker
              }
            }
            
            console.log(JSON.stringify(this.personMostRecentShelterIntake));
          });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
      }

    },
    created() {
    }
  }
</script>
<style scoped>
</style>