<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<v-divider class="my-4"></v-divider>
				<p>Please upload a profile photo for the person:</p>
				<v-btn @click="showProfilePhotoDialog"
					>Upload Profile Photo</v-btn
				>
				<v-list v-if="admitForm.profile_photo">
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title>Profile Photo</v-list-item-title>
							<v-list-item-subtitle>
								<img
									:src="admitForm.profile_photo.url"
									alt="Profile Photo"
									style="max-width: 100px" />
							</v-list-item-subtitle>
						</v-list-item-content>
						<!-- <v-list-item-action>
							<v-btn icon @click="removeProfilePhoto">
								<v-icon>mdi-delete</v-icon>
							</v-btn>
						</v-list-item-action> -->
					</v-list-item>
				</v-list>

				<v-divider class="my-4"></v-divider>
				<p>Please add the person's identification cards:</p>
				<v-btn @click="showAddCardDialog">Add Card</v-btn>
<!-- START EXISTING ID CARDS -->
        <v-list>
          <v-list-item
            v-for="(
              idCard, idx
            ) in personIDCards"
            :key="'card-' + idx">

            
              <v-card
                :class="['card-container']"
                outlined
              >
                <v-list-item-content>
                  
                  <!-- <v-checkbox
                    :input-value="isIdCardSelected(idCard.id)"
                    @change="toggleIdCardSelection(idCard.id)"
                    
                  /> -->
                  <v-checkbox
                    :input-value="isIdCardSelected(idCard.id)"
                    @change="toggleIdCardSelection(idCard.id)"
                    
                  />
                  <v-list-item-title
                    >{{ idCard.cardType.name }} (ID:
                    {{ idCard.card_no }},
                    Province:
                    {{
                      idCard.province.name
                    }})
                    </v-list-item-title
                  >
                  <v-list-item-subtitle>
                    <img
                      :src="
                        apiHost + `/picture/?location=` + idCard.card_upload
                      "
                      alt="Card Image"
                      style="
                        max-width: 100px;
                      " />
                  </v-list-item-subtitle>
                </v-list-item-content>
                <!-- <v-list-item-action>
                  <v-btn
                    icon
                    @click="
                      removeCard(idx)
                    ">
                    <v-icon
                      >mdi-delete</v-icon
                    >
                  </v-btn>
                </v-list-item-action> -->
              
              </v-card>
          </v-list-item>
        </v-list>
<!-- END EXISTING ID CARDS -->
 <div v-if="false"> 
				<v-list>
					<v-list-item
						v-for="(card, idx) in admitForm.cards"
						:key="'card-' + idx">
						<v-list-item-content>
							<v-list-item-title
								>{{ card.type }} (ID: {{ card.idNumber }},
								Province:
								{{ card.province }})
                </v-list-item-title
							>
							<v-list-item-subtitle>
								<!-- <img
									:src="card.image.url"
									alt="Card Image"
									style="max-width: 100px" /> -->
							</v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-btn icon @click="removeCard(idx)">
								<v-icon>mdi-delete</v-icon>
							</v-btn>
						</v-list-item-action>
					</v-list-item>
				</v-list>
  </div>
        <v-list v-if="newPersonIDCards">
          <v-list-item
            v-for="(
              idCard, idx
            ) in newPersonIDCards"
            :key="'card-' + idx">

            
              <!-- <v-card
                :class="['card-container', { 'selected': isIdCardSelected(idCard.id) }]"
              > -->
              <v-card
                :class="['card-container']"
                outlined
              >
                <v-list-item-content>
                  <v-checkbox
                    :input-value="true"
                    disabled
                    
                  />
                  <v-list-item-title
                    >{{ idCard.cardTypeName }} (ID:
                    {{ idCard.idNumber }},
                    Province:
                    {{
                      idCard.cardProvinceName
                    }})</v-list-item-title
                  >
                  <v-list-item-subtitle>
                    <img
                      :src="
                        idCard.image.url
                      "
                      alt="Card Image"
                      style="
                        max-width: 100px;
                      " />
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    icon
                    @click="
                      removeCard(idx)
                    ">
                    <v-icon
                      >mdi-delete</v-icon
                    >
                  </v-btn>
                </v-list-item-action>
              
              </v-card>
          </v-list-item>
        </v-list>

			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	export default {
		props: {
			admitForm: Object,
      personIDCards: Array,
      newPersonIDCards: Array,
      selectedIdCards: Array,
		},
    data() {
      return {
        apiHost: process.env.VUE_APP_API_HOST,
      }
    },
		methods: {
			showProfilePhotoDialog() {
				this.$emit("show-profile-photo-dialog");
			},
			removeProfilePhoto() {
				this.$emit("remove-profile-photo");
			},
			showAddCardDialog() {
				this.$emit("show-add-card-dialog");
			},
			removeCard(index) {
				this.$emit("remove-card", index);
			},
      // isIdCardSelected(cardId) {
      //   this.$emit("is-id-card-selected", cardId);
      // },
      isIdCardSelected(cardId) {
        const previouslyPresentedIdCards = this.admitForm.cards;
        let isSelected = false;
        if (previouslyPresentedIdCards) {
          isSelected = previouslyPresentedIdCards.some(card => card.id === cardId);
          
        }
        return this.selectedIdCards.includes(cardId) || isSelected;
      },
      toggleIdCardSelection(cardId) {
        this.$emit("toggle-id-card-selection", cardId);
      }
		},
	};
</script>

<style scoped>
	/* Add any relevant styles here */
</style>
