import { render, staticRenderFns } from "./residents-recent-moveouts.vue?vue&type=template&id=9ab7d896&scoped=true"
import script from "./residents-recent-moveouts.vue?vue&type=script&lang=js"
export * from "./residents-recent-moveouts.vue?vue&type=script&lang=js"
import style0 from "./residents-recent-moveouts.vue?vue&type=style&index=0&id=9ab7d896&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ab7d896",
  null
  
)

export default component.exports