<template>
	<div>
		<h1>Search Item/ Belonging</h1>
		<v-container class="main-section-itemsbelongings-list ma-1 pa-1" fluid>
			<ItemsBelongingstList />
		</v-container>
	</div>
</template>

<script>
	import ItemsBelongingstList from "./itemsbelongings-list.vue";

	export default {
		name: "MainSectionItemsBelongingstList",
		components: { ItemsBelongingstList },
	};
</script>

<style scoped></style>
