<template>
	<v-dialog v-model="localShow" max-width="500px">
		<v-card>
			<v-toolbar
				flat
				dense
				class="form-stepper-admit-toolbar sticky-toolbar">
				<v-toolbar-title class="dialog-title"
					>Search Person to Admit</v-toolbar-title
				>
				<v-spacer></v-spacer>
				<v-btn class="small-close-btn" icon @click="closeDialog">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>
			<v-card-text class="pt-5">
				<!-- <v-autocomplete
					v-model="selectedPerson"
					:items="filteredPersons"
					label="Search Person"
					item-text="name"
					item-value="name"
					hide-details
					dense
					solo
					background-color="white"
					style="border-radius: 4px"
					@input="onSearchPersonInput"
					@change="onSearchPersonInput"></v-autocomplete> -->
        
        <v-autocomplete
					v-model="selectedPerson"
					:items="searchPersonSelect"
          :search-input.sync="searchPerson"
					label="Search Person via Last Name"
					item-text="name"
					item-value="id"
					hide-details
					dense
					solo
					background-color="white"
					style="border-radius: 4px"
          @input="onSearchPersonInput"
					@change="onSearchPersonInput"
          ></v-autocomplete>

			</v-card-text>
			<v-card-actions class="dialog-actions">
				<v-btn
					class="theme-btn"
					@click="viewPersonInfo"
					:disabled="!selectedPerson"
					>View</v-btn
				>
				<v-btn
          :disabled="isButtonDisabled"
					class="theme-btn"
					@click="addOrUpdatePerson"
					>
          <span v-if="!selectedPerson">Add New Person for Intake</span> 
          <span v-else>
            <!-- {{ personMostRecentShelterIntake }} -->
            <span v-if="personMostRecentShelterIntake?.dischargeDetail">
              <!-- {{ personMostRecentShelterIntake.dischargeDetails }} -->
              <span v-if="personMostRecentShelterIntake.dischargeDetail.is_banned === 1">
                Banned
              </span>
              <span v-else>
                Select this Person for Intake
              </span>
            </span>
            <span v-else>
              Active Intake 
            </span>
            
          </span> 
          
           </v-btn
				>
				<v-btn class="theme-btn" @click="clearSearch">Clear</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import { 
    // searchPersonIntake, 
    fetchPersonInfo, fetchPersonMostRecentShelterIntake } from "@/api"; // Import the API method

	export default {
		props: {
			value: {
				type: Boolean,
				required: true,
			},
		},
		data() {
			return {
				localShow: this.value,
				selectedPerson: null,
				persons: [],
        person: {},



        searchPersonInfoItems: [],
        searchPersonSelect: [],
        selectedPersonInfo: {},
        wasUpdatedFromSelection: false,
        searchPerson: '',
        personMostRecentShelterIntakeResponse: {},
        personMostRecentShelterIntake: {},
        isButtonDisabled: false

			};
		},
		watch: {
			value(val) {
				this.localShow = val;
			},
			localShow(val) {
				this.$emit("input", val);
			},
      searchPerson: {
        handler: async function (val) {
          if (this.wasUpdatedFromSelection) {
            this.wasUpdatedFromSelection = false;
            return;
          }

          if (val != null) {
            
            if (val.length >=3 || val.length == 0) {
              await this.getPersonsInfo({search_name: val});
            }
          }
          
        },
        immediate: true,
      },
			selectedPerson(val) {
        this.isButtonDisabled = false;
				this.wasUpdatedFromSelection = true;
        this.getPersonsInfo({id: val});
        if (val) {
          this.getPersonMostRecentShelterIntake(val);
        }
			},
      // async searchPerson(val) {
      //   if (val) {
      //     if (val.length >=3 || val.length == 0) {
      //       await this.getPersonsInfo(val);
      //     }
      //   }
      // }
		},
		computed: {
			filteredPersons() {
				if (!this.selectedPerson || this.selectedPerson.length < 3) {
					return this.persons;
				}
				return this.persons.filter((item) =>
					item.name
						.toLowerCase()
						.includes(this.selectedPerson.toLowerCase())
				);
			},
		},
		methods: {

      async getPersonsInfo({search_name='', id=''} = {}) {
        try {
        
          let fetchPromise;
          if ( id) {
            fetchPromise = fetchPersonInfo({ person_id : id});
          } else if ((search_name || search_name === '')) {
            fetchPromise = fetchPersonInfo({ last_name: search_name });
          } else {
            throw new Error("Either search_name or id must be provided.");
          }

          const response = await fetchPromise;
          
          const response_data = response.data || {};
          this.searchPersonInfoItems = response_data.items;
          
          this.selectedPersonInfo = response_data.items[0];

          this.searchPersonSelect = this.searchPersonInfoItems.map(person => ({
            name: `${person.first_name} ${person.middle_name} ${person.last_name}`,
            id: person.id
          }));
          

        } catch (error) {
          console.error("Error fetching data:", error);
        }
      },
      async getPersonMostRecentShelterIntake(person_id) {
        try {
          this.isButtonDisabled = true;
          fetchPersonMostRecentShelterIntake(person_id).then(response => {
            this.personMostRecentShelterIntakeResponse = response.data;
            const response_data = response.data?.items || [];
            if (!Array.isArray(response_data)) {
              throw new Error("Unexpected response structure.");
            }

            this.personMostRecentShelterIntake = this.personMostRecentShelterIntakeResponse.items[0];
            
            // this.person = this.personMostRecentShelterIntake.person;

            const item = this.personMostRecentShelterIntake;
            if (item) {
              this.person = {
                id: item.person_id,
                name: `${item.person.first_name} ${item.person.middle_name} ${item.person.last_name}`,
                bedNumber: item.bed.bed_number,
                date: item.checked_in_at,
                reason: item.homelessness_status,
              };

              if (item.dischargeDetail) {
                if (item.dischargeDetail.is_banned) {

                  this.isButtonDisabled = true;
                } else {
                  this.isButtonDisabled = false;
                }

              } else {
                this.isButtonDisabled = true;
              }
            } 

          });

        } catch (error) {
            console.error("Error fetching data:", error);
        }
      },


			// async fetchPersons() {
			// 	try {
			// 		const response = await searchPersonIntake();
			// 		this.persons = response.data.items.map((item) => ({
			// 			id: item.person_id,
			// 			name: `${item.person.first_name} ${item.person.middle_name} ${item.person.last_name}`,
			// 			bedNumber: item.bed.bed_number,
			// 			date: item.checked_in_at,
			// 			reason: item.homelessness_status,
			// 		}));
      //     console.log(`Fetch Persons: ${JSON.stringify(this.persons)}`);
			// 	} catch (error) {
			// 		console.error("Error fetching persons:", error);
			// 	}
			// },
			closeDialog() {
				this.localShow = false;
        this.selectedPerson = null;
			},
			onSearchPersonInput() {
				this.getPersonsInfo({id: this.selectedPerson});
        if(this.selectedPerson) {
          this.getPersonMostRecentShelterIntake(this.selectedPerson);
        }

			},
			viewPersonInfo() {
				// const person = this.persons.find(
				// 	(person) => person.name === this.selectedPerson
				// );
        const person = this.persons.find(
					(person) => person.name === 'John Doe'
				);
        
				console.log("Viewing this.person info:", this.person);
				console.log("Viewing person info:", person);
				
        this.$emit("view", this.person);
			},
			addOrUpdatePerson() {
				// const person = this.persons.find(
				// 	(person) => person.name === this.selectedPerson
				// );

				if (this.selectedPerson === null) {
          this.selectedPersonInfo = null;
        }
				this.$emit("addOrUpdate", this.personMostRecentShelterIntake ? this.personMostRecentShelterIntake : this.selectedPersonInfo);
        
			},
			clearSearch() {
				this.selectedPerson = null;
			},
		},
		created() {
			// this.fetchPersons();
      // this.getPersonsInfo('');
		},
	};
</script>

<style scoped>
	.theme--light.v-input textarea {
		color: black !important;
	}

	.v-application .primary--text {
		color: white;
	}
</style>
