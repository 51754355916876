<template>
	<v-dialog
		v-model="localShowDialog"
		width="80%"
		max-width="90%"
		absolute
		transition="dialog-bottom-transition">
		<v-card>
			<v-card-title
				class="red white--text d-flex justify-space-between align-center">
				<span class="align-left">
					{{ samplePerson.firstName }} {{ samplePerson.middleName }}
					{{ samplePerson.lastName }}
				</span>
				<div class="d-flex align-center">
					<v-chip class="theme-chip-alt mr-2" label>
						<v-icon left>mdi-pencil-off</v-icon>
						readonly
					</v-chip>
					<v-btn class="theme-btn-alt" icon @click="closeDialog">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>
			</v-card-title>
			<v-tabs
				v-model="activeTab"
				background-color="red"
				dark
				@change="handleTabChange">
				<v-tab v-for="tab in tabs" :key="tab" class="text-capitalize">{{
					tab
				}}</v-tab>
			</v-tabs>
			<div class="pa-1" style="background-color: white">
				<v-progress-linear
					v-if="loading"
					indeterminate
					color="red"></v-progress-linear>
			</div>
			<v-tabs-items v-model="activeTab">
				<v-tab-item :value="tabs[0]">
					<person-profile
						:person="samplePerson"
						@contentLoaded="handleContentLoaded" />
				</v-tab-item>
				<v-tab-item :value="tabs[1]">
					<person-identification
						:person="samplePerson"
						@contentLoaded="handleContentLoaded" />
				</v-tab-item>
				<v-tab-item :value="tabs[2]">
					<person-progress-notes
						:notes="samplePerson.notes"
						@contentLoaded="handleContentLoaded" />
				</v-tab-item>
				<v-tab-item :value="tabs[3]">
					<person-history
						:stays="samplePerson.stays"
						@contentLoaded="handleContentLoaded" />
				</v-tab-item>
				<v-tab-item :value="tabs[4]">
					<person-demographics
						:profile="samplePerson.demographicsProfile"
						:emergency-contact="samplePerson.emergencyContact"
						:health-profile="samplePerson.healthProfile"
						:addictions="samplePerson.addictions"
						:mental-health="samplePerson.mentalHealth"
						@contentLoaded="handleContentLoaded" />
				</v-tab-item>
			</v-tabs-items>
		</v-card>
	</v-dialog>
</template>

<script>
	import PersonProfile from "./person-profile.vue";
	import PersonIdentification from "./person-identification.vue";
	import PersonProgressNotes from "./person-progress-notes.vue";
	import PersonHistory from "./person-history.vue";
	import PersonDemographics from "./person-demographics.vue";

	export default {
		name: "MainSectionViewPerson",
		components: {
			PersonProfile,
			PersonIdentification,
			PersonProgressNotes,
			PersonHistory,
			PersonDemographics,
		},
		props: {
			samplePerson: {
				type: Object,
				required: true,
			},
		},
		data() {
			return {
				localShowDialog: true,
				activeTab: "Profile",
				tabs: [
					"Profile",
					"Identification",
					"Notes",
					"Stays",
					"Demographics",
				],
				loading: false,
			};
		},
		methods: {
			closeDialog() {
				this.localShowDialog = false;
				this.$emit("close");
			},
			handleTabChange() {
				this.loading = true;
			},
			handleContentLoaded() {
				this.loading = false;
			},
		},
	};
</script>

<style scoped>
	.text-capitalize {
		text-transform: capitalize;
	}
	.red {
		background-color: #f44336 !important;
	}
	.white--text {
		color: white !important;
	}
</style>
