<template>
	<v-container>
		<v-data-table
			:headers="headers"
			:items="localNotes"
			class="elevation-1"
			:editable="editable">
			<template v-slot:[`item.actions`]="{ item }">
				<v-btn v-if="editable" icon @click="editNote(item)">
					<v-icon>mdi-pencil</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</v-container>
</template>

<script>
	export default {
		name: "PersonProgressNotes",
		props: {
			notes: {
				type: Array,
				required: true,
			},
			editable: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				localNotes: [...this.notes],
				headers: [
					{ text: "Date", value: "date" },
					{ text: "Time", value: "time" },
					{ text: "Comment", value: "comment" },
					{ text: "Actions", value: "actions", sortable: false },
				],
			};
		},
		methods: {
			editNote(note) {
				// Implement the logic for editing the note
				console.log("Edit Note:", note);
			},
		},
	};
</script>
