<template>
	<div>
		<h1>Manage Users</h1>
		<v-container class="main-section-user-management-list ma-1 pa-1" fluid>
			<UserManagementList />
		</v-container>
	</div>
</template>

<script>
	import UserManagementList from "./user-management-list.vue";

	export default {
		name: "MainSectionUserManagementList",
		components: { UserManagementList },
	};
</script>

<style scoped></style>
