<template>
	<v-card class="itembelonging-ccan-card">
		<v-card-title>
			<v-row style="width: 100%">
				<v-col cols="12" md="6">
					List of C-Cans
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-icon
								v-bind="attrs"
								v-on="on"
								small
								class="ml-2 info-icon">
								mdi-information-outline
							</v-icon>
						</template>
						<span class="tooltip-content">
							This table shows the list of C-Cans along with their
							details.
						</span>
					</v-tooltip>
				</v-col>
				<v-col cols="12" md="6">
					<v-text-field
						v-model="search"
						placeholder="Search C-Can:"
						hide-details
						dense
						solo
						background-color="white"
						style="border-radius: 4px"
						append-icon="mdi-package-variant">
					</v-text-field>
				</v-col>
			</v-row>
		</v-card-title>
		<v-card-text>
			<div class="table-container">
				<v-progress-linear v-if="loading" indeterminate color="red">
				</v-progress-linear>
				<v-data-table
					:headers="headers"
					:items="paginatedCans"
					:search="search"
					:items-per-page="5"
					:footer-props="{
						'items-per-page-options': [5, 10, 50, 100, 500],
						'items-per-page-text': 'C-Cans per page',
					}">
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									small
									class="mr-2"
									@click="viewCCan(item)">
									mdi-eye
								</v-icon>
							</template>
							<span class="tooltip-content">View C-Can</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									small
									class="mr-2"
									@click="editCCan(item)">
									mdi-pencil
								</v-icon>
							</template>
							<span class="tooltip-content">Edit C-Can</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									small
									@click="deleteCCan(item)">
									mdi-delete
								</v-icon>
							</template>
							<span class="tooltip-content">Delete C-Can</span>
						</v-tooltip>
					</template>
				</v-data-table>
				<v-pagination
					v-model="page"
					:length="pageCount"
					:total-visible="7"
					color="red">
				</v-pagination>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
	export default {
		data() {
			return {
				headers: [
					{ text: "ID", value: "id" },
					{ text: "Name", value: "name" },
					{ text: "Location", value: "location" },
					{ text: "Actions", value: "actions", sortable: false },
				],
				ccans: [],
				search: "",
				page: 1,
				itemsPerPage: 5,
				loading: false,
			};
		},
		computed: {
			filteredCans() {
				return this.ccans.filter(
					(ccan) =>
						ccan.name
							.toLowerCase()
							.includes(this.search.toLowerCase()) ||
						ccan.location
							.toLowerCase()
							.includes(this.search.toLowerCase())
				);
			},
			paginatedCans() {
				const start = (this.page - 1) * this.itemsPerPage;
				const end = start + this.itemsPerPage;
				return this.filteredCans.slice(start, end);
			},
			pageCount() {
				return Math.ceil(this.filteredCans.length / this.itemsPerPage);
			},
		},
		created() {
			this.loadDummyData();
		},
		methods: {
			loadDummyData() {
				this.loading = true;
				setTimeout(() => {
					this.ccans = Array.from({ length: 5 }, (_, i) => ({
						id: i + 1,
						name: `C-Can ${i + 1}`,
						location: `Location ${i + 1}`,
					}));
					this.loading = false;
				}, 1000);
			},
			viewCCan(ccan) {
				// View C-Can logic here
				console.log("View:", ccan);
			},
			editCCan(ccan) {
				// Edit C-Can logic here
				console.log("Edit:", ccan);
			},
			deleteCCan(ccan) {
				// Delete C-Can logic here
				console.log("Delete:", ccan);
			},
		},
	};
</script>

<style scoped>
	.ccan-search-input {
		width: 100%;
	}
	.itembelonging-ccan-card {
		margin-top: 20px;
	}
	.table-container {
		position: relative;
	}
</style>
