<template>
	<div v-if="report.title">
		<h1>{{ report.title }}</h1>
		<v-container class="main-section-reports-detail ma-1 pa-1" fluid>
			<ReportsDetail :report="report" />
		</v-container>
	</div>
	<div v-else>
		<p>Loading or report not found...</p>
	</div>
</template>

<script>
	import ReportsFetchData from "./reports-fetch-data.vue";
	import ReportsDetail from "./reports-detail.vue";

	export default {
		name: "MainSectionReportsDetail",
		components: {
			ReportsDetail,
		},
		data() {
			return {
				report: null,
			};
		},
		created() {
			const reportTitle = decodeURIComponent(this.$route.params.title);
			const fetchData = new ReportsFetchData();
			this.report = fetchData.getReportByTitle(reportTitle) || {
				title: "Report Not Found",
				description: "The report you are looking for does not exist.",
				content: "<p>No content available.</p>",
			};
		},
	};
</script>

<style scoped></style>
