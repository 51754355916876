<template>
	<div>
		<!-- Progress Banner -->
		<v-row class="justify-end" v-if="selectedPerson">
			<v-col cols="auto" class="d-flex align-center justify-end">
				<v-banner
					color="primary"
					class="progress-banner"
					style="
						transform: scale(0.7, 0.8);
						left: 15px;
						border-radius: 4px;
					">
					<template v-slot:prepend>
						<v-icon color="white">mdi-check-circle</v-icon>
					</template>
					<span class="progress-text">
						[ {{ completedForms }} / {{ totalForms }} ]
					</span>
				</v-banner>
			</v-col>
		</v-row>

		<v-row>
			<!-- Search Fields -->
			<v-col cols="12" md="6">
				<v-autocomplete
					v-model="selectedPerson"
					:items="personList"
					placeholder="Search Name:"
					hide-details
					dense
					class="person-search-input"
					solo
					background-color="white"
					style="
						border-radius: 4px;
						box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
					"
					append-icon="mdi-account"
					no-data-text="No Available Data"
					@change="onPersonSelect">
				</v-autocomplete>
			</v-col>
			<v-col cols="12" md="3">
				<v-autocomplete
					v-model="formSearchQuery"
					:items="formTitles"
					placeholder="Search Forms:"
					hide-details
					dense
					solo
					background-color="white"
					class="forms-search-input"
					style="
						border-radius: 4px;
						box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
					"
					append-icon="mdi-file-document"
					:disabled="!selectedPerson"
					@input="handleSearchFormsInput"
					@focus="clearSelectedStatuses">
				</v-autocomplete>
			</v-col>
			<v-col cols="12" md="3">
				<v-autocomplete
					v-model="selectedStatuses"
					:items="statusOptions"
					clearable
					chips
					label="Filter by Status"
					multiple
					solo
					background-color="white"
					class="status-autocomplete"
					style="box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3)"
					hide-details
					dense
					:disabled="!selectedPerson"
					@change="filterBySelectedStatuses"
					@focus="clearFormSearchQuery">
					<!-- Custom selection slot for chips -->
					<template v-slot:selection="data">
						<v-chip
							:color="getChipColor(data.item)"
							text-color="white"
							close
							@click:close="removeStatus(data.item)">
							{{ data.item }}
						</v-chip>
					</template>
					<!-- Custom item slot for dropdown items -->
					<template v-slot:item="data">
						<v-list-item
							:style="{
								backgroundColor: getChipColor(data.item),
							}"
							@click="addStatus(data.item)">
							<v-list-item-title
								v-text="data.item"
								style="
									color: white !important;
								"></v-list-item-title>
						</v-list-item>
					</template>
				</v-autocomplete>
			</v-col>
		</v-row>

		<!-- List of Forms -->
		<v-row>
			<v-col
				v-for="(form, index) in filteredForms"
				:key="index"
				cols="12"
				sm="6"
				md="3">
				<v-card class="mb-1 form-card">
					<v-card-title class="form-card-title">
						{{ form.title }}
						<v-spacer></v-spacer>
						<v-icon
							:class="getStatusClass(form)"
							class="status-icon">
							{{ getStatusIcon(form) }}
						</v-icon>
					</v-card-title>
					<v-divider></v-divider>
					<v-card-text class="form-card-body">
						{{ form.description }}
					</v-card-text>
					<v-card-actions class="form-card-actions">
						<v-btn
							small
							class="form-action-btn"
							@click="editForm(form)">
							Edit Form
						</v-btn>
						<v-btn
							small
							class="form-action-btn"
							@click="confirmClearForm(form)">
							Clear Form
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>

		<!-- Clear Form Dialog -->
		<v-dialog v-model="showClearDialog" max-width="600px">
			<v-card>
				<v-toolbar flat dense>
					<v-toolbar-title class="dialog-title">
						Clear Form - {{ selectedForm.title }}
					</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn icon @click="showClearDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text>
					<p>Are you sure you want to clear this form?</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="showClearDialog = false">Cancel</v-btn>
					<v-btn text @click="clearForm">Confirm</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				selectedPerson: "",
				personList: ["John Doe", "Jane Smith", "Robert Johnson"], // Replace with API data
				formSearchQuery: "",
				statusOptions: ["Completed", "Incomplete", "Unfilled"],
				selectedStatuses: [],
				forms: [
					{
						title: "Health Form",
						description: "Complete your health details",
						component: "form-health",
						completed: true,
						started: true,
						show: true,
					},
					{
						title: "Insurance Form",
						description: "Fill out insurance information",
						component: "form-insurance",
						completed: false,
						started: true,
						show: true,
					},
					{
						title: "Consent Form",
						description: "Sign the consent form",
						component: "form-consent",
						completed: true,
						started: true,
						show: true,
					},
					{
						title: "Personal Info Form",
						description: "Update your personal information",
						component: "form-personal-info",
						completed: false,
						started: false,
						show: true,
					},
					{
						title: "Emergency Contact Form",
						description: "Provide emergency contact details",
						component: "form-emergency-contact",
						completed: true,
						started: true,
						show: true,
					},
					{
						title: "Medical History Form",
						description: "Provide your medical history",
						component: "form-medical-history",
						completed: false,
						started: false,
						show: true,
					},
					{
						title: "Vaccination Form",
						description: "Update your vaccination details",
						component: "form-vaccination",
						completed: true,
						started: true,
						show: true,
					},
					{
						title: "Dietary Preferences Form",
						description: "Specify your dietary preferences",
						component: "form-dietary-preferences",
						completed: false,
						started: false,
						show: true,
					},
					{
						title: "Housing Form",
						description: "Fill out housing information",
						component: "form-housing",
						completed: true,
						started: true,
						show: true,
					},
					{
						title: "Work History Form",
						description: "Provide your work history",
						component: "form-work-history",
						completed: false,
						started: false,
						show: true,
					},
					{
						title: "Education Form",
						description: "Provide educational background",
						component: "form-education",
						completed: true,
						started: true,
						show: true,
					},
					{
						title: "Financial Aid Form",
						description: "Apply for financial aid",
						component: "form-financial-aid",
						completed: false,
						started: false,
						show: true,
					},
				],
				showClearDialog: false,
				selectedForm: {},
			};
		},
		computed: {
			filteredForms() {
				let filteredForms = this.forms.filter((form) => form.show);
				if (this.formSearchQuery) {
					filteredForms = filteredForms.filter((form) =>
						form.title
							.toLowerCase()
							.includes(this.formSearchQuery.toLowerCase())
					);
				}
				if (this.selectedStatuses.length) {
					filteredForms = filteredForms.filter((form) => {
						const matchesStatus =
							(this.selectedStatuses.includes("Completed") &&
								form.completed) ||
							(this.selectedStatuses.includes("Incomplete") &&
								!form.completed &&
								form.started) ||
							(this.selectedStatuses.includes("Unfilled") &&
								!form.started);
						return matchesStatus;
					});
				}
				return filteredForms;
			},
			completedForms() {
				return this.forms.filter((form) => form.completed).length;
			},
			totalForms() {
				return this.forms.length;
			},
			formTitles() {
				return this.forms.map((form) => form.title);
			},
		},
		methods: {
			onPersonSelect(person) {
				console.log("Selected Person:", person);
				this.formSearchQuery = "";
				this.selectedStatuses = [];
			},
			handleSearchFormsInput() {
				this.clearSelectedStatuses(); // Clear filter selections when search input changes
				this.filterFormsByTitle(); // Filter the forms by title
			},
			filterFormsByTitle() {
				// This method automatically triggers filteredForms computed property
				console.log("Filtering forms by title:", this.formSearchQuery);
			},
			filterForms() {
				console.log("Filtering forms");
			},
			editForm(form) {
				console.log("Edit Form:", form);
			},
			confirmClearForm(form) {
				this.selectedForm = form;
				this.showClearDialog = true;
			},
			clearForm() {
				console.log("Clearing Form:", this.selectedForm);
				this.showClearDialog = false;
			},
			filterBySelectedStatuses() {
				this.formSearchQuery = ""; // Clear the search form field
				console.log("Selected Statuses:", this.selectedStatuses);
			},
			clearFormSearchQuery() {
				this.formSearchQuery = ""; // Clear the search form field on focus
				console.log("Clearing Search Forms query due to Filter focus");
			},
			clearSelectedStatuses() {
				this.selectedStatuses = []; // Clear the filter statuses
				console.log(
					"Clearing Selected Statuses due to Search Forms input"
				);
			},
			getStatusIcon(form) {
				if (form.completed) {
					return "mdi-file-document-check"; // Green icon
				} else if (form.started) {
					return "mdi-file-document-edit"; // Red icon
				} else {
					return "mdi-file-document-alert"; // Grey icon
				}
			},
			getStatusClass(form) {
				return {
					"status-icon-completed": form.completed,
					"status-icon-incomplete": !form.completed && form.started,
					"status-icon-unfilled": !form.started,
				};
			},
			getChipColor(status) {
				switch (status) {
					case "Completed":
						return "#4CAF50"; // Green
					case "Incomplete":
						return "#F44336"; // Red
					case "Unfilled":
						return "#696969"; // Grey
					default:
						return "black"; // Fallback color
				}
			},
			removeStatus(status) {
				const index = this.selectedStatuses.indexOf(status);
				if (index !== -1) {
					this.selectedStatuses.splice(index, 1);
				}
			},
			addStatus(status) {
				if (!this.selectedStatuses.includes(status)) {
					this.selectedStatuses.push(status);
				}
			},
		},
	};
</script>

<style scoped>
	/* Ensure chips are styled correctly with dynamic colors */
	.v-chip {
		color: white !important; /* Ensure text is always white */
	}

	/* Form Card Styles */
	.form-card {
		min-height: 150px; /* Adjust height as needed */
		display: flex;
		flex-direction: column;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
		border: solid 1px #696969 !important;
		padding: 5px;
	}

	.form-card-title {
		font-weight: bold;
		padding: 4px;
		font-size: 18px;
		display: flex;
		align-items: center;
		background-color: white !important; /* Default card title background */
		color: black !important; /* Card title text color */
	}

	.form-card-title .v-icon {
		font-size: 16px; /* Smaller icon size */
	}

	.form-card-body {
		padding: 10px;
		background-color: var(--secondary-color);
		flex: 1;
		text-align: center;
	}

	.form-card-actions {
		background-color: var(--secondary-color);
		padding: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px; /* Adds space between buttons */
	}

	/* Status Icon in Card Header */
	.status-icon {
		width: 32px; /* Smaller icon */
		height: 32px; /* Smaller icon */
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		color: white !important;
		padding: 5px; /* Adjust padding for smaller icon */
	}

	.status-icon-completed {
		background-color: green !important; /* Green circle */
	}

	.status-icon-incomplete {
		background-color: red !important; /* Red circle */
	}

	.status-icon-unfilled {
		background-color: #696969 !important; /* Grey circle */
	}

	/* Progress Banner */
	.progress-banner {
		padding: 0 !important;
		margin: -65px 0 0 0 !important;
		background-color: #1976d2; /* Primary color */
		color: white;
		border-radius: 8px;
		display: flex;
		align-items: center;
		width: fit-content;
	}

	.progress-text {
		font-size: 16px;
		font-weight: bold;
		margin-right: 12px;
	}

	/* Search Input Styles */
	.person-search-input input,
	.forms-search-input input {
		padding: 8px !important;
		border: 1px solid black !important;
		border-radius: 4px !important;
	}

	/* Clear Icon Color */
	.v-input__icon--clear button,
	.v-input__icon--append i {
		color: black !important; /* Inactive color */
	}

	.v-input__icon--clear button.active,
	.v-input__icon--append i.active {
		color: grey !important; /* Active color */
	}

	/* Divider */
	.v-divider {
		margin: 0px 10px !important;
	}
</style>
