<template>
	<div>
		<h1>Recent Moveouts/ Discharged</h1>
		<v-container
			class="main-section-residents-recent-moveouts ma-1 pa-1"
			fluid>
			<ResidentsRecentMoveouts />
		</v-container>
	</div>
</template>

<script>
	import ResidentsRecentMoveouts from "./residents-recent-moveouts.vue";

	export default {
		name: "MainSectionResidentsRecentMoveouts",
		components: { ResidentsRecentMoveouts },
	};
</script>

<style scoped></style>
