<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<div v-for="(field, idx) in fields" :key="idx">
					<v-text-field
						v-if="field.type === 'text' || field.type === 'date'"
						:label="field.label"
						:type="field.type"
						:value="admitForm[field.model]"
						@input="
							$emit('update-admit-form', {
								key: field.model,
								value: $event,
							})
						"
            :disabled="!canEditPersonInfo"
						:required="field.required"></v-text-field>

					<v-select
						v-else-if="
							field.type === 'select' &&
							field.model === 'province'
						"
						:items="provinces"
						item-text="text"
						item-value="value"
						label="Province"
						:value="admitForm.province"
						@input="
							$emit('update-admit-form', {
								key: 'province',
								value: $event,
							})
						"
            :disabled="!canEditPersonInfo"
						:required="field.required"></v-select>

					<!-- <v-select
						v-else-if="
							field.type === 'select' &&
							field.model === 'ethnicity'
						"
						:items="ethnicities"
						item-text="text"
						item-value="value"
						label="Ethnicity"
						:value="admitForm.ethnicity"
						@input="
							$emit('update-admit-form', {
								key: 'ethnicity',
								value: $event,
							})
						"
						:required="field.required"></v-select> -->
          
            <v-autocomplete
              v-else-if="
                field.type === 'autocomplete' &&
                field.model === 'ethnicity'
              "
              :value="admitForm.ethnicity"
              :items="ethnicities"
              :search-input.sync="localSearchEthnicity"
              @input="
                $emit('update-admit-form', {
                  key: 'ethnicity',
                  value: $event,
                })
              "
              
              :label="`Ethnicity`"
              item-text="ethnicity_name"
              item-value="id"
              hide-no-data
              
              underlined
              background-color="white"
              style="border-radius: 4px"
              :disabled="!canEditPersonInfo"
              :required="field.required"
            ></v-autocomplete>

            <v-autocomplete
              v-else-if="
                field.type === 'autocomplete' &&
                field.model === 'citizenship'
              "
              :value="admitForm.citizenship"
              :items="citizenships"
              :search-input.sync="localSearchCitizenship"
              @input="
                $emit('update-admit-form', {
                  key: 'citizenship',
                  value: $event,
                })
              "
              label="Citizenship"
              item-text="country_name"
              item-value="id"
              hide-no-data
              
              underlined
              background-color="white"
              style="border-radius: 4px"
              :disabled="!canEditPersonInfo"
              :required="field.required"
            ></v-autocomplete>

					<v-select
						v-else-if="
							field.type === 'select' &&
							field.model === 'citizenship'
						"
						:items="citizenships"
						item-text="text"
						item-value="value"
						label="Citizenship"
						:value="admitForm.citizenship"
						@input="
							$emit('update-admit-form', {
								key: 'citizenship',
								value: $event,
							})
						"
            :disabled="!canEditPersonInfo"
						:required="field.required"></v-select>

					<v-select
						v-else-if="
							field.type === 'select' && field.model === 'sex'
						"
						:items="sexOptions"
						item-text="text"
						item-value="value"
						label="Sex"
						:value="admitForm.sex"
						@input="
							$emit('update-admit-form', {
								key: 'sex',
								value: $event,
							})
						"
            :disabled="!canEditPersonInfo"
						:required="field.required"></v-select>
				</div>
        <!-- <v-checkbox
            label="Is a Refufee"
            :v-model="admitForm.isRefugee"
            @change="
              $emit('update-admit-form', {
                key: 'isRefugee',
                value: $event,
              })
            "></v-checkbox> -->
        <v-checkbox
        label="Is a Refugee?"
        :input-value="admitForm.isRefugee"
        @change="handleIsRefugee"
        :disabled="!canEditPersonInfo"
        ></v-checkbox>
				<v-checkbox
					label="Is an Immigrant?"
					:input-value="admitForm.isImmigrant"
					@change="
						$emit('update-admit-form', {
							key: 'isImmigrant',
							value: $event,
						})
					"
          :disabled="!canEditPersonInfo"
          ></v-checkbox>
				<v-checkbox
					label="Is a Veteran?"
					:input-value="admitForm.isVeteran"
					@change="
						$emit('update-admit-form', {
							key: 'isVeteran',
							value: $event,
						})
					"
          :disabled="!canEditPersonInfo"
          ></v-checkbox>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	export default {
		props: {
      canEditPersonInfo: Boolean,
			admitForm: Object,
			fields: Array,
			provinces: Array,
			ethnicities: Array,
			citizenships: Array,
			sexOptions: Array,
		},
    data(){
      return {
        localSearchEthnicity: this.searchEthnicity,
        localSearchCitizenship: this.searchCitizenship,
      }
    },
    watch: {
      localSearchEthnicity(newVal) {
        this.$emit('update:searchEthnicity', newVal)
      },
      localSearchCitizenship(newVal) {
        this.$emit('update:searchCitizenship', newVal)
      },
    },
    methods: {
      handleIsRefugee(value) {
        this.$emit('update-admit-form', {
          key: 'isRefugee',
          value: value,
        });

      } 
    }
	};
</script>

<style scoped>
	/* Add any relevant styles here */
</style>
