<template>
	<v-card class="user-list-card">
		<v-card-title>
			<v-row style="width: 100%">
				<v-col cols="12" md="6">
					List of Users
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-icon
								v-bind="attrs"
								v-on="on"
								small
								class="ml-2 info-icon">
								mdi-information-outline
							</v-icon>
						</template>
						<span class="tooltip-content">
							This table shows the list of users along with their
							details.
						</span>
					</v-tooltip>
				</v-col>
				<v-col cols="12" md="6">
					<v-text-field
						v-model="search"
						placeholder="Search User:"
						hide-details
						dense
						solo
						background-color="white"
						style="border-radius: 4px"
						append-icon="mdi-account">
					</v-text-field>
				</v-col>
			</v-row>
		</v-card-title>
		<v-card-text>
			<div class="table-container">
				<v-progress-linear
					v-if="loading"
					indeterminate
					color="red"></v-progress-linear>
				<v-data-table
					:headers="headers"
					:items="paginatedUsers"
					:search="search"
					:items-per-page="5"
					:footer-props="{
						'items-per-page-options': [5, 10, 50, 100, 500],
						'items-per-page-text': 'Items per page',
					}">
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									small
									class="mr-2"
									@click="viewUser(item)">
									mdi-eye
								</v-icon>
							</template>
							<span class="tooltip-content">View User</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									small
									class="mr-2"
									@click="editUser(item)">
									mdi-pencil
								</v-icon>
							</template>
							<span class="tooltip-content">Edit User</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									small
									@click="confirmArchiveUser(item)">
									mdi-archive
								</v-icon>
							</template>
							<span class="tooltip-content">Archive User</span>
						</v-tooltip>
					</template>
				</v-data-table>
				<v-pagination
					v-model="page"
					:length="pageCount"
					:total-visible="7"
					color="red"></v-pagination>
			</div>
		</v-card-text>

		<!-- Archive User Dialog -->
		<v-dialog v-model="archiveDialog" max-width="600px">
			<v-card>
				<v-toolbar
					flat
					dense
					class="form-stepper-admit-toolbar sticky-toolbar">
					<v-toolbar-title class="dialog-title"
						>Archive User</v-toolbar-title
					>
					<v-spacer></v-spacer>
					<v-btn
						class="small-close-btn"
						icon
						@click="archiveDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text v-if="selectedUser">
					<p>Are you sure you want to archive this user?</p>
					<p>
						<strong>{{ selectedUser.username }}</strong>
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn class="theme-btn" @click="archiveSelectedUser"
						>Archive</v-btn
					>
					<v-btn class="theme-btn" @click="archiveDialog = false"
						>Cancel</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
	import { fetchUserList } from "@/api"; // Ensure the correct path is provided

	export default {
		data() {
			return {
				users: [],
				search: "",
				page: 1,
				itemsPerPage: 5,
				headers: [
					{ text: "ID", value: "id" },
					{ text: "Username", value: "username" },
					{ text: "Email", value: "email" },
					{ text: "Status", value: "status" },
					{ text: "Role", value: "role.name" },
					{ text: "Actions", value: "actions", sortable: false },
				],
				loading: false,
				archiveDialog: false,
				selectedUser: {},
			};
		},
		computed: {
			filteredUsers() {
				return this.users.filter(
					(user) =>
						user.username
							.toLowerCase()
							.includes(this.search.toLowerCase()) ||
						user.email
							.toLowerCase()
							.includes(this.search.toLowerCase()) ||
						(user.role &&
							user.role.name
								.toLowerCase()
								.includes(this.search.toLowerCase()))
				);
			},
			paginatedUsers() {
				const start = (this.page - 1) * this.itemsPerPage;
				const end = start + this.itemsPerPage;
				return this.filteredUsers.slice(start, end);
			},
			pageCount() {
				return Math.ceil(this.filteredUsers.length / this.itemsPerPage);
			},
		},
		created() {
			this.fetchUsers();
		},
		mounted() {
			this.startAutoUpdate();
		},
		beforeDestroy() {
			this.stopAutoUpdate();
		},
		methods: {
			async fetchUsers() {
				this.loading = true;
				try {
					const response = await fetchUserList();
					this.users = response.data.items.map((user) => ({
						...user,
						status: user.status === 10 ? "Active" : "Inactive",
					}));
				} catch (error) {
					console.error("Error fetching user list:", error);
				} finally {
					this.loading = false;
				}
			},
			startAutoUpdate() {
				this.updateInterval = setInterval(this.fetchUsers, 30000); // Update every 30 seconds
			},
			stopAutoUpdate() {
				clearInterval(this.updateInterval);
			},
			viewUser(user) {
				// View user logic here
				console.log("View:", user);
			},
			editUser(user) {
				// Edit user logic here
				console.log("Edit:", user);
			},
			confirmArchiveUser(user) {
				this.selectedUser = user;
				this.archiveDialog = true;
			},
			archiveSelectedUser() {
				// Archive user logic here
				console.log("Archive:", this.selectedUser);
				this.archiveDialog = false;
				this.selectedUser = {}; // Reset selectedUser after archiving
			},
		},
	};
</script>

<style scoped>
	.user-search-input {
		width: 100%;
	}
	.user-list-card {
		margin-top: 20px;
	}
	.table-container {
		position: relative;
	}
	.theme-btn {
		background-color: #f44336;
		color: white;
	}
	.sticky-toolbar {
		position: sticky;
		top: 0;
		z-index: 10;
	}
	.dialog-title {
		font-weight: bold;
	}
	.small-close-btn {
		color: #757575;
	}
</style>
