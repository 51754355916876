<template>
	<v-card class="report-detail-card">
		<v-card-title>
			<div>{{ report.title }}</div>
			<v-spacer></v-spacer>
			<v-btn class="theme-btn" @click="downloadReport">
				<v-icon left>mdi-download</v-icon>
				Download PDF
			</v-btn>
			<v-btn class="theme-btn" @click="printReport">
				<v-icon left>mdi-printer</v-icon>
				Print
			</v-btn>
		</v-card-title>
		<v-card-text>
			<div ref="reportContent" class="report-content">
				<h2>{{ report.title }}</h2>
				<p>{{ report.description }}</p>
				<div v-html="report.content"></div>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
	import jsPDF from "jspdf";
	import html2canvas from "html2canvas";

	export default {
		props: {
			report: {
				type: Object,
				required: true,
			},
		},
		methods: {
			downloadReport() {
				const content = this.$refs.reportContent;
				html2canvas(content, { scrollY: -window.scrollY }).then(
					(canvas) => {
						const imgData = canvas.toDataURL("image/png");
						const pdf = new jsPDF("p", "mm", "a4");
						const imgWidth = 210;
						const imgHeight =
							(canvas.height * imgWidth) / canvas.width;
						pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
						pdf.save(`${this.report.title}.pdf`);
					}
				);
			},
			printReport() {
				const content = this.$refs.reportContent.innerHTML;
				const printWindow = window.open("", "", "width=800,height=600");
				printWindow.document.write(
					"<html><head><title>Print Report</title></head><body>"
				);
				printWindow.document.write(content);
				printWindow.document.write("</body></html>");
				printWindow.document.close();
				printWindow.print();
			},
		},
	};
</script>

<style scoped>
	.report-detail-card {
		border: 1px solid #ccc;
		border-radius: 8px;
		padding: 4px;
		background-color: #fff;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
		min-width: fit-content;
		width: 100%;
	}

	.report-content {
		padding: 16px;
	}
</style>
