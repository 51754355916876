<template>
	<v-form>
		<v-text-field label="First Name" :value="person.firstName" readonly />
		<v-text-field label="Middle Name" :value="person.middleName" readonly />
		<v-text-field label="Last Name" :value="person.lastName" readonly />
		<v-text-field label="Date of Birth" :value="person.dob" readonly />
		<v-select label="Sex" :items="sexes" :value="person.sex" readonly />
		<v-text-field label="Province" :value="person.province" readonly />
		<v-text-field label="Ethnicity" :value="person.ethnicity" readonly />
		<v-text-field
			label="Citizenship"
			:value="person.citizenship"
			readonly />
		<v-checkbox label="Is Refugee" :value="person.isRefugee" readonly />
		<v-checkbox label="Is Immigrant" :value="person.isImmigrant" readonly />
		<v-checkbox label="Is Veteran" :value="person.isVeteran" readonly />
	</v-form>
</template>

<script>
	export default {
		name: "PersonProfile",
		props: {
			person: {
				type: Object,
				required: true,
			},
		},
		data() {
			return {
				sexes: ["Male", "Female", "Other"],
			};
		},
	};
</script>
