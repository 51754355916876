import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "@/views/LoginView.vue";
import MainLayout from "@/layouts/MainLayout.vue";

import DashboardView from "@/views/DashboardView.vue";
import FormsView from "@/views/FormsView.vue";
import ShelterAreasAll from "@/views/ShelterAreasAll";
import ShelterAreasArchived from "@/views/ShelterAreasArchived";
import ReportsView from "@/views/ReportsView.vue";
import ReportsDetailView from "@/views/ReportsDetailView.vue";

import ResidentsList from "@/components/residents-list.vue";
// import MainSectionPersonViewEditDialog from "@/components/main-section-person-view-edit-dialog.vue";

import ResidentsListActive from "@/components/main-section-residents-current.vue";
import ResidentsRecentMoveouts from "@/components/main-section-residents-recent-moveouts.vue";

import ItemsBelongingsList from "@/components/main-section-itemsbelongings-list.vue";
import ItemsBelongingsCCan from "@/components/main-section-itemsbelongings-ccan.vue";
import UserManagement from "@/components/main-section-user-management-list";

Vue.use(VueRouter);

const routes = [
	{ path: "/", redirect: "/login" },
	{ path: "/login", component: LoginView },
	{
		path: "/dashboard",
		component: MainLayout,
		children: [{ path: "", component: DashboardView }],
	},
	{
		path: "/forms",
		component: MainLayout,
		children: [{ path: "", component: FormsView }],
	},
	{
		path: "/shelter-areas",
		component: MainLayout,
		children: [
			{ path: "all", component: ShelterAreasAll },
			{ path: "archived", component: ShelterAreasArchived },
		],
	},
	{
		path: "/reports",
		component: MainLayout,
		children: [
			{ path: "", component: ReportsView },
			{ path: ":title", component: ReportsDetailView, props: true },
		],
	},
	{
		path: "/residents",
		component: MainLayout,
		children: [
			{ path: "", component: ResidentsList }, // Updated to use the base path
			{ path: "active", component: ResidentsListActive },
			{ path: "recent-discharged", component: ResidentsRecentMoveouts },
			// {
			// 	path: ":id/:status",
			// 	component: MainSectionPersonViewEditDialog,
			// 	props: true,
			// 	name: "person-detail", // Updated route name for better clarity
			// },
		],
	},
	{
		path: "/itemsbelongings",
		component: MainLayout,
		children: [
			{ path: "all", component: ItemsBelongingsList },
			{ path: "c-can", component: ItemsBelongingsCCan },
		],
	},
	{
		path: "/settings",
		component: MainLayout,
		children: [{ path: "user-management-list", component: UserManagement }],
	},
];

const router = new VueRouter({
	mode: "history",
	routes,
});

export default router;
