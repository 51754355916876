import { render, staticRenderFns } from "./main-section-shelter-areas-archived.vue?vue&type=template&id=b3aeb340&scoped=true"
import script from "./main-section-shelter-areas-archived.vue?vue&type=script&lang=js"
export * from "./main-section-shelter-areas-archived.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3aeb340",
  null
  
)

export default component.exports