var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-list',{staticClass:"pa-1"},[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":require("@/assets/avatar-default.svg")}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Sean P.")])],1)],1)],1),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.regularMenuItems),function(item,index){return _c('v-list-item',{key:index,class:{ 'active-menu': _vm.isActive(item) },on:{"click":function($event){return _vm.selectMenu(item)}}},[(_vm.showIcons)?_c('v-list-item-icon',[_c('v-icon',{class:{ 'white--text': _vm.isActive(item) }},[_vm._v(_vm._s("mdi-" + item.icon))])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{class:{ 'white--text': _vm.isActive(item) }},[_vm._v(_vm._s(item.title))])],1)],1)}),[_c('v-list',{attrs:{"dense":""}},[_c('v-list-group',{class:{ 'text-color-red': _vm.shelterAreasOpen },attrs:{"append-icon":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"no-padding"},[_c('v-list-item-icon',[_c('v-icon',{class:{
										'text-color-red': _vm.shelterAreasOpen,
									}},[_vm._v(" mdi-domain ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:{
										'text-color-red': _vm.shelterAreasOpen,
									}},[_vm._v(" Shelter Areas ")])],1),_c('v-icon',{class:{
									'text-color-red': _vm.shelterAreasOpen,
								}},[_vm._v(" "+_vm._s(_vm.shelterAreasOpen ? "mdi-chevron-up" : "mdi-chevron-down")+" ")])],1)]},proxy:true}]),model:{value:(_vm.shelterAreasOpen),callback:function ($$v) {_vm.shelterAreasOpen=$$v},expression:"shelterAreasOpen"}},_vm._l((_vm.shelterAreasItems),function(item,index){return _c('v-list-item',{key:'shelter-areas-' + index,staticClass:"ml-3",class:{ 'active-menu': _vm.isActive(item) },on:{"click":function($event){return _vm.selectMenu(item)}}},[_c('v-list-item-icon',[_c('v-icon',{class:{ 'white--text': _vm.isActive(item) }},[_vm._v(_vm._s("mdi-" + item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:{ 'white--text': _vm.isActive(item) }},[_vm._v(_vm._s(item.title))])],1)],1)}),1),_c('v-list-group',{class:{ 'text-color-red': _vm.residentsOpen },attrs:{"append-icon":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"no-padding"},[_c('v-list-item-icon',[_c('v-icon',{class:{
										'text-color-red': _vm.residentsOpen,
									}},[_vm._v(" mdi-home-group ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:{
										'text-color-red': _vm.residentsOpen,
									}},[_vm._v(" Residents ")])],1),_c('v-icon',{class:{
									'text-color-red': _vm.residentsOpen,
								}},[_vm._v(" "+_vm._s(_vm.residentsOpen ? "mdi-chevron-up" : "mdi-chevron-down")+" ")])],1)]},proxy:true}]),model:{value:(_vm.residentsOpen),callback:function ($$v) {_vm.residentsOpen=$$v},expression:"residentsOpen"}},_vm._l((_vm.residentsItems),function(item,index){return _c('v-list-item',{key:'residents-' + index,staticClass:"ml-3",class:{ 'active-menu': _vm.isActive(item) },on:{"click":function($event){return _vm.selectMenu(item)}}},[_c('v-list-item-icon',[_c('v-icon',{class:{ 'white--text': _vm.isActive(item) }},[_vm._v(_vm._s("mdi-" + item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:{ 'white--text': _vm.isActive(item) }},[_vm._v(_vm._s(item.title))])],1)],1)}),1),_c('v-list-group',{class:{ 'text-color-red': _vm.itemsOpen },attrs:{"append-icon":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"no-padding"},[_c('v-list-item-icon',[_c('v-icon',{class:{
										'text-color-red': _vm.itemsOpen,
									}},[_vm._v(" mdi-briefcase ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:{
										'text-color-red': _vm.itemsOpen,
									}},[_vm._v(" Items/Belongings ")])],1),_c('v-icon',{class:{ 'text-color-red': _vm.itemsOpen }},[_vm._v(" "+_vm._s(_vm.itemsOpen ? "mdi-chevron-up" : "mdi-chevron-down")+" ")])],1)]},proxy:true}]),model:{value:(_vm.itemsOpen),callback:function ($$v) {_vm.itemsOpen=$$v},expression:"itemsOpen"}},_vm._l((_vm.itemsBelongingsItems),function(item,index){return _c('v-list-item',{key:'items-' + index,staticClass:"ml-3",class:{ 'active-menu': _vm.isActive(item) },on:{"click":function($event){return _vm.selectMenu(item)}}},[_c('v-list-item-icon',[_c('v-icon',{class:{ 'white--text': _vm.isActive(item) }},[_vm._v(_vm._s("mdi-" + item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:{ 'white--text': _vm.isActive(item) }},[_vm._v(_vm._s(item.title))])],1)],1)}),1),_c('v-list-group',{class:{ 'text-color-red': _vm.settingsOpen },attrs:{"append-icon":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"no-padding"},[_c('v-list-item-icon',[_c('v-icon',{class:{
										'text-color-red': _vm.settingsOpen,
									}},[_vm._v(" mdi-cog ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:{
										'text-color-red': _vm.settingsOpen,
									}},[_vm._v(" Settings ")])],1),_c('v-icon',{class:{ 'text-color-red': _vm.settingsOpen }},[_vm._v(" "+_vm._s(_vm.settingsOpen ? "mdi-chevron-up" : "mdi-chevron-down")+" ")])],1)]},proxy:true}]),model:{value:(_vm.settingsOpen),callback:function ($$v) {_vm.settingsOpen=$$v},expression:"settingsOpen"}},_vm._l((_vm.settingsItems),function(item,index){return _c('v-list-item',{key:'settings-' + index,staticClass:"ml-3",class:{ 'active-menu': _vm.isActive(item) },on:{"click":function($event){return _vm.selectMenu(item)}}},[_c('v-list-item-icon',[_c('v-icon',{class:{ 'white--text': _vm.isActive(item) }},[_vm._v(_vm._s("mdi-" + item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:{ 'white--text': _vm.isActive(item) }},[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }