import axios from "axios";

axios.defaults.headers.common["Authorization"] =
	"Bearer K93FbEHg3ncpM-vfEN05Tv3VJANNbbM_";

const apiRequest = (method, url, data = null, params = null) => {
	return axios({
		method,
		url: `${process.env.VUE_APP_API_HOST}${url}`,
		data,
		params,
	});
};

// Specific API Call Functions
export const loginUser = (data) =>
	apiRequest("post", "/login", data).then((response) => response.data);

export const registerUser = () => apiRequest("post", "/user/register");
export const fetchUserList = () => apiRequest("get", "/users?expand=role");
export const fetchRoles = () => apiRequest("get", "/roles");
export const fetchProvinces = () => apiRequest("get", "/provinces");
export const fetchEthnicity = ({name='', id=''}) => apiRequest("get", 
  `/ethnicity`+ (name ? `?ethnicity_name=${name}` : '') + ((id) ? `?id=${id}` : ''));

export const fetchShelterAreas = () => apiRequest("get", "/building");

export const fetchCitizenship = ({name='', id=''}) => apiRequest("get", 
  "/countries" + (name ? `?country_name=${name}` : '') + ((id) ? `?id=${id}` : ''));
export const fetchCardTypes = () => apiRequest("get", "/card-types");
export const fetchHealthIssues = () => apiRequest("get", "/health-issues");
export const fetchAddictions = () => apiRequest("get", "/addictions");
export const fetchMentalHealthIssues = () => apiRequest("get", "/mental-health-issues");
export const fetchAllergies = () => apiRequest("get", "/allergies");
export const fetchDietaryReqs = () => apiRequest("get", "/dietary-requirements");
export const fetchMedications = () => apiRequest("get", "/medications");
export const fetchPharmacies = () => apiRequest("get", "/pharmacies");

export const fetchPersonInfo = ({last_name='', person_id=''} = {}) => 
  apiRequest(
    "get", 
    `/people?expand=ethnicity,province,citizenships` 
    + ((last_name) ? `&last_name=${last_name}` : '') + ((person_id) ? `&id=${person_id}` : '')
    // `people?expand=ethnicity,province,citizenships&last_name=${person_name.last_name}&first_name=${person_name.first_name}&middle_name=${person_name.middle_name}`
  );

export const fetchActiveIntakes = ({last_name = '', person_id=''} = {}) =>
  apiRequest(
    "get",
    `/people?expand=ethnicity,province,citizenships&has_active_intakes=1`
    + ((last_name) ? `&last_name=${last_name}` : '') + ((person_id) ? `&id=${person_id}` : '')
  ); 
  
export const fetchPersonIDCards = (person_id) => apiRequest("get", `/id-cards?expand=cardType,province&person_id=${person_id}`);
export const fetchPersonEmergencyContacts = (person_id) => 
  apiRequest(
    "get", 
    `/emergency-contacts?expand=person,createdBy,updatedBy,createdBy.role,updatedBy.role&person_id=${person_id}`
  );
export const fetchPersonBikes = (person_id) => 
  apiRequest(
    "get",
    `/bike-registration?expand=person,registeredBy&archived_at_null=1&person_id=${person_id}`
  );
export const fetchPersonPets = (person_id) =>
  apiRequest(
    "get",
    `/pet-info?expand=owner,addedBy,updatedBy,archivedBy&archived_at_null=1&person_id=${person_id}`
  );

export const fetchPersonMostRecentShelterIntake = (person_id) =>
  apiRequest(
    "get",
    `/shelter-intake?expand=person,building,checkedInBy,checkedInBy.role,updatedBy,updatedBy.role,updatedBy.userProfile,bed,bed.building,intakeIdCards,intakeIdCards.idCard,intakeIdCards.idCard.cardType,intakeIdCards.idCard.province,intakeEmergencyContacts,intakeEmergencyContacts.emergencyContact,intakeHealthProfiles,intakeHealthProfiles.healthIssues,intakeAllergies,intakeAllergies.allergy,intakeDietaryReqs,intakeDietaryReqs.dietaryReq,intakeAddictionProfiles,intakeAddictionProfiles.addictions,intakeMentalHealthProfiles,intakeMentalHealthProfiles.mentalHealthIssues,intakeBikeRegs,intakeBikeRegs.bikeReg,intakeCcanStorages,intakeMedications,intakeMedications.medication,intakeMedications.pharmacy,intakePets,intakePets.petInfo,dischargeDetail&person_id=${person_id}`
  );

export const storePersonShelterIntake = (data) =>
  apiRequest("post", "/shelter-intake/create-intake", data);

export const storeIntakeDischarge = (data) =>
  apiRequest("post", "/discharge-detail/create-discharge", data);

export const storeDischargeBanStatus = (data) =>
  apiRequest("post", "/discharge-ban-update-statuses", data);
// export const fetchPerson = (search_val) =>
// 	apiRequest(
// 		"get",
// 		`/people?expand=ethnicity,province,citizenships&last_name=${search_val}`
// 	);

export const dischargePerson = () => apiRequest("post", "/discharge-details");

export const fetchDischargedPersons = () =>
	apiRequest(
		"get",
		"/discharge-details?expand=intake,intake.person,terminatedBy"
	);

export const fetchPerson = () =>
	apiRequest("get", "/people?expand=ethnicity,province,citizenships");
export const storePerson = (data) => apiRequest("post", "/people", data);
export const updatePerson = (person_id, data) => apiRequest("put", `/people/${person_id}`, data);
export const storeProfilePhoto = (profile_photo) =>
	apiRequest("get", `/profile_photo=${profile_photo}`); 

export const storePersonProfilePhoto = (data) => 
  apiRequest("post", `/person/add-profile-pic`, data);

export const searchPersonIntake = () =>
	apiRequest(
		"get",
		"/shelter-intake?expand=person,building,checkedInBy,checkedInBy.role,updatedBy,updatedBy.role,updatedBy.userProfile,bed,bed.building"
	);



export const fetchBedList = () =>
	apiRequest("get", "/bed-list?expand=building");

export const fetchAvailableBeds = () =>
	apiRequest("get", "/bed-list?availability_status=Available");

export const fetchBuildings = () => 
  apiRequest("get", "/buildings");

export const fetchBedListPerBuilding = ({get_available_beds, building_id}) =>
  apiRequest("get", "/buildings?expand=bedLists" + (get_available_beds ? `&getAvailableBeds=${get_available_beds}` : '') + (building_id ? `&id=${building_id}` : ''));
