<template>
	<v-card class="residents-list-card">
		<v-card-title>
			<v-row style="width: 100%">
				<v-col cols="12" md="6">
					List of All Residents
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-icon
								v-bind="attrs"
								v-on="on"
								small
								class="ml-2 info-icon">
								mdi-information-outline
							</v-icon>
						</template>
						<span class="tooltip-content">
							This table shows a list of current residents along
							with their details.
						</span>
					</v-tooltip>
				</v-col>
				<v-col cols="12" md="6">
					<v-text-field
						v-model="search"
						placeholder="Search Resident:"
						hide-details
						dense
						solo
						background-color="white"
						style="border-radius: 4px"
						append-icon="mdi-account">
					</v-text-field>
				</v-col>
			</v-row>
		</v-card-title>
		<v-card-text>
			<div class="table-container">
				<v-progress-linear
					v-if="loading"
					indeterminate
					color="red"></v-progress-linear>
				<v-data-table
					:headers="headers"
					:items="paginatedResidents"
					:search="search"
					:items-per-page="5"
					:footer-props="{
						'items-per-page-options': [5, 10, 50, 100, 500],
						'items-per-page-text': 'Residents per page',
					}">
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									small
									class="mr-2"
									@click="viewResident(item)">
									mdi-eye
								</v-icon>
							</template>
							<span class="tooltip-content">View Resident</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									small
									class="mr-2"
									@click="editResident(item)">
									mdi-pencil
								</v-icon>
							</template>
							<span class="tooltip-content">Edit Resident</span>
						</v-tooltip>
					</template>
				</v-data-table>
				<v-pagination
					v-model="page"
					:length="pageCount"
					:total-visible="7"
					color="red">
				</v-pagination>
			</div>
		</v-card-text>

		<main-section-view-person
			v-if="showViewDialog"
			:sample-person="samplePerson"
			@close="closeDialog" />
		<main-section-edit-person
			v-if="showEditDialog"
			:sample-person="samplePerson"
			@close="closeDialog" />
	</v-card>
</template>

<script>
	import MainSectionViewPerson from "./main-section-view-person.vue";
	import MainSectionEditPerson from "./main-section-edit-person.vue";

	export default {
		data() {
			return {
				headers: [
					{ text: "Name", value: "name" },
					{ text: "Date of Birth", value: "date_of_birth" },
					{ text: "Sex", value: "sex" },
					{ text: "Refugee", value: "is_refugee" },
					{ text: "Immigrant", value: "is_immigrant" },
					{ text: "Veteran", value: "is_veteran" },
					{ text: "Actions", value: "actions", sortable: false },
				],
				residents: [],
				search: "",
				page: 1,
				itemsPerPage: 5,
				loading: false,
				showViewDialog: false,
				showEditDialog: false,
				samplePerson: {
					firstName: "John",
					middleName: "D.",
					lastName: "Doe",
					dob: "1990-01-01",
					sex: "Male",
					province: "BC",
					ethnicity: "Caucasian",
					citizenship: "Canadian",
					isRefugee: false,
					isImmigrant: true,
					isVeteran: false,
					notes: [
						{
							id: 1,
							date: "2023-01-01",
							time: "10:00",
							comment: "First note",
						},
						{
							id: 2,
							date: "2023-01-02",
							time: "12:00",
							comment: "Second note",
						},
					],
					identificationCards: [
						{
							type: "Driver's License",
							image: "https://via.placeholder.com/300x200",
						},
						{
							type: "BCID",
							image: "https://via.placeholder.com/300x200",
						},
						{
							type: "Care Card",
							image: "https://via.placeholder.com/300x200",
						},
					],
					stays: [
						{ checkIn: "2023-01-01", checkOut: "2023-01-10" },
						{ checkIn: "2023-02-15", checkOut: "2023-02-20" },
					],
					demographicsProfile: {
						homelessnessStatus: "Yes",
						homelessnessLength: "2 years",
						specialNote: "Frequent visitor at the shelter.",
					},
					emergencyContact: {
						firstName: "Jane",
						middleName: "A.",
						lastName: "Doe",
						relationship: "Sister",
						phone: "123-456-7890",
						email: "jane.doe@example.com",
						note: "Emergency contact for medical issues.",
					},
					healthProfile: {
						recordDate: "2023-01-01",
						issues: [
							{
								name: "Health Issue 1",
								status: "Yes",
								source: "Stated",
							},
							{
								name: "Health Issue 2",
								status: "No",
								source: "Observed",
							},
							{
								name: "Health Issue 3",
								status: "Suspected",
								source: "Observed",
							},
						],
						notes: "No major health concerns.",
					},
					addictions: {
						recordDate: "2023-01-01",
						issues: [
							{
								name: "Addiction 1",
								status: "Yes",
								source: "Stated",
							},
							{
								name: "Addiction 2",
								status: "No",
								source: "Observed",
							},
							{
								name: "Addiction 3",
								status: "Suspected",
								source: "Observed",
							},
						],
						notes: "Currently attending rehabilitation.",
					},
					mentalHealth: {
						recordDate: "2023-01-01",
						issues: [
							{
								name: "Mental Issue 1",
								status: "Yes",
								source: "Stated",
							},
							{
								name: "Mental Issue 2",
								status: "No",
								source: "Observed",
							},
							{
								name: "Mental Issue 3",
								status: "Suspected",
								source: "Observed",
							},
						],
						notes: "Undergoing therapy.",
					},
				},
			};
		},
		components: {
			MainSectionViewPerson,
			MainSectionEditPerson,
		},
		computed: {
			filteredResidents() {
				return this.residents.filter(
					(resident) =>
						resident.name
							.toLowerCase()
							.includes(this.search.toLowerCase()) ||
						resident.date_of_birth
							.toLowerCase()
							.includes(this.search.toLowerCase()) ||
						resident.sex
							.toLowerCase()
							.includes(this.search.toLowerCase()) ||
						(resident.is_refugee &&
							resident.is_refugee
								.toLowerCase()
								.includes(this.search.toLowerCase())) ||
						(resident.is_immigrant &&
							resident.is_immigrant
								.toLowerCase()
								.includes(this.search.toLowerCase())) ||
						(resident.is_veteran &&
							resident.is_veteran
								.toLowerCase()
								.includes(this.search.toLowerCase()))
				);
			},
			paginatedResidents() {
				const start = (this.page - 1) * this.itemsPerPage;
				const end = start + this.itemsPerPage;
				return this.filteredResidents.slice(start, end);
			},
			pageCount() {
				return Math.ceil(
					this.filteredResidents.length / this.itemsPerPage
				);
			},
		},
		created() {
			this.fetchResidents();
		},
		methods: {
			async fetchResidents() {
				this.loading = true;
				try {
					const response = await this.$axios.get("/api/residents"); // Adjust the API endpoint accordingly
					this.residents = response.data.items.map((person) => ({
						id: person.id,
						name: `${person.last_name}, ${person.first_name} ${
							person.middle_name
								? person.middle_name.charAt(0) + "."
								: ""
						}`.trim(),
						date_of_birth: person.date_of_birth,
						sex: person.sex,
						is_refugee: person.is_refugee ? "✔" : "✘",
						is_immigrant: person.is_immigrant ? "✔" : "✘",
						is_veteran: person.is_veteran ? "✔" : "✘",
					}));
				} catch (error) {
					console.error("Error fetching residents:", error);
				} finally {
					this.loading = false;
				}
			},
			viewResident(item) {
				this.samplePerson = {
					...this.samplePerson,
					firstName: item.name.split(", ")[1].split(" ")[0],
					lastName: item.name.split(", ")[0],
				};
				this.showViewDialog = true;
			},
			editResident(item) {
				this.samplePerson = {
					...this.samplePerson,
					firstName: item.name.split(", ")[1].split(" ")[0],
					lastName: item.name.split(", ")[0],
				};
				this.showEditDialog = true;
			},
			closeDialog() {
				this.showViewDialog = false;
				this.showEditDialog = false;
			},
		},
	};
</script>

<style scoped>
	.residents-list-card {
		border: 1px solid #ccc;
		border-radius: 8px;
		padding: 4px;
		background-color: #fff;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
		min-width: fit-content;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	.table-container {
		position: relative;
	}
</style>
