<template>
	<div>
		<h1>Search C-Can</h1>
		<v-container class="main-section-itemsbelongings-ccan ma-1 pa-1" fluid>
			<ItemsBelongingstCCan />
		</v-container>
	</div>
</template>

<script>
	import ItemsBelongingstCCan from "./itemsbelongings-ccan.vue";

	export default {
		name: "MainSectionItemsBelongingstCCan",
		components: { ItemsBelongingstCCan },
	};
</script>

<style scoped></style>
