<template>
	<v-file-input
		:label="label"
		:required="required"
		:accept="acceptedFormats"
		@change="handleFileChange"
		prepend-icon="mdi-upload"
		outlined
    clearable>
		<template v-slot:message v-if="error">{{ error }}</template>
	</v-file-input>
</template>

<script>
	export default {
		props: {
			label: {
				type: String,
				required: true,
			},
			required: {
				type: Boolean,
				default: false,
			},
			// value: {
			// 	type: [File, Object], // Accept both File and Object for flexibility
			// 	default: null,
			// },
		},
		data() {
			return {
				// file: this.value,
        file: null,
				error: "",
				acceptedFormats: "image/*", // Only accept image files
			};
		},
		methods: {
      handleFileChange(file) {
        console.log('handleFileChange called with file:', file); // Log the file object
        if (!file) {
          this.error = 'No file selected.';
          return;
        }
        if (!file.type.startsWith('image/')) {
          this.error = 'Incorrect file format. Please upload an image file.';
          this.file = null;
          return;
        }
        this.error = '';
        // Perform additional actions with the file if necessary
        let fileURL = URL.createObjectURL(file);
        this.$emit("input", { file, url: fileURL }); // Emit null to indicate an invalid file
        console.log('Selected file:', file);
        console.log('Selected file URL:', fileURL);
      },
			handleFileChangez(file) {
        alert(JSON.stringify(file));
        if (event) {

          const file = event.target.files[0]; // Ensure we get the first file from the event
          console.log('handleFileChange called with file:', file); // Debug log
  
          // Validate the file type
          if (!file ||  !file.type || !file.type.startsWith("image/")) {
            console.log(`handleFileChange error ${JSON.stringify(file)}`);
            this.error =
              "Incorrect file format. Please upload an image file.";
            this.$emit("input", null); // Emit null to indicate an invalid file
            return;
          }
  
          // Reset error
          this.error = "";
  
          // Create a preview URL
          let fileURL;
          try {
            fileURL = URL.createObjectURL(file);
            console.log(`flielUrl: ${JSON.stringify(fileURL)}`);
  
          } catch (e) {
            console.error('Error creating object URL:', e);
            this.error = "An error occurred while processing the file.";
            this.$emit("file-change", null); // Emit null to indicate an invalid file
            return;
          }
  
          // Emit both the file and the URL for preview
          this.$emit("file-change", { file, url: fileURL });
        } else {
          alert ("NOO");
        }
			},
		},
		watch: {
			value(newValue) {
				this.file = newValue;
			},
		},
	};
</script>

<style scoped>
	/* Add any styles for your file-upload component here */
</style>
