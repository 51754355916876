<template>
	<div class="shelter-areas-archived">
		<main-section-shelter-areas-archived></main-section-shelter-areas-archived>
	</div>
</template>

<script>
	import ShelterAreasArchived from "@/components/main-section-shelter-areas-archived.vue";

	export default {
		components: {
			"main-section-shelter-areas-archived": ShelterAreasArchived,
		},
	};
</script>

<style scoped>
	/* Add your scoped styles here */
</style>
