<template>
	<v-container fluid>
		<!-- Row 1 Label -->
		<v-row>
			<v-col cols="12">
				<v-btn text href="/shelter-areas" class="label-link">
					<h3>Shelter Area - Brigadier Arthur Cartmell House</h3>
				</v-btn>
			</v-col>
		</v-row>

		<!-- Row 1 Cards -->
		<v-row>
			<v-col
				v-for="(card, index) in overviewCards.slice(0, 4)"
				:key="index"
				cols="12"
				sm="6"
				md="6"
				lg="3"
				class="ma-0 pa-2">
				<v-card class="dashboard-cards pt-5" :href="card.link">
					<v-list-item>
						<v-list-item-content>
							<div class="text-subtitle-2 mt-2 card-title">
								{{ card.title }}
							</div>
							<v-list-item-title class="number-display">
								{{ card.value }}
							</v-list-item-title>
						</v-list-item-content>
						<v-list-item-avatar size="50" color="transparent">
							<v-icon
								class="mdi"
								color="#ff4d4d"
								size="xxx-large"
								:class="card.icon"></v-icon>
						</v-list-item-avatar>
					</v-list-item>
					<v-card-actions class="justify-end overall-value">
						{{ card.overallValue }}
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>

		<!-- Row 2 Label -->
		<v-row>
			<v-col cols="12">
				<v-btn text href="/shelter-areas" class="label-link">
					<h3>Shelter Area - House of Hope</h3>
				</v-btn>
			</v-col>
		</v-row>

		<!-- Row 2 Cards -->
		<v-row>
			<v-col
				v-for="(card, index) in overviewCards.slice(4, 8)"
				:key="index"
				cols="12"
				sm="6"
				md="6"
				lg="3"
				class="ma-0 pa-2">
				<v-card class="dashboard-cards pt-5" :href="card.link">
					<v-list-item>
						<v-list-item-content>
							<div class="text-subtitle-2 mt-2 card-title">
								{{ card.title }}
							</div>
							<v-list-item-title class="number-display">
								{{ card.value }}
							</v-list-item-title>
						</v-list-item-content>
						<v-list-item-avatar size="50" color="transparent">
							<v-icon
								class="mdi"
								color="#ff4d4d"
								size="xxx-large"
								:class="card.icon"></v-icon>
						</v-list-item-avatar>
					</v-list-item>
					<v-card-actions class="justify-end overall-value">
						{{ card.overallValue }}
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	export default {
		name: "DashboardCard",
		data() {
			return {
				overviewCards: [
					{
						title: "Total Number of Residents",
						value: 120,
						overallValue: "120/500", // Example overall value
						icon: "mdi-account-group",
						link: "#", // Placeholder link
					},
					{
						title: "Number of Available Beds",
						value: 30,
						overallValue: "30/100", // Example overall value
						icon: "mdi-bed-empty",
						link: "#", // Placeholder link
					},
					{
						title: "Stored Items/Belongings",
						value: 450,
						overallValue: "450/600", // Example overall value
						icon: "mdi-archive",
						link: "#", // Placeholder link
					},
					{
						title: "Banned/ Restricted Individuals",
						value: 15,
						overallValue: "15/50", // Example overall value
						icon: "mdi-block-helper",
						link: "#", // Placeholder link
					},
					// Adding more cards for the second row
					{
						title: "Total Number of Residents",
						value: 75,
						overallValue: "75/300", // Example overall value
						icon: "mdi-account-group",
						link: "#", // Placeholder link
					},
					{
						title: "Number of Available Beds",
						value: 12,
						overallValue: "12/80", // Example overall value
						icon: "mdi-bed-empty",
						link: "#", // Placeholder link
					},
					{
						title: "Stored Items/Belongings",
						value: 768,
						overallValue: "768/1500", // Example overall value
						icon: "mdi-archive",
						link: "#", // Placeholder link
					},
					{
						title: "Banned/ Restricted Individuals",
						value: 3,
						overallValue: "3/80", // Example overall value
						icon: "mdi-block-helper",
						link: "#", // Placeholder link
					},
				],
			};
		},
	};
</script>

<style scoped>
	.dashboard-cards {
		border-radius: 8px;
		background-color: #fff; /* White background for contrast */
		color: #333; /* Dark text for readability */
		max-height: 130px; /* Fixed height for uniformity */
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		transition: box-shadow 0.3s ease; /* Smooth transition for hover effect */
	}

	.dashboard-cards:hover {
		box-shadow: 4px 4px 4px rgba(255, 0, 0, 0.6),
			-1px -1px 2px rgba(255, 0, 0, 0.2);
	}

	.number-display {
		font-size: 3.8rem;
		font-weight: bold;
		margin: 0;
	}

	.overall-value {
		font-size: 0.875rem;
		color: #888;
	}

	.label-link {
		text-decoration: none;
		color: #000; /* Black color for the link */
	}

	.label-link:hover {
		text-decoration: underline;
	}

	@media (max-width: 768px) {
		.v-col {
			width: 50%; /* 2 cards per row */
		}
	}

	@media (max-width: 576px) {
		.v-col {
			width: 100% !important; /* 1 card per row */
		}
		.number-display {
			font-size: 2.75rem;
		}
		.overall-value {
			font-size: 0.75rem;
		}
		.card-title {
			font-size: large !important;
		}
	}
</style>
