<template>
	<div>
		<!-- User Profile -->
		<v-list class="pa-1">
			<v-list-item>
				<v-list-item-avatar>
					<v-img src="@/assets/avatar-default.svg"></v-img>
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title>Sean P.</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>

		<v-divider></v-divider>

		<!-- Sidebar Menu -->
		<v-list dense>
			<!-- Regular Menu Items -->
			<v-list-item
				v-for="(item, index) in regularMenuItems"
				:key="index"
				@click="selectMenu(item)"
				:class="{ 'active-menu': isActive(item) }">
				<v-list-item-icon v-if="showIcons">
					<v-icon :class="{ 'white--text': isActive(item) }">{{
						"mdi-" + item.icon
					}}</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title
						:class="{ 'white--text': isActive(item) }"
						>{{ item.title }}</v-list-item-title
					>
				</v-list-item-content>
			</v-list-item>
			<template>
				<v-list dense>
					<!-- Shelter Areas Sub-menu -->
					<v-list-group
						v-model="shelterAreasOpen"
						append-icon=""
						:class="{ 'text-color-red': shelterAreasOpen }">
						<template v-slot:activator>
							<v-list-item class="no-padding">
								<v-list-item-icon>
									<v-icon
										:class="{
											'text-color-red': shelterAreasOpen,
										}">
										mdi-domain
									</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title
										:class="{
											'text-color-red': shelterAreasOpen,
										}">
										Shelter Areas
									</v-list-item-title>
								</v-list-item-content>
								<v-icon
									:class="{
										'text-color-red': shelterAreasOpen,
									}">
									{{
										shelterAreasOpen
											? "mdi-chevron-up"
											: "mdi-chevron-down"
									}}
								</v-icon>
							</v-list-item>
						</template>

						<!-- Sub Menu Items -->
						<v-list-item
							class="ml-3"
							v-for="(item, index) in shelterAreasItems"
							:key="'shelter-areas-' + index"
							@click="selectMenu(item)"
							:class="{ 'active-menu': isActive(item) }">
							<v-list-item-icon>
								<v-icon
									:class="{ 'white--text': isActive(item) }"
									>{{ "mdi-" + item.icon }}</v-icon
								>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title
									:class="{ 'white--text': isActive(item) }"
									>{{ item.title }}</v-list-item-title
								>
							</v-list-item-content>
						</v-list-item>
					</v-list-group>

					<!-- Residents Sub-menu -->
					<v-list-group
						v-model="residentsOpen"
						append-icon=""
						:class="{ 'text-color-red': residentsOpen }">
						<template v-slot:activator>
							<v-list-item class="no-padding">
								<v-list-item-icon>
									<v-icon
										:class="{
											'text-color-red': residentsOpen,
										}">
										mdi-home-group
									</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title
										:class="{
											'text-color-red': residentsOpen,
										}">
										Residents
									</v-list-item-title>
								</v-list-item-content>
								<v-icon
									:class="{
										'text-color-red': residentsOpen,
									}">
									{{
										residentsOpen
											? "mdi-chevron-up"
											: "mdi-chevron-down"
									}}
								</v-icon>
							</v-list-item>
						</template>

						<!-- Residents Sub-items -->
						<v-list-item
							class="ml-3"
							v-for="(item, index) in residentsItems"
							:key="'residents-' + index"
							@click="selectMenu(item)"
							:class="{ 'active-menu': isActive(item) }">
							<v-list-item-icon>
								<v-icon
									:class="{ 'white--text': isActive(item) }"
									>{{ "mdi-" + item.icon }}</v-icon
								>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title
									:class="{ 'white--text': isActive(item) }"
									>{{ item.title }}</v-list-item-title
								>
							</v-list-item-content>
						</v-list-item>
					</v-list-group>

					<!-- Items/Belongings Sub-menu -->
					<v-list-group
						v-model="itemsOpen"
						append-icon=""
						:class="{ 'text-color-red': itemsOpen }">
						<template v-slot:activator>
							<v-list-item class="no-padding">
								<v-list-item-icon>
									<v-icon
										:class="{
											'text-color-red': itemsOpen,
										}">
										mdi-briefcase
									</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title
										:class="{
											'text-color-red': itemsOpen,
										}">
										Items/Belongings
									</v-list-item-title>
								</v-list-item-content>
								<v-icon
									:class="{ 'text-color-red': itemsOpen }">
									{{
										itemsOpen
											? "mdi-chevron-up"
											: "mdi-chevron-down"
									}}
								</v-icon>
							</v-list-item>
						</template>

						<!-- Items/Belongings Sub-items -->
						<v-list-item
							class="ml-3"
							v-for="(item, index) in itemsBelongingsItems"
							:key="'items-' + index"
							@click="selectMenu(item)"
							:class="{ 'active-menu': isActive(item) }">
							<v-list-item-icon>
								<v-icon
									:class="{ 'white--text': isActive(item) }"
									>{{ "mdi-" + item.icon }}</v-icon
								>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title
									:class="{ 'white--text': isActive(item) }"
									>{{ item.title }}</v-list-item-title
								>
							</v-list-item-content>
						</v-list-item>
					</v-list-group>

					<!-- Settings Sub-menu -->
					<v-list-group
						v-model="settingsOpen"
						append-icon=""
						:class="{ 'text-color-red': settingsOpen }">
						<template v-slot:activator>
							<v-list-item class="no-padding">
								<v-list-item-icon>
									<v-icon
										:class="{
											'text-color-red': settingsOpen,
										}">
										mdi-cog
									</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title
										:class="{
											'text-color-red': settingsOpen,
										}">
										Settings
									</v-list-item-title>
								</v-list-item-content>
								<v-icon
									:class="{ 'text-color-red': settingsOpen }">
									{{
										settingsOpen
											? "mdi-chevron-up"
											: "mdi-chevron-down"
									}}
								</v-icon>
							</v-list-item>
						</template>

						<!-- Settings Sub-items -->
						<v-list-item
							class="ml-3"
							v-for="(item, index) in settingsItems"
							:key="'settings-' + index"
							@click="selectMenu(item)"
							:class="{ 'active-menu': isActive(item) }">
							<v-list-item-icon>
								<v-icon
									:class="{ 'white--text': isActive(item) }"
									>{{ "mdi-" + item.icon }}</v-icon
								>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title
									:class="{ 'white--text': isActive(item) }"
									>{{ item.title }}</v-list-item-title
								>
							</v-list-item-content>
						</v-list-item>
					</v-list-group>
				</v-list>
			</template>
		</v-list>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				menuItems: [
					{
						title: "Dashboard",
						icon: "view-dashboard",
						route: "/dashboard",
					},
					// {
					// 	title: "Forms",
					// 	icon: "file-document",
					// 	route: "/forms",
					// },
					// {
					// 	title: "Reports",
					// 	icon: "chart-line",
					// 	route: "/reports",
					// },
				],
				shelterAreasItems: [
					{
						title: "All Locations",
						icon: "map-marker",
						route: "/shelter-areas/all",
					},
					{
						title: "Archived Locations",
						icon: "archive",
						route: "/shelter-areas/archived",
						children: true,
					},
				],
				residentsItems: [
					{
						title: "All Residents",
						icon: "account-multiple",
						route: "/residents",
					},
					{
						title: "Current Residents",
						icon: "account-check",
						route: "/residents/active",
					},
					{
						title: "Recent Discharged",
						icon: "account-remove",
						route: "/residents/recent-discharged",
					},
				],
				itemsBelongingsItems: [
					{
						title: "All Items",
						icon: "briefcase",
						route: "/itemsbelongings/all",
					},
					{
						title: "C-Can",
						icon: "package-variant",
						route: "/itemsbelongings/c-can",
					},
				],
				settingsItems: [
					{
						title: "User Management",
						icon: "account-group",
						route: "/settings/user-management-list",
					},
				],
				showIcons: true,
				shelterAreasOpen: false,
				residentsOpen: false,
				itemsOpen: false,
				settingsOpen: false,
			};
		},
		computed: {
			regularMenuItems() {
				return this.menuItems.filter((item) => !item.children);
			},
		},
		methods: {
			selectMenu(item) {
				if (item.route) {
					if (this.$route.path !== item.route) {
						this.$emit("menu-selected", item);
						this.$router.push(item.route);
					}
				} else {
					item.model = !item.model;
				}
			},
			isActive(item) {
				return this.$route.path === item.route;
			},
		},
	};
</script>

<style scoped>
	.sub-menu-item {
		padding-left: 32px;
	}

	.text-color-red {
		color: var(--primary-color) !important;
	}

	.active-menu {
		background-color: var(--primary-color) !important;
		color: var(--secondary-color) !important;
	}

	.no-padding {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
</style>
