<template>
	<div>
		<h1>Search For Shelter Areas</h1>
		<v-container class="main-section-shelter-areas-list ma-1 pa-1" fluid>
			<ShelterAreasList />
		</v-container>
	</div>
</template>

<script>
	import ShelterAreasList from "./shelter-areas-list.vue";

	export default {
		name: "MainSectionShelterAreasList",
		components: { ShelterAreasList },
	};
</script>

<style scoped></style>
