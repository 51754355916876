<template>
	<v-container class="pl-0" fluid>
		<v-card>
			<v-card-title>
				<v-row style="width: 100%">
					<v-col cols="12" md="6">
						Demographics
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									small
									class="ml-2 info-icon">
									mdi-information-outline
								</v-icon>
							</template>
							<span class="tooltip-content">
								This section shows the demographics data of the
								person.
							</span>
						</v-tooltip>
					</v-col>
				</v-row>
			</v-card-title>
			<v-card-text>
				<v-progress-linear
					v-if="loading"
					indeterminate
					color="red"></v-progress-linear>

				<v-card class="mb-3">
					<v-card-title
						><v-chip color="red" dark>Profile</v-chip></v-card-title
					>
					<v-divider></v-divider>
					<v-card-text>
						<div>
							<strong>Homelessness Status:</strong>
							{{ localDemographicsProfile.homelessnessStatus }}
						</div>
						<div>
							<strong>Homelessness Length:</strong>
							{{ localDemographicsProfile.homelessnessLength }}
						</div>
						<div>
							<strong>Special Note/ Remarks:</strong>
							{{ localDemographicsProfile.specialNote }}
						</div>
						<v-btn v-if="editable" @click="editProfile">Edit</v-btn>
					</v-card-text>
				</v-card>

				<v-card class="mb-3">
					<v-card-title
						><v-chip color="red" dark
							>Emergency Contact</v-chip
						></v-card-title
					>
					<v-divider></v-divider>
					<v-card-text>
						<div>
							<strong>First Name:</strong>
							{{ localEmergencyContact.firstName }}
						</div>
						<div>
							<strong>Middle Name:</strong>
							{{ localEmergencyContact.middleName }}
						</div>
						<div>
							<strong>Last Name:</strong>
							{{ localEmergencyContact.lastName }}
						</div>
						<div>
							<strong>Relationship:</strong>
							{{ localEmergencyContact.relationship }}
						</div>
						<div>
							<strong>Phone:</strong>
							{{ localEmergencyContact.phone }}
						</div>
						<div>
							<strong>Email:</strong>
							{{ localEmergencyContact.email }}
						</div>
						<div>
							<strong>Note:</strong>
							{{ localEmergencyContact.note }}
						</div>
						<v-btn v-if="editable" @click="editEmergencyContact"
							>Edit</v-btn
						>
					</v-card-text>
				</v-card>

				<v-card class="mb-3">
					<v-card-title
						><v-chip color="red" dark
							>Health Profile</v-chip
						></v-card-title
					>
					<v-divider></v-divider>
					<v-card-text>
						<div>
							<strong>Record Date:</strong>
							{{ localHealthProfile.recordDate }}
						</div>
						<div
							v-for="issue in localHealthProfile.issues"
							:key="issue.name">
							<strong>{{ issue.name }}:</strong>
							{{ issue.status }} ({{ issue.source }})
						</div>
						<div>
							<strong>Other/ Notes:</strong>
							{{ localHealthProfile.notes }}
						</div>
						<v-btn v-if="editable" @click="editHealthProfile"
							>Edit</v-btn
						>
					</v-card-text>
				</v-card>

				<v-card class="mb-3">
					<v-card-title
						><v-chip color="red" dark
							>Addictions</v-chip
						></v-card-title
					>
					<v-divider></v-divider>
					<v-card-text>
						<div>
							<strong>Record Date:</strong>
							{{ localAddictions.recordDate }}
						</div>
						<div
							v-for="addiction in localAddictions.issues"
							:key="addiction.name">
							<strong>{{ addiction.name }}:</strong>
							{{ addiction.status }} ({{ addiction.source }})
						</div>
						<div>
							<strong>Other/ Notes:</strong>
							{{ localAddictions.notes }}
						</div>
						<v-btn v-if="editable" @click="editAddictions"
							>Edit</v-btn
						>
					</v-card-text>
				</v-card>

				<v-card class="mb-3">
					<v-card-title
						><v-chip color="red" dark
							>Mental Health</v-chip
						></v-card-title
					>
					<v-divider></v-divider>
					<v-card-text>
						<div>
							<strong>Record Date:</strong>
							{{ localMentalHealth.recordDate }}
						</div>
						<div
							v-for="issue in localMentalHealth.issues"
							:key="issue.name">
							<strong>{{ issue.name }}:</strong>
							{{ issue.status }} ({{ issue.source }})
						</div>
						<div>
							<strong>Other/ Notes:</strong>
							{{ localMentalHealth.notes }}
						</div>
						<v-btn v-if="editable" @click="editMentalHealth"
							>Edit</v-btn
						>
					</v-card-text>
				</v-card>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
	export default {
		name: "PersonDemographics",
		props: {
			demographicsProfile: {
				type: Object,
				required: true,
			},
			emergencyContact: {
				type: Object,
				required: true,
			},
			healthProfile: {
				type: Object,
				required: true,
			},
			addictions: {
				type: Object,
				required: true,
			},
			mentalHealth: {
				type: Object,
				required: true,
			},
			editable: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				localDemographicsProfile: { ...this.demographicsProfile },
				localEmergencyContact: { ...this.emergencyContact },
				localHealthProfile: { ...this.healthProfile },
				localAddictions: { ...this.addictions },
				localMentalHealth: { ...this.mentalHealth },
				loading: false,
			};
		},
		methods: {
			editProfile() {
				// Implement the logic for editing the profile
				console.log("Edit Profile:", this.localDemographicsProfile);
			},
			editEmergencyContact() {
				// Implement the logic for editing the emergency contact
				console.log(
					"Edit Emergency Contact:",
					this.localEmergencyContact
				);
			},
			editHealthProfile() {
				// Implement the logic for editing the health profile
				console.log("Edit Health Profile:", this.localHealthProfile);
			},
			editAddictions() {
				// Implement the logic for editing the addictions
				console.log("Edit Addictions:", this.localAddictions);
			},
			editMentalHealth() {
				// Implement the logic for editing the mental health
				console.log("Edit Mental Health:", this.localMentalHealth);
			},
		},
	};
</script>

<style scoped>
	.info-icon {
		cursor: pointer;
	}
	.tooltip-content {
		font-size: 0.9rem;
	}
</style>
