<template>
	<v-card class="reports-list-card">
		<v-card-title>
			<v-row style="width: 100%">
				<v-col cols="12" md="6">
					Generate Report
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-icon
								v-bind="attrs"
								v-on="on"
								small
								class="ml-2 info-icon">
								mdi-information-outline
							</v-icon>
						</template>
						<span class="tooltip-content">
							This page allows you to generate various reports for
							the shelter house.
						</span>
					</v-tooltip>
				</v-col>
				<v-col cols="12" md="6">
					<v-text-field
						v-model="search"
						placeholder="Search Reports:"
						hide-details
						dense
						solo
						background-color="white"
						style="border-radius: 4px"
						append-icon="mdi-text-box-search"
						:class="{ 'focused-search': searchFocused }"
						@focus="searchFocused = true"
						@blur="searchFocused = false"></v-text-field>
				</v-col>
			</v-row>
		</v-card-title>
		<v-card-text>
			<div class="table-container">
				<v-progress-linear
					v-if="loading"
					indeterminate
					color="red"></v-progress-linear>
				<v-list>
					<v-list-item
						v-for="report in paginatedReports"
						:key="report.title"
						@click="generateReport(report)"
						class="report-list-item">
						<v-list-item-content>
							<v-list-item-title style="">{{
								report.title
							}}</v-list-item-title>
							<v-list-item-subtitle style="color: white">{{
								report.description
							}}</v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-icon class="report-list-item-icon">
							<v-icon>mdi-file-chart</v-icon>
						</v-list-item-icon>
					</v-list-item>
				</v-list>
				<v-pagination
					v-model="page"
					:length="pageCount"
					:total-visible="7"
					color="red"></v-pagination>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
	export default {
		data() {
			return {
				search: "",
				searchFocused: false,
				page: 1,
				itemsPerPage: 10,
				reports: [
					{
						title: "Resident Details Report",
						description: "Detailed information about all residents",
					},
					{
						title: "Admission and Discharge Report",
						description:
							"Admission and discharge dates for all residents",
					},
					{
						title: "Refugee Status Report",
						description: "Report on residents who are refugees",
					},
					{
						title: "Immigrant Status Report",
						description: "Report on residents who are immigrants",
					},
					{
						title: "Veteran Status Report",
						description: "Report on residents who are veterans",
					},
					{
						title: "Shelter Area Occupancy Report",
						description:
							"Current occupancy status of all shelter areas",
					},
					{
						title: "Shelter Area Condition Report",
						description: "Condition of all shelter areas",
					},
					{
						title: "Item Inventory Report",
						description: "Inventory of all items and belongings",
					},
					{
						title: "Lost and Found Report",
						description: "Report on lost and found items",
					},
					{
						title: "User Activity Report",
						description: "Activity log of all users",
					},
					{
						title: "User Role Distribution Report",
						description: "Distribution of user roles",
					},
				],
			};
		},
		computed: {
			filteredReports() {
				return this.reports.filter(
					(report) =>
						report.title
							.toLowerCase()
							.includes(this.search.toLowerCase()) ||
						report.description
							.toLowerCase()
							.includes(this.search.toLowerCase())
				);
			},
			paginatedReports() {
				const start = (this.page - 1) * this.itemsPerPage;
				const end = start + this.itemsPerPage;
				return this.filteredReports.slice(start, end);
			},
			pageCount() {
				return Math.ceil(
					this.filteredReports.length / this.itemsPerPage
				);
			},
		},
		methods: {
			generateReport(report) {
				console.log("Generating report:", report.title);
			},
		},
	};
</script>

<style scoped>
	.reports-list-card {
		border: 1px solid #ccc;
		border-radius: 8px;
		padding: 4px;
		background-color: #fff;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
		min-width: fit-content;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.table-container {
		position: relative;
	}

	/* Add red hover effect to report list items */
	.report-list-item:hover {
		background-color: var(--primary-color) !important;
		color: var(--secondary-color) !important;
		cursor: pointer;
	}

	/* Change the color of icons within report list items on hover */
	.report-list-item-icon .v-icon {
		transition: color 0.2s ease;
	}

	.report-list-item:hover .report-list-item-icon .v-icon {
		color: var(--secondary-color) !important; /* White when hovered */
	}

	/* Change the title to be white and bold on hover */
	.report-list-item:hover .v-list-item-title {
		color: var(--secondary-color) !important;
		font-weight: bold !important;
		font-size: 1.5rem !important;
	}

	/* Change the description to be white on hover */
	.report-list-item:hover .v-list-item-subtitle {
		color: var(--secondary-color) !important;
		font-style: ;
	}
</style>
