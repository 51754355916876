<template>
	<v-container>
		<v-row>
			<v-col
				v-for="card in localIdentificationCards"
				:key="card.type"
				cols="12"
				sm="6"
				md="4">
				<v-card>
					<v-card-title>{{ card.type }}</v-card-title>
					<v-img
						:src="card.image"
						height="200px"
						width="300px"
						contain
						@click="showLightbox(card.image)"
						class="pa-2"
						style="
							object-fit: contain;
							display: block;
							margin: auto;
						" />
					<v-btn v-if="editable" icon @click="editCard(card)">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
				</v-card>
			</v-col>
		</v-row>
		<v-dialog v-model="lightbox" max-width="500px">
			<v-img :src="lightboxImage"></v-img>
		</v-dialog>
	</v-container>
</template>

<script>
	export default {
		name: "PersonIdentification",
		props: {
			identificationCards: {
				type: Array,
				required: true,
			},
			editable: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				localIdentificationCards: [...this.identificationCards],
				lightbox: false,
				lightboxImage: "",
			};
		},
		methods: {
			showLightbox(image) {
				this.lightboxImage = image;
				this.lightbox = true;
			},
			editCard(card) {
				// Implement the logic for editing the card
				console.log("Edit Card:", card);
			},
		},
	};
</script>
