<template>
	<div>
		<v-card class="recent-moveouts-card">
			<v-card-title>
				Recent Moveouts/Discharged
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-icon
							v-bind="attrs"
							v-on="on"
							small
							class="ml-2 info-icon">
							mdi-information-outline
						</v-icon>
					</template>
					<span class="tooltip-content">
						List of residents who have recently moved out or been
						discharged.
					</span>
				</v-tooltip>
			</v-card-title>
			<v-card-text>
				<v-progress-linear v-if="loading" indeterminate color="red">
				</v-progress-linear>
				<v-data-table
					:headers="headers"
					:items="recentMoveouts"
					:items-per-page="itemsPerPage"
					:footer-props="{
						itemsPerPageOptions: [4, 8, 12],
						showFirstLastPage: true,
					}">
					<template v-slot:[`item.name`]="{ item }">
						{{ item.name }}
					</template>
					<template v-slot:[`item.bedNumber`]="{ item }">
						{{ item.bedNumber }}
					</template>
					<template v-slot:[`item.date`]="{ item }">
						{{ item.date }}
					</template>
					<template v-slot:[`item.reason`]="{ item }">
						{{ item.reason }}
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									v-bind="attrs"
									v-on="on"
									small
									@click="viewResident(item)">
									mdi-eye
								</v-icon>
							</template>
							<span class="tooltip-content">View Resident</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
	import { fetchDischargedPersons } from "@/api"; // Ensure the correct path is provided

	export default {
		name: "DashboardRecentMoveouts",
		data() {
			return {
				headers: [
					{ text: "Resident Name", value: "name" },
					{ text: "Bed #", value: "bedNumber" },
					{ text: "Discharge Date", value: "date" },
					{ text: "Reason", value: "reason" },
					{ text: "Actions", value: "actions", sortable: false },
				],
				recentMoveouts: [],
				itemsPerPage: 4, // Adjust this value to control the height
				loading: false,
			};
		},
		created() {
			this.fetchRecentMoveouts();
		},
		methods: {
			async fetchRecentMoveouts() {
				this.loading = true;
				try {
					const response = await fetchDischargedPersons();
					this.recentMoveouts = response.data.items.map((item) => ({
						name: `${item.intake.person.first_name} ${item.intake.person.last_name}`,
						bedNumber: item.intake.bed_id,
						date: new Date(item.terminated_at).toLocaleDateString(),
						reason: item.checkout_reason,
					}));
				} catch (error) {
					console.error("Error fetching recent moveouts:", error);
				} finally {
					this.loading = false;
				}
			},
			viewResident(resident) {
				// View resident logic here
				console.log("View:", resident);
			},
		},
	};
</script>

<style scoped>
	.recent-moveouts-card {
		border: 1px solid #ccc;
		border-radius: 8px;
		padding: 4px; /* Adjust the padding within the card */
		background-color: #fff; /* White background */
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
		min-width: fit-content; /* Set minimum width to fit content */
		width: 100%; /* Ensure it takes full width */
		height: 100%; /* Ensure it takes full height of the column */
		display: flex;
		flex-direction: column;
	}
</style>
