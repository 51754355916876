<template>
	<div>
		<h1>Reports</h1>
		<v-container class="main-section-reports ma-1 pa-1" fluid>
			<ReportsList />
		</v-container>
	</div>
</template>

<script>
	import ReportsList from "./reports-list.vue";

	export default {
		name: "MainSectionReports",
		components: {
			ReportsList,
		},
	};
</script>

<style scoped></style>
